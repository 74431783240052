@import "../../_variables";

.brand-form-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.brand-form {
  width: 100%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  background: #151515;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  color: #fff;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 200, 7, 0.3);
    border-radius: 4px;

    &:hover {
      background: rgba(255, 200, 7, 0.5);
    }
  }

  .close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    color: rgba(255, 255, 255, 0.6);
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    z-index: 10;

    &:hover {
      color: #fff;
      transform: scale(1.1);
    }
  }

  h2 {
    text-align: center;
    padding: 2rem;
    margin: 0;
    font-size: 1.75rem;
    font-weight: 700;
    background: linear-gradient(314deg, #d1a300 0%, #ffc807 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .form-header {
    position: relative;
    margin-bottom: 3rem;
    border-radius: 12px;
    overflow: visible;

    .cover-upload {
      position: relative;
      width: 100%;
      height: 240px;
      background: rgba(255, 255, 255, 0.03);
      border-radius: 8px 8px 0 0;
      overflow: hidden;
      border: 1px solid rgba(255, 255, 255, 0.1);
      transition: all 0.3s ease;

      &:hover {
        border-color: rgba(255, 200, 7, 0.3);
        background: rgba(255, 255, 255, 0.05);
      }

      .upload-placeholder {
        position: absolute;
        inset: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: rgba(255, 255, 255, 0.5);
        pointer-events: none;
        z-index: 1;

        svg {
          font-size: 2rem;
          margin-bottom: 0.5rem;
          color: rgba(255, 200, 7, 0.5);
        }

        span {
          font-size: 0.9rem;
        }
      }

      .progressive-image {
        position: absolute;
        inset: 0;
        z-index: 2;
      }

      input[type="file"] {
        display: none;
      }

      label {
        position: absolute;
        inset: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: rgba(0, 0, 0, 0.5);
        color: white;
        opacity: 0;
        transition: opacity 0.3s ease;
        z-index: 3;

        &:hover {
          opacity: 1;
        }

        svg {
          font-size: 2rem;
          margin-bottom: 0.5rem;
          color: #ffc807;
        }
      }
    }

    .logo-upload {
      position: absolute;
      bottom: -60px;
      left: 50% !important;
      transform: translateX(-50%);
      width: 120px;
      height: 120px;
      z-index: 10;

      &.required::after {
        content: "*";
        position: absolute;
        top: 0.3rem;
        right: 0.3rem;
        color: #ffc807;
        font-size: 1.2rem;
        font-weight: bold;
        z-index: 11;
      }

      label {
        position: absolute;
        inset: 0;
        display: flex !important;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        transition: all 0.3s ease;
        z-index: 12;
        opacity: 1 !important;

        &:not(:has(.logo-image)) {
          background: rgba(0, 0, 0, 0.7);

          svg {
            opacity: 0.8;
          }
        }

        &:has(.logo-image) {
          background: rgba(0, 0, 0, 0.3);
          &:hover {
            background: rgba(0, 0, 0, 0.7);
          }
        }

        svg {
          font-size: 2rem;
          color: #ffc807;
          margin-bottom: 0.5rem;
        }

        span {
          font-size: 0.8rem;
          color: white;
          text-align: center;
        }
      }

      .progressive-image {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        overflow: hidden;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
        background: rgba(255, 255, 255, 0.05);
        border: 2px solid rgba(255, 255, 255, 0.1);
        z-index: 11;
      }

      input[type="file"] {
        display: none;
      }
    }
  }

  form {
    .form-fields {
      padding: 5rem 2rem 2rem;

      .input-group {
        margin-bottom: 1.5rem;
        position: relative;

        &.required::after {
          content: "*";
          position: absolute;
          top: 0.85rem;
          right: 1rem;
          color: #ffc807;
          font-size: 1.2rem;
          font-weight: bold;
        }

        &.username-group {
          .username-wrapper {
            position: relative;
            display: flex;
            align-items: center;
            background: rgba(255, 255, 255, 0.05);
            border: 1px solid rgba(255, 255, 255, 0.1);
            border-radius: 8px;
            transition: all 0.2s ease;

            &:focus-within {
              border-color: #ffc807;
              background: rgba(255, 255, 255, 0.08);
            }

            .username-prefix {
              color: #ffc807;
              font-size: 1.1rem;
              font-weight: 600;
              padding: 0 0.5rem 0 1rem;
              user-select: none;
            }

            .username-input {
              border: none;
              background: transparent;
              padding-left: 0;

              &:focus {
                border: none;
                background: transparent;
              }
            }
          }

          .input-hint {
            font-size: 0.85rem;
            color: rgba(255, 255, 255, 0.5);
            margin-top: 0.5rem;
            margin-left: 0.5rem;
          }
        }

        &.error {
          input {
            border-color: #ff4444;
            background: rgba(255, 68, 68, 0.05);
          }

          .username-wrapper {
            border-color: #ff4444;
            background: rgba(255, 68, 68, 0.05);
          }
        }

        .error-message {
          color: #ff4444;
          font-size: 0.8rem;
          margin-top: 0.25rem;
          display: flex;
          align-items: center;
          gap: 0.25rem;
        }
      }

      input,
      textarea {
        width: 100%;
        padding: 0.85rem 1rem;
        background: rgba(255, 255, 255, 0.05);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 8px;
        color: #fff;
        font-size: 0.95rem;
        transition: all 0.2s ease;

        &::placeholder {
          color: rgba(255, 255, 255, 0.5);
        }

        &:focus {
          outline: none;
          border-color: #ffc807;
          background: rgba(255, 255, 255, 0.08);
        }
      }

      textarea {
        min-height: 100px;
        resize: vertical;
      }

      .form-section {
        margin-top: 2rem;

        h3 {
          font-size: 1.1rem;
          margin-bottom: 1rem;
          color: rgba(255, 255, 255, 0.9);
        }

        .social-links {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .social-input-wrapper {
            display: flex;
            align-items: center;
            gap: 1rem;
            background: rgba(255, 255, 255, 0.05);
            border: 1px solid rgba(255, 255, 255, 0.1);
            border-radius: 8px;
            padding: 0.5rem 1rem;
            transition: all 0.2s ease;

            &:focus-within {
              border-color: #ffc807;
              background: rgba(255, 255, 255, 0.08);
            }

            svg {
              font-size: 1.25rem;
              color: #ffc807;
              flex-shrink: 0;
            }

            .input-container {
              flex: 1;
              position: relative;
              display: flex;
              align-items: center;

              .social-prefix {
                color: rgba(255, 255, 255, 0.4);
                font-size: 0.9rem;
                user-select: none;
                position: absolute;
                left: 0;
                pointer-events: none;
                transition: all 0.2s ease;
                transform-origin: left;
                transform: translateY(-14px) scale(0.8);
              }

              input {
                border: none;
                background: transparent;
                padding: 0;
                width: 100%;

                &:focus {
                  border: none;
                  background: transparent;
                }

                &::placeholder {
                  color: rgba(255, 255, 255, 0.5);
                }
              }
            }
          }

          .show-more-button {
            background: none;
            border: none;
            color: #ffc807;
            font-size: 0.95rem;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            margin: 0 auto;
            padding: 0.5rem 1rem;
            transition: all 0.2s ease;

            svg {
              transition: transform 0.2s ease;

              &.icon-rotate {
                transform: rotate(180deg);
              }
            }

            &:hover {
              opacity: 0.8;
            }
          }
        }

        .contact-info {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
          gap: 1rem;

          .input-wrapper {
            display: flex;
            align-items: center;
            gap: 1rem;
            background: rgba(255, 255, 255, 0.05);
            border: 1px solid rgba(255, 255, 255, 0.1);
            border-radius: 8px;
            padding: 0 1rem;
            transition: all 0.2s ease;

            &:focus-within {
              border-color: #ffc807;
              background: rgba(255, 255, 255, 0.08);
            }

            svg {
              font-size: 1.25rem;
              color: rgba(255, 255, 255, 0.7);
            }

            input {
              border: none;
              background: transparent;
              padding: 0.85rem 0;

              &:focus {
                border: none;
                background: transparent;
              }
            }
          }
        }
      }

      .form-actions {
        margin-top: 2rem;
        display: flex;
        justify-content: center;

        .save-button {
          padding: 1rem 3rem;
          background: linear-gradient(314deg, #d1a300 0%, #ffc807 100%);
          border: none;
          border-radius: 8px;
          color: #000;
          font-weight: 600;
          font-size: 1rem;
          cursor: pointer;
          transition: all 0.3s ease;

          &:hover:not(:disabled) {
            transform: translateY(-2px);
            box-shadow: 0 4px 12px rgba(255, 200, 7, 0.25);
          }

          .button-content {
            display: inline-flex;
            align-items: center;
            gap: 0.5rem;
          }

          &:disabled {
            opacity: 0.7;
            cursor: not-allowed;

            .loading-spinner {
              margin-right: 0.5rem;
            }
          }
        }
      }
    }
  }

  // Social Media Section
  .social-section {
    margin-top: 3rem;
    padding-top: 1.5rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);

    h3 {
      font-size: 1.1rem;
      color: #ffc807;
      margin-bottom: 1.5rem;
    }

    .social-inputs {
      display: flex;
      flex-direction: column;
      gap: 1.25rem;

      .social-input-group {
        position: relative;

        .social-prefix {
          position: absolute;
          left: 2.75rem;
          top: 50%;
          transform: translateY(-50%);
          color: rgba(255, 255, 255, 0.4);
          font-size: 0.85rem;
          pointer-events: none;
          font-family: monospace;
        }

        .input-wrapper {
          display: flex;
          align-items: center;
          background: rgba(255, 255, 255, 0.03);
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 8px;
          transition: all 0.2s ease;

          &:focus-within {
            border-color: #ffc807;
            background: rgba(255, 255, 255, 0.05);
          }

          .social-icon {
            width: 2.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.25rem;
            color: #ffc807;
            opacity: 0.8;
          }

          input {
            flex: 1;
            background: transparent;
            border: none;
            padding: 0.75rem 1rem 0.75rem 0;
            color: white;
            font-size: 0.9rem;
            padding-left: 6.5rem;

            &:focus {
              outline: none;
            }

            &::placeholder {
              color: rgba(255, 255, 255, 0.3);
            }
          }
        }
      }
    }

    .show-more {
      margin-top: 1rem;
      color: #ffc807;
      background: none;
      border: none;
      padding: 0.5rem;
      cursor: pointer;
      font-size: 0.9rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      transition: all 0.2s ease;

      &:hover {
        opacity: 0.8;
      }

      svg {
        transition: transform 0.2s ease;
      }

      &.expanded svg {
        transform: rotate(180deg);
      }
    }
  }

  .logo-upload {
    &.error {
      label {
        border-color: #ff4444;
        background: rgba(255, 68, 68, 0.05);
      }

      .error-text {
        color: #ff4444;
      }
    }

    .error-message {
      color: #ff4444;
      font-size: 0.8rem;
      margin-top: 0.25rem;
      text-align: center;
    }
  }

  .error-text {
    color: #ff4444;
    margin-left: 0.25rem;
  }
}

@media (max-width: 768px) {
  .brand-form-overlay {
    padding: 1rem;
  }

  .brand-form {
    h2 {
      padding: 1.5rem;
      font-size: 1.5rem;
    }

    form {
      .form-header {
        .cover-upload {
          height: 150px;
        }

        .logo-upload {
          width: 100px;
          height: 100px;
          bottom: -50px;
          left: 1rem;
        }
      }

      .form-fields {
        padding: 4rem 1rem 1rem;

        .form-section {
          .contact-info {
            grid-template-columns: 1fr;
          }
        }
      }
    }
  }
}
