@import "../../_variables";

.code-management {
  padding: 1rem;
  width: 100%;
  max-width: 100%;

  @media (max-width: 768px) {
    padding: 0.5rem;
  }

  .event-logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 1.5rem;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: 7rem;
      height: 7rem;
      background: radial-gradient(
        circle,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0) 70%
      );
      border-radius: 50%;
      z-index: -1;
      animation: pulse 3s infinite ease-in-out;
    }

    .event-logo {
      width: 5rem;
      height: 5rem;
      object-fit: cover;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.1);
      padding: 0.25rem;
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);
      animation: 1.5s ease-out 0s 1 LogoRotate;
      border: 2px solid rgba(255, 255, 255, 0.2);
      z-index: 10;
      transition: transform 0.3s ease, box-shadow 0.3s ease;

      &:hover {
        transform: scale(1.05);
        box-shadow: 0 6px 20px rgba(0, 0, 0, 0.5);
      }
    }

    .event-name {
      color: rgba(255, 255, 255, 0.9);
      font-size: 1.2rem;
      margin-top: 0.75rem;
      font-weight: 600;
      text-align: center;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    }
  }

  @keyframes LogoRotate {
    0% {
      transform: scale(0.7);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      opacity: 0.5;
    }
    50% {
      transform: scale(1.05);
      opacity: 0.8;
    }
    100% {
      transform: scale(0.95);
      opacity: 0.5;
    }
  }

  @keyframes subtle-float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-3px);
    }
    100% {
      transform: translateY(0);
    }
  }

  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.7);
    background: rgba(21, 21, 21, 0.8);
    border-radius: 1rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
    min-height: 150px;
  }

  .no-codes {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.7);
    background: rgba(21, 21, 21, 0.8);
    border-radius: 1rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
    min-height: 150px;
    text-align: center;
  }

  .code-management-item {
    background: rgba(21, 21, 21, 0.8);
    border-radius: 0.75rem;
    padding: 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
    position: relative;
    overflow: visible !important;

    // Paper texture overlay
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url("data:image/svg+xml,%3Csvg width='200' height='200' viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noise'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.75' numOctaves='2' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noise)' opacity='0.15'/%3E%3C/svg%3E");
      border-radius: 12px;
      pointer-events: none;
      z-index: 1;
      opacity: 0.4;
      mix-blend-mode: overlay;
    }

    @media (max-width: 768px) {
      padding: 1rem;
      flex-direction: column;
      align-items: stretch;
      border-radius: 0.5rem;
      gap: 0.75rem;
    }

    &:hover {
      transform: translateY(-2px);
      border-color: rgba(255, 200, 7, 0.3);
      box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
      animation: subtle-float 3s ease infinite;
    }

    // Styling for when the item is being edited
    &.editing {
      background: rgba(30, 30, 30, 0.9);
      border-color: rgba(255, 200, 7, 0.5);
      box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
    }

    &-info {
      flex: 1;
      display: flex;
      align-items: center;
      gap: 1rem;
      min-width: 0;
      position: relative;
      z-index: 2;

      .code-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3rem;
        height: 3rem;
        min-width: 3rem;
        border-radius: 0.5rem;
        background: linear-gradient(45deg, #1976d2, #2196f3);
        margin-right: 1rem;
        flex-shrink: 0;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
        transition: all 0.3s ease;

        &:hover {
          transform: scale(1.05);
          box-shadow: 0 6px 15px rgba(0, 0, 0, 0.4);
        }

        @media (max-width: 768px) {
          width: 2.5rem;
          height: 2.5rem;
          min-width: 2.5rem;
        }

        svg.qr-icon {
          width: 1.5rem;
          height: 1.5rem;
          color: #ffffff;
          transition: all 0.3s ease;
        }

        &:hover svg.qr-icon {
          transform: scale(1.1);
        }

        // Default color (Guest Code)
        background: linear-gradient(45deg, #1976d2, #2196f3);
        color: white;

        // Bottle Code
        &.bottle {
          background: linear-gradient(45deg, #d1a300, #ffc807);
          color: #000;

          svg.qr-icon {
            color: #000;
          }
        }

        // Special Code
        &.special {
          background: linear-gradient(45deg, #7b1fa2, #9c27b0);
          color: white;
        }

        // Table Code
        &.table {
          background: linear-gradient(45deg, #c2185b, #e91e63);
          color: white;
        }

        // Friends Code
        &.friends {
          background: linear-gradient(45deg, #388e3c, #4caf50);
          color: white;
        }

        // Backstage Code
        &.backstage {
          background: linear-gradient(45deg, #f57c00, #ff9800);
          color: white;
        }
      }

      .code-details {
        min-width: 0;
        flex: 1;

        .code-name {
          font-size: 1.1rem;
          font-weight: 600;
          color: white;
          margin: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          @media (max-width: 768px) {
            font-size: 1rem;
          }
        }

        .edit-name-input {
          width: 100%;
          padding: 0.5rem;
          background: rgba(0, 0, 0, 0.3);
          border: 1px solid rgba(255, 255, 255, 0.3);
          border-radius: 4px;
          color: white;
          font-size: 1.1rem;
          font-weight: 600;
          transition: all 0.2s ease;

          &:focus {
            outline: none;
            border-color: rgba(255, 200, 7, 0.7);
            box-shadow: 0 0 0 2px rgba(255, 200, 7, 0.2);
          }
        }

        .code-value {
          font-size: 0.9rem;
          color: rgba(255, 255, 255, 0.6);
          margin-top: 0.25rem;
          font-family: monospace;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    &-people {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      padding: 0 1.25rem;
      border-left: 1px solid rgba(255, 255, 255, 0.1);
      border-right: 1px solid rgba(255, 255, 255, 0.1);
      height: 3rem;
      position: relative;
      z-index: 2;

      @media (max-width: 768px) {
        border-left: none;
        border-right: none;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        padding: 0.75rem 0;
        height: auto;
        justify-content: center;
      }

      .people-icon {
        color: rgba(255, 255, 255, 0.7);
        font-size: 1.25rem;
      }

      .people-count {
        font-size: 1.1rem;
        font-weight: 600;
        color: white;
      }

      .people-separator {
        font-size: 0.9rem;
        color: rgba(255, 255, 255, 0.5);
        margin: 0 0.25rem;
      }

      .edit-pax-select {
        padding: 0.35rem 0.5rem;
        background: rgba(0, 0, 0, 0.3);
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 4px;
        color: white;
        font-size: 1rem;
        font-weight: 600;
        transition: all 0.2s ease;
        width: 3.5rem;
        text-align: center;

        &:focus {
          outline: none;
          border-color: rgba(255, 200, 7, 0.7);
          box-shadow: 0 0 0 2px rgba(255, 200, 7, 0.2);
        }
      }

      .people-max {
        font-size: 0.9rem;
        color: rgba(255, 255, 255, 0.5);
      }
    }

    &-actions {
      display: flex;
      gap: 0.75rem;
      position: relative;
      z-index: 2;

      @media (max-width: 768px) {
        justify-content: space-between;
        width: 100%;
      }

      button {
        width: 3rem; /* Increased size */
        height: 3rem; /* Increased size */
        border-radius: 0.5rem;
        border: none;
        background: rgba(255, 255, 255, 0.1);
        color: rgba(255, 255, 255, 0.7);
        cursor: pointer;
        transition: all 0.2s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.4rem; /* Increased font size */

        @media (max-width: 768px) {
          width: 3.2rem; /* Further increased for mobile */
          height: 3.2rem; /* Further increased for mobile */
          font-size: 1.5rem;
        }

        &:hover {
          transform: translateY(-2px);
        }

        svg {
          width: 1.4rem; /* Larger icons */
          height: 1.4rem; /* Larger icons */
        }

        &.edit-btn:hover {
          background: rgba(33, 150, 243, 0.2);
          color: #2196f3;
        }

        &.delete-btn:hover {
          background: rgba(244, 67, 54, 0.2);
          color: #f44336;
        }

        &.view-btn:hover {
          background: rgba(76, 175, 80, 0.2);
          color: #4caf50;
        }

        &.download-btn:hover {
          background: rgba(255, 193, 7, 0.2);
          color: #ffc107;
        }

        &.download-png-btn:hover {
          background: rgba(33, 150, 243, 0.2);
          color: #2196f3;
        }

        &.email-btn {
          font-size: 1.3rem;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            background: rgba(156, 39, 176, 0.2);
            color: #9c27b0;
          }
        }

        &.save-edit-btn {
          background: rgba(76, 175, 80, 0.2);
          color: #4caf50;
          font-size: 1.6rem;
          font-weight: bold;

          &:hover {
            background: rgba(76, 175, 80, 0.3);
            color: #4caf50;
          }
        }

        &.cancel-edit-btn {
          background: rgba(244, 67, 54, 0.2);
          color: #f44336;
          font-size: 1.6rem;
          font-weight: bold;

          &:hover {
            background: rgba(244, 67, 54, 0.3);
            color: #f44336;
          }
        }
      }
    }
  }

  .load-more-btn {
    display: block;
    width: 100%;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 0.75rem;
    color: white;
    cursor: pointer;
    margin-top: 1.5rem;
    font-weight: 600;
    font-size: 1rem;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.05) 50%,
        rgba(255, 255, 255, 0) 100%
      );
      transform: translateX(-100%);
      transition: transform 0.6s ease;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.15);
      transform: translateY(-3px);
      box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);

      &::before {
        transform: translateX(100%);
      }
    }

    &:active {
      transform: translateY(-1px);
    }
  }

  // Delete modal styling
  &-delete {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .modal-content {
      background: #151515;
      border-radius: 1rem;
      padding: 2rem;
      max-width: 90%;
      max-height: 90%;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
      border: 1px solid rgba(255, 255, 255, 0.1);
      position: relative;

      .close-btn {
        position: absolute;
        top: 1rem;
        right: 1rem;
        background: none;
        border: none;
        color: rgba(255, 255, 255, 0.7);
        font-size: 1.5rem;
        cursor: pointer;

        &:hover {
          color: white;
        }
      }

      h3 {
        margin-bottom: 1.5rem;
        color: white;
        font-size: 1.25rem;
        text-align: center;
      }

      .delete-content {
        text-align: center;

        h3 {
          margin-bottom: 1rem;
          color: white;
        }

        p {
          margin-bottom: 2rem;
          color: rgba(255, 255, 255, 0.7);
        }

        .delete-actions {
          display: flex;
          gap: 1rem;
          justify-content: center;

          button {
            padding: 0.75rem 1.5rem;
            border-radius: 0.5rem;
            border: none;
            font-weight: 500;
            cursor: pointer;
            transition: all 0.2s ease;

            &.cancel {
              background: rgba(255, 255, 255, 0.1);
              color: white;

              &:hover {
                background: rgba(255, 255, 255, 0.2);
              }
            }

            &.confirm {
              background: #f44336;
              color: white;

              &:hover {
                background: #d32f2f;
              }
            }
          }
        }
      }
    }
  }

  // PNG View Modal
  .code-png-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.95);
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
    animation: modalFadeIn 0.3s ease;

    .close-btn {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.7);
      color: white;
      border: 1px solid rgba(255, 255, 255, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      cursor: pointer;
      z-index: 1010;
      transition: all 0.2s ease;

      &:hover {
        background-color: rgba(50, 50, 50, 0.9);
        transform: scale(1.1);
      }
    }

    .png-container {
      max-width: 100%;
      max-height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 100%;
        max-height: 100vh;
        object-fit: contain;
        animation: imageFadeIn 0.5s ease;
      }
    }
  }

  // Email modal styling
  .email-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
    animation: modalFadeIn 0.3s ease;
  }

  .email-modal {
    background: #1a1a1a;
    border-radius: 12px;
    width: 360px;
    max-width: 95%;
    padding: 1.5rem;
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.1);
    position: relative;
    animation: modalPopIn 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 4px;
      background: linear-gradient(90deg, #ffc107, #e3a31d);
    }

    .close-btn {
      position: absolute;
      top: 12px;
      right: 12px;
      background: none;
      border: none;
      color: rgba(255, 255, 255, 0.7);
      font-size: 1.25rem;
      cursor: pointer;
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease;

      &:hover {
        color: white;
        transform: scale(1.1);
      }
    }

    h3 {
      margin: 0 0 1.25rem;
      color: white;
      font-size: 1.1rem;
      padding-right: 1.5rem;
    }

    .email-form {
      display: flex;
      gap: 8px;

      input {
        flex: 1;
        padding: 12px 16px;
        border-radius: 8px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        background: rgba(255, 255, 255, 0.05);
        color: white;
        font-size: 0.95rem;
        transition: all 0.2s ease;

        &:focus {
          outline: none;
          border-color: #ffc107;
          box-shadow: 0 0 0 2px rgba(255, 193, 7, 0.3);
        }

        &::placeholder {
          color: rgba(255, 255, 255, 0.4);
        }
      }

      .send-btn {
        min-width: 80px;
        padding: 0 15px;
        border-radius: 8px;
        border: none;
        background: linear-gradient(45deg, #ffc107, #e3a31d);
        color: #212121;
        font-weight: 600;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover:not(:disabled) {
          transform: translateY(-2px);
          box-shadow: 0 5px 15px rgba(255, 193, 7, 0.4);
        }

        &:active:not(:disabled) {
          transform: translateY(0);
        }

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
          background: rgba(255, 255, 255, 0.1);
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }

  @keyframes modalPopIn {
    0% {
      opacity: 0;
      transform: scale(0.8);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  // Delete the old email-modal styling and replace with this:
  .modal.email-modal {
    display: none; // Hide the old modal
  }
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes imageFadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

// Responsive adjustments
@media (max-width: 768px) {
  .code-management {
    .code-management-item {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.75rem;
      padding: 0.75rem;

      &-info {
        width: 100%;
      }

      &-people {
        border-left: none;
        border-right: none;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        width: 100%;
        padding: 0.5rem 0;
        height: auto;
        justify-content: center;
      }

      &-actions {
        width: 100%;
        justify-content: space-between;

        button {
          width: 2.25rem;
          height: 2.25rem;
        }
      }
    }
  }
}
