@import "../../_variables";

.brand-profile {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  padding-top: 80px;

  .brand-header {
    background: #151515;
    position: relative;
    margin-bottom: 1rem;
    border-radius: 1rem;
    overflow: hidden;

    .brand-cover {
      height: 200px;
      position: relative;
      overflow: hidden;

      @media (max-width: 768px) {
        height: 180px;
      }

      .cover-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .cover-placeholder {
        width: 100%;
        height: 100%;
        background: linear-gradient(45deg, #1a1a1a, #2a2a2a);
      }
    }

    .brand-info {
      padding: 1rem;
      display: flex;
      align-items: flex-start;
      gap: 1.5rem;
      margin-top: -60px;
      position: relative;
      z-index: 2;

      @media (max-width: 768px) {
        margin-top: -40px;
        padding: 0.75rem;
        gap: 1rem;
        flex-wrap: wrap;
      }

      .brand-logo {
        width: 120px;
        height: 120px;
        border-radius: 1rem;
        overflow: hidden;
        border: 4px solid #151515;
        background: rgba(255, 255, 255, 0.1);
        flex-shrink: 0;
        position: relative;

        @media (max-width: 768px) {
          width: 80px;
          height: 80px;
          border-width: 3px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .logo-placeholder {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 2.5rem;
          font-weight: bold;
          color: rgba(255, 255, 255, 0.5);
          background: linear-gradient(45deg, #1a1a1a, #2a2a2a);

          @media (max-width: 768px) {
            font-size: 1.75rem;
          }
        }
      }

      .brand-details {
        flex: 1;
        min-width: 0;

        h1 {
          margin: 0;
          font-size: 1.5rem;
          font-weight: 600;
          color: #fff;

          @media (max-width: 768px) {
            font-size: 1.25rem;
          }
        }

        .username {
          color: rgba(255, 255, 255, 0.5);
          font-size: 0.9rem;
        }
      }

      .brand-actions {
        display: flex;
        gap: 0.5rem;
        margin-left: auto;

        @media (max-width: 768px) {
          width: 100%;
          order: 3;
          margin: 0.5rem 0;
        }

        .action-button {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          padding: 0.5rem 1rem;
          border-radius: 0.5rem;
          background: rgba(255, 255, 255, 0.08);
          border: 1px solid rgba(255, 255, 255, 0.1);
          color: #fff;
          cursor: pointer;
          transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
          font-weight: 500;

          @media (max-width: 768px) {
            flex: 1;
            justify-content: center;
            padding: 0.5rem;
          }

          svg {
            font-size: 1.25rem;
            transition: transform 0.2s ease;
          }

          &:hover {
            background: rgba(255, 255, 255, 0.12);
            border-color: rgba(255, 255, 255, 0.2);

            svg {
              transform: scale(1.1);
            }
          }

          &.active {
            background: rgba(255, 200, 7, 0.15);
            border-color: rgba(255, 200, 7, 0.3);
            color: #ffc807;

            &:hover {
              background: rgba(255, 200, 7, 0.2);
              border-color: rgba(255, 200, 7, 0.4);
            }
          }

          &.pending {
            background: rgba(255, 200, 7, 0.1);
            color: #ffc807;
            border: 1px solid rgba(255, 200, 7, 0.2);
            cursor: not-allowed;

            &:hover {
              transform: none !important;
            }
          }

          &.accepted {
            background: rgba(76, 209, 55, 0.1);
            color: #4cd137;
            border: 1px solid rgba(76, 209, 55, 0.2);
          }

          &.rejected {
            background: rgba(255, 82, 82, 0.1);
            color: #ff5252;
            border: 1px solid rgba(255, 82, 82, 0.2);
          }

          &.favorite {
            &.active {
              svg {
                color: #ffc807;
                filter: drop-shadow(0 0 8px rgba(255, 200, 7, 0.4));
              }
            }
          }
        }
      }
    }

    .brand-stats {
      display: flex;
      padding: 1rem;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      gap: 2rem;

      @media (max-width: 768px) {
        padding: 0.75rem;
        gap: 1rem;
        justify-content: space-around;
      }

      .stat-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.25rem;

        .stat-value {
          font-size: 1.1rem;
          font-weight: 600;
          color: #fff;

          @media (max-width: 768px) {
            font-size: 1rem;
          }
        }

        .stat-label {
          font-size: 0.85rem;
          color: rgba(255, 255, 255, 0.5);

          @media (max-width: 768px) {
            font-size: 0.75rem;
          }
        }
      }
    }

    .social-links-container {
      padding: 1rem;
      margin-top: 0.5rem;
      border-top: 1px solid rgba(255, 255, 255, 0.06);

      .social-links {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
        justify-content: center;
        align-items: center;

        .social-icon {
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          background: rgba(21, 21, 21, 0.6);
          border: 1px solid rgba(255, 255, 255, 0.1);
          text-decoration: none;
          transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
          position: relative;

          svg {
            width: 18px;
            height: 18px;
            transition: transform 0.2s ease;
          }

          &:hover {
            border-color: rgba(255, 255, 255, 0.2);
          }

          @media (max-width: 768px) {
            width: 32px;
            height: 32px;

            svg {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }

  .loading,
  .error {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    background: #151515;
    border-radius: 1rem;
    color: rgba(255, 255, 255, 0.5);
    font-size: 1rem;
    text-align: center;
  }

  .quick-actions-bar {
    position: sticky;
    top: 70px;
    z-index: 90;
    background: rgba(21, 21, 21, 0.8);
    backdrop-filter: blur(10px);
    padding: 1rem;
    border-radius: 0.5rem;
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0.1);

    .actions-left,
    .actions-right {
      display: flex;
      gap: 0.5rem;
    }

    .action-button {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      background: rgba(255, 255, 255, 0.1);
      border: none;
      color: #fff;
      cursor: pointer;
      transition: all 0.2s;

      svg {
        font-size: 1.25rem;
      }

      &:hover {
        background: rgba(255, 255, 255, 0.15);
      }

      &.active {
        background: $primary-color;
        color: #151515;
      }
    }
  }

  .actions-menu {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #151515;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding: 1rem;
    z-index: 100;

    .menu-items {
      max-width: 600px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      button {
        padding: 1rem;
        border: none;
        background: rgba(255, 255, 255, 0.1);
        color: #fff;
        border-radius: 0.5rem;
        cursor: pointer;
        transition: all 0.2s;

        &:hover {
          background: rgba(255, 255, 255, 0.15);
        }

        &:last-child {
          background: rgba(255, 64, 129, 0.1);
          color: #ff4081;

          &:hover {
            background: rgba(255, 64, 129, 0.2);
          }
        }
      }
    }
  }

  &.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 70px);

    .loading-spinner {
      width: 40px;
      height: 40px;
      border: 3px solid rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      border-top-color: #ffc807;
      animation: spin 1s ease-in-out infinite;
      margin: 0 auto;
    }

    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }

    .loading-content {
      display: none;
    }
  }

  .brand-lineups {
    margin: 1.5rem 0;
    padding: 1.5rem;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 1rem;
    border: 1px solid rgba(255, 255, 255, 0.1);

    .section-title {
      color: #fff;
      font-size: 1.25rem;
      margin-bottom: 1.25rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 0.5rem;

      &:after {
        content: "";
        flex: 1;
        height: 1px;
        background: rgba(255, 255, 255, 0.1);
        margin-left: 0.5rem;
      }
    }

    .lineup-container {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }

    .lineup-category-group {
      margin-bottom: 0.5rem;

      .category-title {
        color: #ffc807;
        font-size: 0.9rem;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        margin-bottom: 0.75rem;
        font-weight: 600;
        padding-left: 0.5rem;
        border-left: 3px solid #ffc807;
      }

      .lineup-artists {
        display: flex;
        flex-wrap: wrap;
        gap: 0.75rem;

        @media (max-width: 768px) {
          flex-direction: column;
        }

        .artist {
          display: flex;
          align-items: center;
          gap: 0.75rem;
          padding: 0.75rem;
          border-radius: 8px;
          background: rgba(255, 255, 255, 0.05);
          transition: all 0.2s ease;
          flex: 1;
          min-width: 200px;
          max-width: calc(33.333% - 0.5rem);

          @media (max-width: 768px) {
            max-width: 100%;
          }

          &:hover {
            background: rgba(255, 200, 7, 0.1);
            transform: translateX(3px);
          }

          .artist-avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            overflow: hidden;
            flex-shrink: 0;
            border: 2px solid rgba(255, 200, 7, 0.3);

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            &.placeholder {
              background: linear-gradient(
                45deg,
                rgba(255, 200, 7, 0.2),
                rgba(255, 200, 7, 0.3)
              );
              display: flex;
              align-items: center;
              justify-content: center;
              color: #ffc807;
              font-weight: 600;
            }
          }

          .artist-info {
            flex: 1;
            min-width: 0;

            .artist-name {
              color: #fff;
              font-size: 0.95rem;
              font-weight: 500;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              display: block;
            }
          }
        }
      }
    }
  }
}

.brand-profile-feed {
  width: 100%;
  overflow-x: hidden;

  .feed-section {
    background: #151515;
    border-radius: 1rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 1.5rem;
    margin-bottom: 1rem;

    .section-title {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-size: 1.1rem;
      color: #fff;
      margin: 0 0 1rem 0;

      svg {
        font-size: 1.25rem;
        color: $primary-color;
      }
    }

    .empty-state {
      text-align: center;
      padding: 2rem;
      color: rgba(255, 255, 255, 0.5);

      .empty-icon {
        font-size: 2rem;
        margin-bottom: 0.5rem;
      }

      p {
        margin: 0;
        font-size: 1rem;
      }

      .subtitle {
        font-size: 0.9rem;
        color: rgba(255, 255, 255, 0.3);
        margin-top: 0.5rem;
        display: block;
      }
    }

    .events-list {
      width: 100%;
      max-width: 100%;

      // Styling for seamless UpcomingEvent
      .event-navigation {
        margin-top: -0.5rem;
      }

      .event-card {
        margin-top: 0.5rem;
      }

      .event-image-container {
        border-radius: 8px;
        overflow: hidden;
      }

      .event-details {
        padding: 0rem 0;
      }
    }
  }
}

@media (max-width: 768px) {
  .brand-profile {
    padding: 0;
    margin-top: 50px;

    .brand-header {
      border-radius: 0;
    }

    .quick-actions-bar {
      border-radius: 0;
      margin: 0;
      top: 60px;
    }

    .brand-profile-header,
    .feed-section {
      border-radius: 0;
      border-left: none;
      border-right: none;
    }
  }
}
