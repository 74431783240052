@import "../../_variables";
@import "../../_animations";

// Enhanced color variables
$primary-gold: #ffc807;
$secondary-gold: #d1a300;
$dark-gold: #a58300;
$light-gold: #ffdf80;
$highlight-gold: #ffe680;
$black: #000000;
$dark-gray: #1c1c1c;
$darker-gray: #0f0f0f;
$deep-black: #050505;

// Animation durations
$pulse-duration: 6s;
$shimmer-duration: 2s;
$float-duration: 4s;
$nebula-duration: 15s;
$star-twinkle-duration: 2s;

// Enhanced keyframes
@keyframes float {
  0%,
  100% {
    transform: translateY(0) rotate(0deg);
  }
  25% {
    transform: translateY(-15px) rotate(2deg);
  }
  50% {
    transform: translateY(0) rotate(0deg);
  }
  75% {
    transform: translateY(15px) rotate(-2deg);
  }
}

@keyframes pulse {
  0%,
  100% {
    opacity: 0.3;
    transform: scale(1);
  }
  50% {
    opacity: 0.7;
    transform: scale(1.05);
  }
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

@keyframes backgroundShift {
  0%,
  100% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 100% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  75% {
    background-position: 0% 100%;
  }
}

@keyframes dustFloat {
  0%,
  100% {
    transform: translateY(0) translateX(0);
    opacity: 0.1;
  }
  25% {
    transform: translateY(-10px) translateX(5px);
    opacity: 0.3;
  }
  50% {
    transform: translateY(-15px) translateX(0);
    opacity: 0.2;
  }
  75% {
    transform: translateY(-5px) translateX(-5px);
    opacity: 0.3;
  }
}

@keyframes starTwinkle {
  0%,
  100% {
    opacity: var(--min-brightness, 0.3);
    transform: scale(0.8);
  }
  50% {
    opacity: var(--max-brightness, 1);
    transform: scale(1.3);
  }
}

@keyframes nebulaPulse {
  0%,
  100% {
    opacity: 0.2;
    filter: blur(var(--min-blur, 40px));
  }
  50% {
    opacity: 0.4;
    filter: blur(var(--max-blur, 60px));
  }
}

@keyframes glow {
  0%,
  100% {
    box-shadow: 0 0 30px 5px rgba($primary-gold, 0.3);
  }
  50% {
    box-shadow: 0 0 50px 10px rgba($primary-gold, 0.5);
  }
}

// Main styles
.device-restriction {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  overflow: hidden;
  perspective: 1000px;

  .animated-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden;

    // Base gradient background
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: radial-gradient(
          ellipse at 20% 20%,
          rgba($primary-gold, 0.08) 0%,
          transparent 40%
        ),
        radial-gradient(
          ellipse at 80% 80%,
          rgba($primary-gold, 0.05) 0%,
          transparent 40%
        ),
        radial-gradient(
          circle at 50% 50%,
          $deep-black 0%,
          $black 40%,
          $dark-gray 100%
        );
      background-size: 200% 200%;
      animation: backgroundShift 30s ease infinite;
      transform-style: preserve-3d;
    }

    // Subtle pattern overlay
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='rgba(255,200,7,0.05)' fill-rule='evenodd'/%3E%3C/svg%3E"),
        linear-gradient(to bottom, transparent, rgba($black, 0.8));
      opacity: 0.3;
    }
  }

  // Nebula effect
  .nebula-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;

    .nebula {
      position: absolute;
      border-radius: 50%;
      opacity: 0.2;
      filter: blur(40px);

      &:nth-child(1) {
        --min-blur: 40px;
        --max-blur: 60px;
        top: 20%;
        left: 30%;
        width: 40vmin;
        height: 40vmin;
        background: radial-gradient(
          circle at center,
          rgba($light-gold, 0.3),
          rgba($primary-gold, 0.1),
          transparent 70%
        );
        animation: nebulaPulse #{$nebula-duration * 1.2} ease-in-out infinite;
      }

      &:nth-child(2) {
        --min-blur: 50px;
        --max-blur: 70px;
        top: 60%;
        left: 70%;
        width: 50vmin;
        height: 35vmin;
        background: radial-gradient(
          ellipse at center,
          rgba($primary-gold, 0.2),
          rgba($secondary-gold, 0.1),
          transparent 70%
        );
        animation: nebulaPulse #{$nebula-duration * 0.8} ease-in-out infinite reverse;
      }
    }
  }

  // Star field
  .star-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 2;

    .star {
      position: absolute;
      border-radius: 50%;
      background: #fff;

      @for $i from 1 through 30 {
        &:nth-child(#{$i}) {
          --min-brightness: #{0.4 + random() * 0.3};
          --max-brightness: #{0.8 + random() * 0.3};

          $size: 1px + random(3) + px;
          $duration: 1s + random(3) + s;
          $delay: random(3) + s;

          width: $size;
          height: $size;
          top: #{random(100)}vh;
          left: #{random(100)}vw;
          opacity: 0.8;
          box-shadow: 0 0 #{3 + random(5)}px rgba(255, 255, 255, 0.9);
          animation: starTwinkle $duration ease-in-out $delay infinite;
        }
      }
    }
  }

  // Particles
  .particles-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 3;
  }

  .particle {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;

    @for $i from 1 through 15 {
      &.particle-#{$i} {
        --tx: #{random(120) - 60}vw;
        --ty: #{random(120) - 60}vh;
        --r: #{random(360)}deg;
        --s: #{0.5 + random() * 0.8};
        --max-opacity: #{0.8 + random() * 0.3};
        --min-opacity: #{0.4 + random() * 0.3};

        $size: 3px + random(15) + px;
        $duration: 5s + random(10) + s;
        $delay: random(5) + s;

        width: $size;
        height: $size;
        top: #{random(100)}vh;
        left: #{random(100)}vw;
        background: radial-gradient(
          circle at center,
          rgba($light-gold, 0.9),
          rgba($primary-gold, 0.3)
        );
        filter: blur(1px);
        box-shadow: 0 0 #{random(10) + 5}px rgba($primary-gold, 0.7);
        animation: particleFloat $duration ease-in-out $delay infinite;
      }
    }
  }

  // Glow overlay
  .glow-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vmin;
    height: 80vmin;
    background: radial-gradient(
      circle at center,
      rgba($primary-gold, 0.05),
      transparent 70%
    );
    filter: blur(30px);
    z-index: 0;
    opacity: 0.5;
    animation: pulse $pulse-duration ease-in-out infinite;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 20px;
    z-index: 4;
  }

  &__message {
    color: white;
    text-align: center;

    z-index: 5;
    animation: float $float-duration ease-in-out infinite;

    h1 {
      font-size: clamp(3rem, 8vw, 5rem);
      font-weight: 800;
      margin-bottom: 0.5rem;
      background: linear-gradient(
        135deg,
        $light-gold 0%,
        $primary-gold 50%,
        $secondary-gold 100%
      );
      background-size: 200% auto;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: shimmer $shimmer-duration ease-in-out infinite;
      position: relative;
      width: 100%;
      display: inline-block;
      overflow: visible;
      white-space: nowrap;
      letter-spacing: -0.02em;
      padding-right: 0.5em;
      transform: none;
      margin-left: -0.1em;
      margin-right: -0.1em;

      &::after {
        content: "";
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        width: 40%;
        height: 3px;
        background: linear-gradient(
          90deg,
          transparent,
          $primary-gold,
          transparent
        );
        border-radius: 3px;
      }
    }

    .subtitle {
      font-size: clamp(1.2rem, 4vw, 2rem);
      color: rgba(255, 255, 255, 0.9);
      margin-bottom: 1.5rem;
      font-weight: 300;
      letter-spacing: 1px;
    }

    .alpha-badge {
      display: inline-flex;
      align-items: center;
      background: linear-gradient(
        135deg,
        $primary-gold 0%,
        $secondary-gold 100%
      );
      color: $black;
      padding: 0.5rem 1.5rem;
      border-radius: 100px;
      font-weight: 700;
      font-size: 1rem;
      letter-spacing: 1px;
      text-transform: uppercase;
      box-shadow: 0 0 20px rgba($primary-gold, 0.5);
      animation: glow 3s ease-in-out infinite;
      position: relative;
      overflow: hidden;

      &::before {
        content: "";
        position: absolute;
        top: -50%;
        left: -50%;
        width: 200%;
        height: 200%;
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.3) 50%,
          rgba(255, 255, 255, 0) 100%
        );
        transform: rotate(30deg);
        animation: shimmer 3s linear infinite;
        pointer-events: none;
      }
    }

    .mobile-note {
      margin-top: 2rem;
      font-size: 1rem;
      color: rgba(255, 255, 255, 0.7);
      font-weight: 300;
    }
  }
}

// Dust particles
.dust-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;

  .dust-particle {
    position: absolute;
    width: 1px;
    height: 1px;
    background: rgba($primary-gold, 0.3);
    border-radius: 50%;

    @for $i from 1 through 50 {
      &:nth-child(#{$i}) {
        top: #{random(100)}vh;
        left: #{random(100)}vw;
        opacity: 0.1 + random() * 0.2;
        box-shadow: 0 0 #{1 + random(2)}px rgba($primary-gold, 0.5);
        animation: dustFloat
          #{3 +
          random(5)}s
          ease-in-out
          #{random(5)}s
          infinite;
      }
    }
  }
}

// Media queries for responsiveness
@media (max-width: 768px) {
  .device-restriction {
    &__message {
      h1 {
        font-size: clamp(2.5rem, 8vw, 4rem);
        padding-right: 0.5em; /* Increased padding on mobile too */
      }

      .subtitle {
        font-size: 1.2rem;
      }

      .alpha-badge {
        padding: 0.4rem 1.25rem;
        font-size: 0.875rem;
      }
    }
  }
}

// Additional effects for high-end devices
@media (min-width: 1200px) {
  .device-restriction {
    .animated-background {
      &::before {
        background-size: 300% 300%;
      }
    }

    .particle {
      filter: blur(0);

      @for $i from 1 through 15 {
        &.particle-#{$i} {
          box-shadow: 0 0 #{random(20) + 10}px rgba($primary-gold, 0.8);
        }
      }
    }

    .glow-overlay {
      width: 100vmin;
      height: 100vmin;
      filter: blur(50px);
    }

    .nebula {
      filter: blur(60px) !important;
    }

    &__message {
      h1 {
        padding-right: 0.6em; /* Extra padding for large screens */
      }
    }
  }
}
