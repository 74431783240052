@import "../../_variables";

.event-profile {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  color: var(--text-color);
  padding: 0 1rem;
  padding-top: 80px;
  padding-bottom: 120px;
  display: flex;
  flex-direction: column;

  // Loading state
  &-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
    padding: 2rem;
    margin-top: 100px;

    .loading-spinner {
      width: 48px;
      height: 48px;
      border: 4px solid rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      border-top-color: #2196f3;
      animation: spin 1s ease-in-out infinite;
      margin-bottom: 1rem;
    }

    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }

    p {
      margin-top: 1rem;
      color: var(--text-color-secondary);
    }
  }

  // Error state
  &-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
    padding: 2rem;
    text-align: center;
    margin-top: 100px;

    svg {
      color: rgba(255, 255, 255, 0.5);
      margin-bottom: 1rem;
      font-size: 3rem;
    }

    h2 {
      margin-bottom: 0.5rem;
      font-weight: 600;
      color: white;
    }

    p {
      color: rgba(255, 255, 255, 0.7);
      margin-bottom: 1.5rem;
    }

    button {
      background: #2196f3;
      border: none;
      border-radius: 0.5rem;
      padding: 0.75rem 1.5rem;
      color: white;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        background: #1976d2;
        transform: translateY(-2px);
      }
    }
  }

  // Event Header Section
  .event-header {
    position: relative;
    width: 100%;
    margin-bottom: 2rem;
    border-radius: 1.25rem;
    overflow: hidden;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3);
    background: #151515;

    .event-cover {
      position: relative;
      width: 100%;
      height: auto;
      min-height: 220px;
      overflow: hidden;

      .cover-image {
        width: 100%;
        display: block;
        object-fit: cover;
        transform: scale(1.02);
        transition: transform 0.4s ease-out;
        max-height: 500px; // Limit maximum height
        margin: 0 auto;

        &:hover {
          transform: scale(1.05);
        }
      }

      .cover-placeholder {
        width: 100%;
        height: 100%;
        min-height: 220px;
        background: linear-gradient(135deg, #181818 0%, #282828 100%);
      }

      .cover-gradient {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 70%; // Increased height for better gradient coverage
        pointer-events: none;
        background: linear-gradient(
          to top,
          rgba(21, 21, 21, 1) 0%,
          rgba(21, 21, 21, 0.9) 20%,
          rgba(21, 21, 21, 0.6) 40%,
          rgba(21, 21, 21, 0.2) 80%,
          rgba(21, 21, 21, 0) 100%
        );
        z-index: 1;
      }
    }

    .event-header-container {
      position: relative;
      padding: 0 1.5rem 1.5rem;
      margin-top: -120px;
      z-index: 10;

      .event-brand-info {
        display: flex;
        align-items: center;
        margin-bottom: 0.8rem;

        .brand-logo {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 0.8rem;
          border: 2px solid var(--brand-primary, #ffc807);
          flex-shrink: 0;
          box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .logo-placeholder {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(
              --brand-primary-transparent,
              rgba(255, 200, 7, 0.2)
            );
            color: var(--brand-primary, #ffc807);
            font-size: 1.3rem;
            font-weight: bold;
          }
        }

        .brand-username-container {
          cursor: pointer;
          padding: 0.3rem 0.8rem;
          background: rgba(255, 200, 7, 0.1);
          border-radius: 20px;
          border-left: 2px solid var(--brand-primary, #ffc807);
          transition: all 0.2s ease;

          .brand-username {
            font-size: 0.9rem;
            color: var(--brand-primary, #ffc807);
            font-weight: 500;
            text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
          }

          &:hover {
            background: rgba(255, 200, 7, 0.2);
            transform: translateY(-1px);
          }
        }
      }

      .event-meta {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.2rem;

        .event-date {
          display: flex;
          align-items: center;
          font-size: 0.9rem;
          color: rgba(255, 255, 255, 0.8);
          background: rgba(255, 255, 255, 0.08);
          padding: 0.4rem 0.8rem;
          border-radius: 20px;

          .event-date-icon {
            margin-right: 0.5rem;
            display: flex;
            align-items: center;
            color: var(--brand-primary, #ffc807);
          }
        }

        .header-actions {
          display: flex;
          align-items: center;

          .action-button {
            background: rgba(255, 255, 255, 0.1);
            border: none;
            border-radius: 50%;
            width: 38px;
            height: 38px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            cursor: pointer;
            transition: all 0.2s ease;
            backdrop-filter: blur(10px);

            &:hover {
              background: var(--brand-primary, #ffc807);
              color: black;
              box-shadow: 0 4px 12px rgba(255, 200, 7, 0.3);
              transform: translateY(-2px);
            }

            svg {
              font-size: 1.2rem;
            }
          }
        }
      }

      .event-title-container {
        border-left: 3px solid var(--brand-primary, #ffc807);
        padding-left: 1rem;

        .event-title {
          font-size: 1.6rem;
          font-weight: 700;
          margin: 0 0 0.3rem 0;
          color: #fff;
          letter-spacing: -0.02em;
          line-height: 1.2;
          display: inline-block;
          text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
          max-width: 95%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .event-subtitle {
          font-size: 1rem;
          margin: 0;
          color: #e0e0e0;
          font-weight: 500;
          text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  // Navigation tabs
  .event-nav {
    background: #151515;
    border-radius: 1rem;
    overflow: hidden;
    margin-bottom: 1.5rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    position: sticky;
    top: 80px;
    z-index: 10;

    button {
      flex: 1;
      padding: 1rem;
      background: transparent;
      border: none;
      color: rgba(255, 255, 255, 0.7);
      font-weight: 500;
      cursor: pointer;
      transition: all 0.2s ease;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;

      svg {
        font-size: 1.25rem;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        height: 2px;
        background: #ffc807;
        transition: all 0.2s ease;
        transform: translateX(-50%);
      }

      &:hover {
        color: white;
        background: rgba(255, 255, 255, 0.05);
      }

      &.active {
        color: #ffc807;

        &::after {
          width: 100%;
        }
      }
    }
  }

  // Content sections
  .event-content {
    .event-section:not(.event-codes) {
      background: #151515;
      border-radius: 1rem;
      padding: 1.5rem;
      margin-bottom: 1.5rem;
      border: 1px solid rgba(255, 255, 255, 0.1);

      h3 {
        font-size: 1.25rem;
        font-weight: 600;
        color: white;
        margin: 0 0 1.5rem;
      }

      // Info section styling
      &.event-info {
        .eventProfile-details {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
          gap: 1.5rem;
          margin-bottom: 2rem;

          .detail-item {
            display: flex;
            align-items: flex-start;
            gap: 1rem;
            padding: 1rem;
            background: rgba(255, 255, 255, 0.05);
            border-radius: 12px;
            transition: all 0.2s ease;

            &:hover {
              background: rgba(255, 200, 7, 0.1) !important;
            }

            svg {
              font-size: 1.5rem;
              color: #ffc807 !important;
            }

            div {
              h4 {
                margin: 0 0 0.25rem;
                color: rgba(255, 255, 255, 0.7);
                font-size: 0.9rem;
                font-weight: 500;
              }

              p {
                margin: 0;
                color: white;
                font-size: 1rem;
                font-weight: 400;

                & + p {
                  margin-top: 0.25rem;
                }
              }
            }
          }
        }

        .event-description {
          background: rgba(255, 255, 255, 0.05);
          border-radius: 12px;
          padding: 1.5rem;

          h3 {
            font-size: 1.1rem;
            margin-bottom: 1rem;
          }

          p {
            color: rgba(255, 255, 255, 0.9);
            line-height: 1.6;
            white-space: pre-line;
          }
        }
      }

      // Lineup section styling
      &.event-lineup {
        .lineup-grid {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
          gap: 1.5rem;

          .lineup-artist {
            background: rgba(255, 255, 255, 0.05);
            border-radius: 16px;
            padding: 1.5rem;
            text-align: center;
            transition: all 0.2s ease;

            &:hover {
              background: rgba(255, 200, 7, 0.1) !important;
            }

            .artist-image {
              width: 120px;
              height: 120px;
              border-radius: 50%;
              margin: 0 auto 1rem;
              overflow: hidden;
              border: 3px solid #ffc807;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }

              .artist-placeholder {
                width: 100%;
                height: 100%;
                background: linear-gradient(45deg, #1a1a1a, #2a2a2a);
                display: flex;
                align-items: center;
                justify-content: center;
                color: rgba(255, 255, 255, 0.5);
                font-size: 2rem;
                font-weight: bold;
              }
            }

            h4 {
              margin: 0 0 0.25rem;
              color: white;
              font-size: 1.1rem;
            }

            .artist-category {
              color: rgba(255, 255, 255, 0.6);
              font-size: 0.9rem;
            }
          }
        }
      }

      // Tickets section styling
      &.event-tickets {
        h3 {
          font-size: 1.5rem;
          font-weight: 600;
          margin-bottom: 1.5rem;
          color: #fff;
          position: relative;
          display: inline-block;

          &::after {
            content: "";
            position: absolute;
            bottom: -8px;
            left: 0;
            width: 60px;
            height: 3px;
            background: linear-gradient(to right, #ffc807, transparent);
            border-radius: 3px;
          }
        }

        .no-tickets {
          text-align: center;
          padding: 2rem;
          background: rgba(0, 0, 0, 0.2);
          border-radius: 8px;
          color: rgba(255, 255, 255, 0.7);
        }
      }

      // Access section styling
      &.event-access {
        .access-options {
          display: grid;
          gap: 1rem;

          .access-option {
            background: rgba(255, 255, 255, 0.05);
            border-radius: 16px;
            padding: 1.5rem;
            display: flex;
            align-items: center;
            gap: 1.5rem;
            transition: all 0.2s ease;

            &:hover {
              background: rgba(255, 200, 7, 0.1) !important;
            }

            .access-icon {
              width: 48px;
              height: 48px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              background: rgba(255, 200, 7, 0.15);
              flex-shrink: 0;

              svg {
                font-size: 1.5rem;
                color: #ffc807 !important;
              }

              &.friends {
                background: rgba(255, 200, 7, 0.15);
              }
              &.vip {
                background: rgba(255, 200, 7, 0.15);
              }
              &.backstage {
                background: rgba(255, 200, 7, 0.15);
              }
              &.table {
                background: rgba(255, 200, 7, 0.15);
              }
            }

            .access-info {
              flex: 1;

              h4 {
                margin: 0 0 0.25rem;
                color: white;
                font-size: 1.1rem;
              }

              p {
                margin: 0;
                color: rgba(255, 255, 255, 0.7);
                font-size: 0.9rem;
              }
            }

            .code-input {
              display: flex;
              gap: 0.5rem;
              flex: 1;

              input {
                flex: 1;
                padding: 0.75rem 1rem;
                border-radius: 8px;
                border: 1px solid rgba(255, 255, 255, 0.1);
                background: rgba(0, 0, 0, 0.2);
                color: white;

                &::placeholder {
                  color: rgba(255, 255, 255, 0.5);
                }
              }

              button {
                padding: 0.75rem 1.5rem;
                border: none;
                border-radius: 8px;
                background: rgba(255, 200, 7, 0.15);
                color: #ffc807;
                font-weight: 500;
                cursor: pointer;
                transition: all 0.2s ease;
                border: 1px solid rgba(255, 200, 7, 0.3);

                &:hover {
                  background: rgba(255, 200, 7, 0.2);
                  border-color: rgba(255, 200, 7, 0.4);
                }
              }
            }
          }
        }
      }

      // Combined Event Section (Info + Lineup)
      &.event-combined {
        .event-info-section {
          background: rgba(255, 255, 255, 0.05);
          border-radius: 12px;
          padding: 1.5rem;
          margin-bottom: 2rem;

          h3 {
            font-size: 1.25rem;
            font-weight: 600;
            color: white;
            margin: 0 0 1.5rem;
          }

          // Integrated lineup grid
          .lineup-mini-grid {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            margin-bottom: 1.5rem;
            padding-bottom: 1.5rem;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);

            .lineup-category-section {
              margin-bottom: 0.5rem;

              .lineup-category-title {
                color: #ffc807;
                font-size: 0.9rem;
                text-transform: uppercase;
                letter-spacing: 0.5px;
                margin-bottom: 0.75rem;
                font-weight: 600;
                padding-left: 0.5rem;
                border-left: 3px solid #ffc807;
              }

              .lineup-category-artists {
                display: flex;
                flex-wrap: wrap;
                gap: 0.75rem;
              }
            }

            .lineup-artist-mini {
              display: flex;
              align-items: center;
              gap: 0.75rem;
              background: rgba(255, 255, 255, 0.05);
              border-radius: 12px;
              padding: 0.75rem;
              transition: all 0.2s ease;
              flex: 1;
              min-width: 200px;
              max-width: calc(33.333% - 0.75rem);

              @media (max-width: 768px) {
                max-width: 100%;
              }

              &:hover {
                background: rgba(255, 200, 7, 0.1) !important;
                transform: translateX(3px);
              }

              .artist-image-mini {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                overflow: hidden;
                border: 2px solid #ffc807;
                flex-shrink: 0;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }

                .artist-placeholder-mini {
                  width: 100%;
                  height: 100%;
                  background: linear-gradient(45deg, #1a1a1a, #2a2a2a);
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: rgba(255, 255, 255, 0.5);
                  font-size: 1.25rem;
                  font-weight: bold;
                }
              }

              .artist-info-mini {
                flex: 1;
                min-width: 0;

                h4 {
                  margin: 0 0 0.25rem;
                  color: white;
                  font-size: 1rem;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }
          }

          .event-details {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            gap: 1.5rem;
            margin-bottom: 2rem;

            .detail-item {
              display: flex;
              align-items: flex-start;
              gap: 1rem;
              padding: 1rem;
              background: rgba(255, 255, 255, 0.05);
              border-radius: 12px;
              transition: all 0.2s ease;

              &:hover {
                background: rgba(255, 200, 7, 0.1) !important;
              }

              svg {
                font-size: 1.5rem;
                color: #ffc807 !important;
              }

              div {
                h4 {
                  margin: 0 0 0.25rem;
                  color: rgba(255, 255, 255, 0.7);
                  font-size: 0.9rem;
                  font-weight: 500;
                }

                p {
                  margin: 0;
                  color: white;
                  font-size: 1rem;
                  font-weight: 400;

                  & + p {
                    margin-top: 0.25rem;
                  }
                }
              }
            }
          }

          .event-description {
            background: rgba(255, 255, 255, 0.05);
            border-radius: 12px;
            padding: 1.5rem;

            h3 {
              font-size: 1.1rem;
              margin-bottom: 1rem;
            }

            p {
              color: rgba(255, 255, 255, 0.9);
              line-height: 1.6;
              white-space: pre-line;
            }
          }
        }

        // Guest Code Section has been replaced by the GuestCode component
      }
    }
  }
}

// Responsive adjustments
@media (max-width: 768px) {
  .event-profile {
    padding-top: 60px;

    .event-header {
      border-radius: 16px;

      .event-header-container {
        .event-title-container {
          .event-title {
          }

          .event-subtitle {
            font-size: 0.75rem;
          }
        }
      }
    }

    .event-nav {
      border-radius: 16px;

      button {
        padding: 0.75rem;
        font-size: 0.9rem;
      }
    }

    .event-content {
      .event-section {
        border-radius: 16px;
        padding: 1rem;

        &.event-combined {
          .event-info-section {
            padding: 1rem;

            .lineup-mini-grid {
              flex-direction: column;

              .lineup-artist-mini {
                max-width: 100%;
              }
            }

            .event-details {
              grid-template-columns: 1fr;
            }
          }

          .event-guest-code {
            padding: 1rem;
          }
        }

        &.event-info {
          .event-details {
            grid-template-columns: 1fr;
          }
        }

        &.event-lineup {
          .lineup-grid {
            grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
          }
        }

        &.event-access {
          .access-guest-code {
            padding: 1rem;
          }

          .access-options {
            .access-option {
              flex-direction: column;
              text-align: center;

              .access-info {
                text-align: center;
              }

              .code-input {
                flex-direction: column;

                button {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

// Styles for the event-codes section that contains the GuestCode component
.event-codes-wrapper {
  padding: 0;
  background: transparent;
  border: none;
  margin-bottom: 1.5rem;
}

// Add responsive styles for the event header:

@media (min-width: 768px) {
  .event-header {
    .event-cover {
      height: 260px;
    }

    .event-header-container {
      margin-top: -130px;
      padding: 0 2rem 2rem;

      .event-title-container {
        .event-title {
          font-size: 1.8rem;
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .event-header {
    .event-cover {
      height: 300px;
    }

    .event-header-container {
      margin-top: -140px;
      padding: 0 2.5rem 2.5rem;

      .event-brand-info {
        .brand-logo {
          width: 60px;
          height: 60px;
        }
      }

      .event-title-container {
        .event-title {
          font-size: 2rem;
        }
      }
    }
  }
}

@media (min-width: 1440px) {
  .event-header {
    .event-cover {
      height: 450px;
    }

    .event-info {
      padding: 0 4rem 4rem;
      margin-top: -150px;

      .eventProfile-details {
        .event-title {
          font-size: 3.5rem;
        }
      }
    }
  }
}

// Add styles for the lineup section

.lineup-section {
  margin-top: 2rem;

  h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    color: #fff;
    position: relative;
    display: inline-block;

    &::after {
      content: "";
      position: absolute;
      bottom: -8px;
      left: 0;
      width: 60px;
      height: 3px;
      background: linear-gradient(to right, #ffc807, transparent);
      border-radius: 3px;
    }
  }

  .lineup-grid {
    display: grid;
    gap: 1.5rem;

    .lineup-category-section {
      background: rgba(0, 0, 0, 0.2);
      border-radius: 12px;
      padding: 1.25rem;

      .lineup-category-title {
        font-size: 1.1rem;
        font-weight: 600;
        margin-bottom: 1rem;
        color: #ffc807;
      }

      .lineup-category-artists {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
        gap: 1rem;

        .lineup-artist {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;

          .artist-image {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            overflow: hidden;
            margin-bottom: 0.75rem;
            border: 2px solid rgba(255, 255, 255, 0.1);

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            &.artist-placeholder {
              background: linear-gradient(135deg, #333, #222);
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 1.5rem;
              font-weight: 600;
              color: rgba(255, 255, 255, 0.7);
            }
          }

          .artist-info {
            h4 {
              font-size: 0.9rem;
              font-weight: 500;
              margin: 0;
              color: white;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .lineup-section {
    .lineup-grid {
      .lineup-category-artists {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      }
    }
  }
}

@media (min-width: 1024px) {
  .lineup-section {
    .lineup-grid {
      .lineup-category-section {
        padding: 1.5rem;
      }

      .lineup-category-artists {
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));

        .lineup-artist {
          .artist-image {
            width: 100px;
            height: 100px;
          }

          .artist-info {
            h4 {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
}
