@import "../../_variables";

.current-events-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.current-events-menu {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 480px;
  max-height: 80vh;
  background: #151515;
  border-radius: 12px;
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.3);
  z-index: 10000;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: 0;

  .current-events-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    h3 {
      font-size: 1.1rem;
      font-weight: 600;
      color: white;
      margin: 0;
    }

    .close-button {
      background: none;
      border: none;
      color: rgba(255, 255, 255, 0.6);
      font-size: 1.5rem;
      cursor: pointer;
      padding: 0;
      line-height: 1;
      transition: color 0.2s ease;

      &:hover {
        color: #fff;
      }
    }
  }

  .current-events-content {
    flex: 1;
    overflow-y: auto;
    padding: 1rem;

    .loading-state {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 150px;
      color: rgba(255, 255, 255, 0.7);
      font-size: 0.9rem;
    }

    .no-events {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 150px;
      color: rgba(255, 255, 255, 0.7);
      font-size: 0.9rem;
      text-align: center;
    }

    .events-list {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .event-item {
        display: flex;
        gap: 1rem;
        padding: 0.75rem;
        border-radius: 8px;
        background: rgba(255, 255, 255, 0.05);
        cursor: pointer;
        transition: all 0.2s ease;
        position: relative;

        &:hover {
          background: rgba(255, 255, 255, 0.1);
        }

        &.active {
          border-left: 3px solid #ffc807;
        }

        &.upcoming {
          border-left: 3px solid #4dabf7;
        }

        &.past {
          border-left: 3px solid #868e96;
          opacity: 0.8;
        }

        .event-image {
          width: 70px;
          height: 70px;
          border-radius: 8px;
          overflow: hidden;
          flex-shrink: 0;
          position: relative;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .placeholder-image {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: linear-gradient(145deg, #1a1a1a, #2a2a2a);
            color: rgba(255, 200, 7, 0.6);
            font-weight: 600;
            font-size: 1.5rem;
          }

          .active-badge {
            position: absolute;
            top: 5px;
            right: 5px;
            background: #ffc807;
            color: #151515;
            font-size: 0.6rem;
            font-weight: 600;
            padding: 2px 6px;
            border-radius: 4px;
            text-transform: uppercase;
          }
        }

        .event-details {
          flex: 1;
          min-width: 0;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .event-title {
            font-size: 1rem;
            font-weight: 600;
            margin: 0 0 0.5rem;
            color: rgba(255, 255, 255, 0.9);
            line-height: 1.3;
          }

          .event-info {
            display: flex;
            flex-direction: column;
            gap: 0.4rem;

            .event-date,
            .event-location,
            .event-time {
              display: flex;
              align-items: center;
              gap: 0.5rem;
              color: rgba(255, 255, 255, 0.7);
              font-size: 0.8rem;

              svg {
                color: rgba(255, 200, 7, 0.8);
                font-size: 0.75rem;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .current-events-menu {
    width: 95%;
    max-width: 95%;
    max-height: 80vh;
    left: 50%;
    margin-left: 0;

    .current-events-header {
      padding: 0.75rem 1rem;

      h3 {
        font-size: 1rem;
      }
    }

    .current-events-content {
      padding: 0.75rem;

      .events-list {
        gap: 0.75rem;

        .event-item {
          padding: 0.6rem;
          gap: 0.75rem;

          .event-image {
            width: 60px;
            height: 60px;
          }

          .event-details {
            .event-title {
              font-size: 0.9rem;
              margin-bottom: 0.35rem;
            }

            .event-info {
              gap: 0.3rem;

              .event-date,
              .event-location,
              .event-time {
                font-size: 0.75rem;
              }
            }
          }
        }
      }
    }
  }
}

/* For extremely small screens */
@media (max-width: 360px) {
  .current-events-menu {
    width: 98%;
    max-width: 98%;
    max-height: 75vh;
  }
}
