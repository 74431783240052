@import "../../_variables";
@import "../../_animations";
@import "../../_glassy";

.event-settings {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 600px;
  height: 100vh;
  max-height: 100%;
  background-color: var(--surface-color);
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  color: var(--text-color);
  overflow: hidden;

  .settings-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    flex-shrink: 0;

    h2 {
      margin: 0;
      font-size: 1.5rem;
      font-weight: 600;
    }

    .close-button {
      background: none;
      border: none;
      color: var(--text-color);
      cursor: pointer;
      padding: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: background-color 0.2s;

      &:hover {
        background-color: var(--hover-color);
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  .settings-content {
    padding: 1rem;
    flex: 1;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: rgba(255, 255, 255, 0.2) transparent;
    padding-bottom: 3rem;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.2);
      border-radius: 3px;
    }
  }

  .settings-group {
    margin-bottom: 2rem;

    h3 {
      font-size: 1.2rem;
      font-weight: 500;
      margin-bottom: 1rem;
      color: var(--text-color);
    }

    &.danger-zone {
      margin-top: 3rem;
      padding: 1.5rem;
      background-color: rgba(244, 67, 54, 0.08);
      border: 1px solid rgba(244, 67, 54, 0.3);
      border-radius: 8px;
      box-shadow: 0 4px 12px rgba(244, 67, 54, 0.1);
      position: relative;

      h3 {
        color: #f44336;
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-bottom: 1.25rem;
        font-size: 1.25rem;

        &::before {
          content: "⚠️";
          font-size: 1.1rem;
        }
      }

      .settings-item {
        background-color: rgba(244, 67, 54, 0.08);
        border-radius: 8px;
        border: 1px dashed rgba(244, 67, 54, 0.3);
        padding: 1.25rem;

        .item-actions {
          .delete-button {
            background-color: #f44336;
            color: white;
            border: none;
            border-radius: 6px;
            padding: 10px 18px;
            font-weight: 600;
            font-size: 0.95rem;
            cursor: pointer;
            transition: all 0.2s ease;
            box-shadow: 0 4px 8px rgba(244, 67, 54, 0.3);
            display: flex;
            align-items: center;
            gap: 0.5rem;

            &:hover {
              background-color: #d32f2f;
              transform: translateY(-2px);
              box-shadow: 0 6px 12px rgba(244, 67, 54, 0.4);
            }

            &:active {
              transform: translateY(0);
            }
          }
        }
      }
    }
  }

  .settings-items {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .loading-message {
      padding: 1rem;
      text-align: center;
      color: #666;
      font-size: 0.9rem;
      background: rgba(255, 255, 255, 0.05);
      border-radius: 8px;
    }
  }

  .settings-item {
    display: flex;
    align-items: center;
    padding: 1rem;
    background-color: var(--surface-color-elevated);
    border-radius: 8px;
    transition: all 0.2s;

    &:hover {
      background-color: var(--hover-color);
    }

    &.active {
      background-color: var(--primary-color-light);
    }

    &.danger {
      &:hover {
        background-color: var(--danger-color-light);
      }
    }

    .item-content {
      flex: 1;

      h4 {
        margin: 0;
        font-size: 1rem;
        font-weight: 500;
      }

      p {
        margin: 0.25rem 0 0;
        font-size: 0.875rem;
        color: var(--text-color-secondary);
      }
    }

    .item-actions {
      display: flex;
      gap: 0.5rem;

      button {
        background: none;
        border: none;
        padding: 0.5rem;
        border-radius: 50%;
        color: var(--text-color);
        cursor: pointer;
        transition: all 0.2s;

        &:hover {
          background-color: var(--hover-color);
          transform: scale(1.1);
        }

        &:active {
          transform: scale(0.9);
        }

        svg {
          width: 20px;
          height: 20px;
        }
      }

      .delete-button {
        background-color: #f44336;
        color: white;
        border: none;
        border-radius: 4px;
        padding: 8px 16px;
        font-weight: 600;
        cursor: pointer;
        transition: background-color 0.2s ease;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

        &:hover {
          background-color: #d32f2f;
        }
      }
    }
  }

  .danger {
    margin-top: 2rem;
    padding: 1.5rem;
    border-radius: 8px;
    background-color: rgba(244, 67, 54, 0.08);
    border: 1px solid rgba(244, 67, 54, 0.3);
    position: relative;
    overflow: hidden;

    &::before {
      content: "⚠️ Danger Zone";
      display: block;
      font-weight: bold;
      margin-bottom: 1rem;
      color: #f44336;
      font-size: 1.1rem;
      letter-spacing: 0.5px;
    }

    .settings-item {
      background-color: transparent;
      border: none;
      padding: 0;
      margin-bottom: 0;
    }

    .item-icon {
      background-color: rgba(244, 67, 54, 0.15) !important;
      color: #f44336 !important;
    }

    .delete-button {
      background-color: #f44336;
      color: white;
      border: none;
      border-radius: 4px;
      padding: 8px 16px;
      font-weight: 600;
      font-size: 0.9rem;
      cursor: pointer;
      transition: all 0.2s ease;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

      &:hover {
        background-color: #d32f2f;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
      }
    }
  }
}

// Media query for smaller screens
@media (max-width: 600px) {
  .event-settings {
    width: 100%;
    max-width: none;
  }
}

.add-code-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--surface-color-elevated);
  border-radius: 12px;
  padding: 24px;
  width: 90%;
  max-width: 400px;
  z-index: 1100;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.25);
  color: var(--text-color);

  h3 {
    margin: 0 0 16px;
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--text-color);
  }

  .dialog-content {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .form-field {
      display: flex;
      flex-direction: column;
      gap: 8px;

      label {
        font-size: 0.9rem;
        color: var(--text-color-secondary);
      }

      input {
        padding: 10px 12px;
        border-radius: 6px;
        border: 1px solid var(--border-color);
        background-color: var(--input-bg-color, rgba(255, 255, 255, 0.05));
        color: var(--text-color);
        font-size: 0.95rem;

        &:focus {
          outline: none;
          border-color: var(--primary-color);
          box-shadow: 0 0 0 1px var(--primary-color-light);
        }

        &::placeholder {
          color: var(--text-color-secondary, rgba(255, 255, 255, 0.5));
        }
      }
    }

    .dialog-actions {
      display: flex;
      justify-content: flex-end;
      gap: 12px;
      margin-top: 8px;

      button {
        padding: 8px 16px;
        border-radius: 6px;
        font-size: 0.9rem;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.2s ease;

        &.cancel-button {
          background: none;
          border: 1px solid var(--border-color);
          color: var(--text-color);

          &:hover {
            background-color: var(--hover-color);
          }
        }

        &.confirm-button {
          background-color: var(--primary-color, #ffc807);
          border: none;
          color: var(--primary-button-text, #000);

          &:hover:not(:disabled) {
            background-color: var(--primary-color-dark, #d1a300);
          }

          &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1050;
  backdrop-filter: blur(2px);
}

// PlayStation-like glassy icons
.psn-icons {
  display: flex;
  gap: 1rem;
  margin: 1.5rem 0;

  .psn-icon {
    @include glassy-base;
    @include glassy-icon;
    @include glassy-md;
    background: linear-gradient(
      145deg,
      rgba(20, 30, 45, 0.7),
      rgba(10, 20, 35, 0.8)
    );

    svg {
      color: #00a8e8; // PlayStation blue color
    }

    &.store {
      svg {
        color: #00a8e8; // PlayStation store blue
      }
    }

    &.plus {
      svg {
        color: #ffd400; // PlayStation Plus yellow
      }
    }

    &.trophy {
      svg {
        color: #cddc39; // Trophy green/yellow
      }
    }
  }
}

// Console panel example
.console-panel {
  @include glassy-base;
  padding: 1.5rem;
  margin: 1.5rem 0;

  .panel-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    h3 {
      color: white;
      margin: 0;
    }

    .panel-icon {
      @include glassy-base;
      @include glassy-icon;
      @include glassy-sm;
    }
  }

  .panel-content {
    color: rgba(255, 255, 255, 0.8);
  }
}
