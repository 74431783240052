@import "../../_variables";

.menuDashboard {
  position: fixed;
  left: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;

  .menu-trigger {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    border: none;
    background: linear-gradient(145deg, #ffc807, #d1a300);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-shadow: 0 4px 15px rgba(255, 200, 7, 0.25);
    transition: all 0.3s ease;

    .trigger-icon {
      font-size: 1.5rem;
      color: #000;
      animation: pulse 3s ease-in-out infinite;
      filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5));
    }

    &:hover {
      transform: scale(1.05);
      box-shadow: 0 6px 20px rgba(255, 200, 7, 0.35);
    }
  }

  .menu-items {
    position: absolute;
    left: calc(100% + 1rem);
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.85);
    backdrop-filter: blur(12px);
    padding: 1rem;
    border-radius: 16px;
    border: 1px solid rgba(255, 200, 7, 0.15);
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2);

    .menu-disabled-message {
      padding: 10px 15px;
      margin-bottom: 10px;
      background-color: rgba(255, 0, 0, 0.1);
      border-radius: 8px;
      color: #ff5f5f;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
    }

    .menu-grid.disabled {
      opacity: 0.7;
      pointer-events: none;
    }

    .menu-item.disabled {
      cursor: not-allowed;
      opacity: 0.5;

      &:hover {
        background-color: rgba(255, 255, 255, 0.05);
        transform: none;
      }
    }
  }

  .menu-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.75rem;
    min-width: max-content;
  }

  .menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem;
    background: linear-gradient(145deg, #ffc807, #d1a300);
    border-radius: 12px;
    cursor: pointer;
    color: #000;
    font-weight: 500;
    box-shadow: 0 2px 8px rgba(255, 200, 7, 0.15);
    transition: all 0.2s ease;

    svg {
      font-size: 1.5rem;
    }

    span {
      font-size: 0.85rem;
      white-space: nowrap;
    }

    &:hover {
      transform: scale(1.05);
      box-shadow: 0 4px 12px rgba(255, 200, 7, 0.25);
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.5));
  }
  25% {
    transform: scale(1.1);
    filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.6));
  }
  50% {
    transform: scale(1);
    filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.5));
  }
  75% {
    transform: scale(1.05);
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.6));
  }
  100% {
    transform: scale(1);
    filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.5));
  }
}

// Mobile styles
@media (max-width: 768px) {
  .menuDashboard {
    left: 1rem;
    bottom: 6rem;
    top: auto;
    transform: none;

    .menu-trigger {
      width: 3rem;
      height: 3rem;

      .trigger-icon {
        font-size: 1.25rem;
      }
    }

    .menu-items {
      left: 0;
      bottom: calc(100% + 0.5rem);
      top: auto;
      transform: none;
      max-width: 90vw;
    }

    .menu-grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 0.5rem;
    }

    .menu-item {
      padding: 0.75rem;

      svg {
        font-size: 1.25rem;
      }

      span {
        font-size: 0.8rem;
      }
    }
  }
}

// Extra small devices
@media (max-width: 480px) {
  .menuDashboard {
    left: 0.75rem;
    bottom: 5rem;

    .menu-items {
      max-width: 85vw;
    }
  }
}

// Collapsed state
.menuDashboard.collapsed {
  transform: translateX(-100%);
  opacity: 0.5;

  &:hover {
    transform: translateX(0);
    opacity: 1;
  }
}
