@import "../../_variables";
@import "../../_animations";
@import "../../_glassy";

.search-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 1000;
}

.search-container {
  width: 100%;
  max-width: 600px;
  margin-top: 80px;
  background: #151515;
  border-radius: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.4);

  .search-header {
    padding: 1rem;
    display: flex;
    gap: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    .search-input-wrapper {
      flex: 1;
      position: relative;
      display: flex;
      align-items: center;

      .search-icon {
        position: absolute;
        left: 1rem;
        color: rgba(255, 255, 255, 0.5);
        font-size: 1.25rem;
      }

      .search-input {
        width: 100%;
        background: rgba(255, 255, 255, 0.05);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 0.5rem;
        padding: 0.75rem 2.75rem;
        color: #fff;
        font-size: 1rem;

        &:focus {
          outline: none;
          border-color: rgba(255, 200, 7, 0.3);
          background: rgba(255, 255, 255, 0.08);
        }
      }
    }

    .close-btn {
      background: rgba(255, 255, 255, 0.1);
      border: none;
      width: 40px;
      height: 40px;
      border-radius: 0.5rem;
      color: rgba(255, 255, 255, 0.8);
      font-size: 1.25rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s;

      &:hover {
        background: rgba(255, 255, 255, 0.15);
        color: #fff;
      }
    }
  }

  .search-tabs {
    display: flex;
    gap: 0.5rem;
    padding: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    .tab-button {
      background: none;
      border: none;
      color: rgba(255, 255, 255, 0.6);
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      font-size: 0.9rem;
      cursor: pointer;
      transition: all 0.2s;
      display: flex;
      align-items: center;
      gap: 0.5rem;

      svg {
        font-size: 1.25rem;
      }

      &:hover {
        background: rgba(255, 255, 255, 0.05);
        color: #fff;
      }

      &.active {
        background: rgba(255, 200, 7, 0.1);
        color: #ffc807;
      }
    }
  }

  .search-results {
    max-height: calc(100vh - 300px);
    overflow-y: auto;
    padding: 1rem;

    .search-loading,
    .search-error,
    .no-results {
      text-align: center;
      padding: 2rem;
      color: rgba(255, 255, 255, 0.5);
    }

    .search-error {
      color: #ff4081;
    }

    .search-result-item {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 0.75rem;
      border-radius: 0.5rem;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        background: rgba(255, 255, 255, 0.05);
      }

      .result-avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        background: rgba(255, 255, 255, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .avatar-placeholder {
          color: #fff;
          font-weight: 600;
          font-size: 1rem;
        }
      }

      .result-info {
        flex: 1;
        min-width: 0;

        h3 {
          margin: 0;
          color: #fff;
          font-size: 0.95rem;
          font-weight: 500;
        }

        p {
          margin: 0.25rem 0 0;
          color: rgba(255, 255, 255, 0.5);
          font-size: 0.85rem;
        }
      }

      .type-indicator {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transition: all 0.2s;

        .type-icon {
          font-size: 1.1rem;
        }

        &.user {
          background: rgba(64, 196, 255, 0.1);
          color: #40c4ff;
        }

        &.event {
          background: rgba(255, 64, 129, 0.1);
          color: #ff4081;
        }

        &.brand {
          background: rgba(255, 200, 7, 0.1);
          color: #ffc807;
        }
      }

      &.glassy {
        @include glassy-base;
        background: linear-gradient(
          145deg,
          rgba(25, 25, 25, 0.7),
          rgba(15, 15, 15, 0.8)
        );
        margin: 10px 0;
        transition: all 0.3s ease;

        .type-indicator {
          @include glassy-base;
          @include glassy-icon;
          @include glassy-xs;
          margin-left: 8px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .search-container {
    margin: 1rem;
    max-height: calc(100vh - 2rem);

    .search-tabs {
      overflow-x: auto;
      padding-bottom: 0.5rem;

      .tab-button {
        white-space: nowrap;
      }
    }
  }
}
