@import "../../_variables";
@import "../../_glassy";

.confirm-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.confirm-dialog {
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.1);

  .dialog-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 12px;
    color: #333;
  }

  .dialog-message {
    margin-bottom: 24px;
    color: #555;
    line-height: 1.5;
  }

  .dialog-buttons {
    display: flex;
    justify-content: space-between;
    gap: 12px;

    button {
      padding: 0.75rem 1rem;
      border-radius: 6px;
      font-weight: 500;
      transition: all 0.2s ease;
      flex: 1;
      max-width: 48%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.cancel-button {
        background-color: #f5f5f5;
        color: #333;
        border: 1px solid #ddd;

        &:hover {
          background-color: #e9e9e9;
        }
      }

      &.confirm-button {
        background-color: #4caf50;
        color: white;
        border: none;

        &:hover {
          background-color: #43a047;
        }
      }

      &.danger-button {
        background-color: #f44336;
        color: white;
        border: none;

        &:hover {
          background-color: #e53935;
        }
      }
    }
  }
}

// Mobile responsiveness
@media (max-width: 480px) {
  .confirm-dialog {
    width: 95%;
    padding: 20px;

    .dialog-buttons {
      flex-direction: column;

      button {
        max-width: 100%;
        margin-bottom: 8px;
      }
    }
  }
}
