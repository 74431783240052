.page-wrapper {
  min-height: 100vh;
  background: #000;
}

.brands {
  padding: 1rem;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 6rem;

  &-header {
    text-align: center;
    margin-bottom: 2rem;

    h1 {
      color: #fff;
      margin-bottom: 0.5rem;
    }

    p {
      color: rgba(255, 255, 255, 0.7);
    }
  }

  &-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
    padding: 1rem;
  }
}

.brand-card {
  position: relative;
  width: 100%;
  background: #151515;
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
  cursor: pointer;
  transform-style: preserve-3d;
  transition: transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  perspective: 1000px;

  &.flipped {
    transform: rotateY(180deg);

    .card-front {
      pointer-events: none;
    }

    .card-back {
      pointer-events: auto;
    }
  }

  .card-front,
  .card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background: #151515;
    border-radius: 24px;
  }

  .card-back {
    transform: rotateY(180deg);
    pointer-events: none;

    .no-permission-message {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding: 2rem;
      background: rgba(0, 0, 0, 0.8);
      text-align: center;

      h3 {
        color: #f44336;
        font-size: 1.5rem;
        margin-bottom: 1rem;
      }

      p {
        color: rgba(255, 255, 255, 0.8);
        font-size: 1.1rem;
        line-height: 1.5;
        margin-bottom: 1.5rem;
      }

      .back-button {
        padding: 0.5rem 1.25rem;
        background: rgba(255, 255, 255, 0.2);
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 4px;
        color: white;
        font-size: 0.9rem;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
          background: rgba(255, 255, 255, 0.3);
        }
      }
    }
  }

  .brand-card-header {
    position: relative;
    height: 160px;
    overflow: hidden;

    .brand-cover-image {
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.05);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .placeholder-cover {
        width: 100%;
        height: 100%;
        background: linear-gradient(
          45deg,
          rgba(255, 255, 255, 0.05),
          rgba(255, 255, 255, 0.1)
        );
      }
    }

    .card-actions {
      position: absolute;
      top: 1rem;
      right: 1rem;
      display: flex;
      gap: 0.5rem;
      z-index: 2;

      .action-button {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: none;
        background: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(4px);
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.2s ease;

        svg {
          font-size: 1.1rem;
        }

        &:hover {
          background: rgba(255, 200, 7, 0.2);
          color: #ffc807;
        }
      }
    }
  }

  &-content {
    padding: 1.5rem;
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .brand-logo {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      border: 3px solid #ffc807;
      position: absolute;
      top: -40px;
      left: 1.5rem;
      object-fit: cover;
      background-color: #000;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
      z-index: 1;
      overflow: hidden;
    }

    .brand-info {
      margin-top: 2rem;

      h3 {
        color: #fff;
        margin: 0 0 0.25rem;
        font-size: 1.25rem;
      }

      .username {
        color: #ffc807;
        font-size: 0.9rem;
        opacity: 0.8;
      }

      .description {
        color: rgba(255, 255, 255, 0.7);
        font-size: 0.9rem;
        margin: 0.5rem 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    .brand-details {
      margin-top: auto;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      padding-top: 1rem;

      .social-icons {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
        margin-bottom: 1rem;
        padding: 0.5rem;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 0.5rem;

        .social-icon {
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          font-size: 1rem;
          transition: all 0.3s ease;

          &.active {
            background: #ffc807;
            color: #000;

            &:hover {
              transform: scale(1.1);
            }
          }

          &.empty {
            background: rgba(255, 255, 255, 0.1);
            color: rgba(255, 255, 255, 0.3);
          }
        }
      }

      .contact-section {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .contact-info {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          padding: 0.5rem;
          border-radius: 0.5rem;
          font-size: 0.9rem;
          transition: all 0.3s ease;

          &.active {
            background: rgba(255, 200, 7, 0.1);
            color: #fff;

            svg {
              color: #ffc807;
            }
          }

          &.empty {
            background: rgba(255, 255, 255, 0.05);
            color: rgba(255, 255, 255, 0.3);

            span {
              display: none;
            }
          }

          svg {
            font-size: 1.1rem;
          }

          span {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
}

.add-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 420px;
  background: rgba(255, 255, 255, 0.03);
  border: 2px dashed rgba(255, 200, 7, 0.2);

  .add-icon {
    font-size: 3rem;
    color: #ffc807;
    margin-bottom: 1rem;
  }

  p {
    color: rgba(255, 255, 255, 0.7);
  }

  &:hover {
    background: rgba(255, 200, 7, 0.05);
  }
}

@media (max-width: 768px) {
  .brands-grid {
    grid-template-columns: 1fr;
    padding: 0.5rem;
  }

  .brand-card {
    min-height: 550px;
  }
}
