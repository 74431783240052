@import "../../_variables";

.table-code-management {
  padding: 1rem;
  color: #fff;
  margin-top: 1rem;
  width: 100%;
  position: relative;

  h2 {
    color: #fff;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  .category-header {
    margin-bottom: 0.5rem;

    h3 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 1rem;
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 8px;
      font-size: 1rem;
      margin: 0;
      font-weight: 600;
    }

    .category-counts {
      font-size: 0.8rem;
      font-weight: 400;
      display: flex;
      gap: 0.5rem;

      .count-pending {
        color: #ffc107;
      }

      .count-total {
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }

  .table-category {
    margin-bottom: 2rem;

    h3 {
      font-size: 1.1rem;
      font-weight: 600;
      margin-bottom: 1rem;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      color: rgba(255, 255, 255, 0.7);
      text-align: center;
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.06rem;
      text-transform: uppercase;
      margin-top: 1.5rem;
    }
  }

  .reservations-list {
    display: grid;
    gap: 1rem;

    .reservation-item {
      background: rgba(21, 21, 21, 0.8);
      border-radius: 0.75rem;
      padding: 1.25rem;
      margin-bottom: 1rem;
      border: 1px solid rgba(255, 255, 255, 0.1);
      transition: all 0.3s ease;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      width: 100%;
      position: relative;
      overflow: visible !important;

      // Paper texture overlay
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url("data:image/svg+xml,%3Csvg width='200' height='200' viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noise'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.75' numOctaves='2' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noise)' opacity='0.15'/%3E%3C/svg%3E");
        border-radius: 12px;
        pointer-events: none;
        z-index: 1;
        opacity: 0.4;
        mix-blend-mode: overlay;
      }

      @media (max-width: 768px) {
        padding: 1rem;
        flex-direction: column;
        align-items: stretch;
        border-radius: 0.5rem;
        gap: 0.75rem;
      }

      // Specific status styles with left borders
      &.confirmed {
        border-left: 3px solid #28a745;
      }

      &.pending {
        border-left: 3px solid #ffc107;
      }

      &.declined,
      &.cancelled {
        border-left: 3px solid #dc3545;
        opacity: 0.7;
      }

      &:hover {
        transform: translateY(-2px);
        border-color: rgba(255, 200, 7, 0.3);
        box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
        animation: subtle-float 3s ease infinite;
      }

      // Styling for when the item is being edited
      &.editing {
        background: rgba(30, 30, 30, 0.9);
        border-color: rgba(255, 200, 7, 0.5) !important;
        box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
        transform: translateY(-2px);
      }

      // Checked-in styles
      &.checked-in {
        background-color: rgba(40, 167, 69, 0.15);
        position: relative;
        overflow: hidden;

        &::after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 2.5rem 2.5rem 0;
          border-color: transparent rgba(40, 167, 69, 0.3) transparent
            transparent;
          z-index: 1;
        }

        .reservation-info {
          .pax-count {
            position: relative;
            background: rgba(40, 167, 69, 0.15);
            color: #28a745;

            &::after {
              content: "✓";
              margin-left: 0.5rem;
              font-weight: bold;
            }
          }
        }

        // Add subtle glow effect
        box-shadow: inset 0 0 20px rgba(40, 167, 69, 0.1);
      }

      // Update existing confirmed style to work with checked-in
      &.confirmed.checked-in {
        border-left: 4px solid #1a8d3c; // Slightly darker green for contrast
        background: linear-gradient(
          90deg,
          rgba(40, 167, 69, 0.05) 0%,
          rgba(26, 26, 26, 1) 100%
        );
      }

      .reservation-details {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 10px;
        width: 100%;
        position: relative;
        z-index: 2;

        .reservation-info {
          flex: 1;
          display: flex;
          align-items: center;
          gap: 1rem;
          min-width: 0;

          .table-number-badge {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 3rem;
            height: 3rem;
            min-width: 3rem;
            border-radius: 0.5rem;
            background: linear-gradient(45deg, #7b1fa2, #9c27b0);
            margin-right: 1rem;
            flex-shrink: 0;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
            transition: all 0.3s ease;
            font-weight: bold;
            font-size: 1.2rem;
            color: white;
            overflow: hidden;
            position: relative;

            @media (max-width: 768px) {
              width: 2.5rem;
              height: 2.5rem;
              min-width: 2.5rem;
              font-size: 1rem;
            }

            &:hover {
              transform: scale(1.05);
              box-shadow: 0 6px 15px rgba(0, 0, 0, 0.4);
            }

            .table-select-inline {
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              width: 100%;
              height: 100%;
              background: transparent;
              border: none;
              color: white;
              font-weight: bold;
              font-size: 1rem;
              text-align: center;
              text-align-last: center;
              cursor: pointer;
              padding: 0;

              &:focus {
                outline: none;
              }

              optgroup,
              option {
                background-color: #2a2a2a;
                color: white;
                font-size: 0.9rem;
              }

              optgroup {
                font-weight: bold;
              }
            }

            // Add a more robust visual indicator that it's selectable
            &:has(.table-select-inline) {
              cursor: pointer;

              &:after {
                content: "▼";
                position: absolute;
                bottom: 0.25rem;
                right: 0.25rem;
                font-size: 0.6rem;
                opacity: 0.8;
                pointer-events: none;
                z-index: 1;
              }

              &:before {
                content: "";
                position: absolute;
                inset: 0;
                border: 2px solid rgba(255, 255, 255, 0.3);
                opacity: 0;
                transition: opacity 0.2s ease;
                pointer-events: none;
                border-radius: 0.4rem;
                z-index: 1;
              }

              &:hover:before {
                opacity: 1;
              }
            }

            /* Fallback for browsers that don't support :has() */
            .table-select-inline-wrapper {
              position: relative;
              width: 100%;
              height: 100%;

              &:after {
                content: "▼";
                position: absolute;
                bottom: 0.25rem;
                right: 0.25rem;
                font-size: 0.6rem;
                opacity: 0.8;
                pointer-events: none;
                z-index: 1;
              }

              &:hover:before {
                opacity: 1;
              }

              &:before {
                content: "";
                position: absolute;
                inset: 0;
                border: 2px solid rgba(255, 255, 255, 0.3);
                opacity: 0;
                transition: opacity 0.2s ease;
                pointer-events: none;
                border-radius: 0.4rem;
                z-index: 1;
              }
            }

            /* iOS Safari specific styles */
            @supports (-webkit-touch-callout: none) {
              .table-select-inline {
                text-align-last: center;
                padding-bottom: 2px; /* Slight adjustment for iOS centering */
              }
            }
          }

          .guest-details {
            min-width: 0;
            flex: 1;

            .guest-name {
              font-size: 1.1rem;
              font-weight: 600;
              color: white;
              margin: 0;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              @media (max-width: 768px) {
                font-size: 1rem;
              }
            }

            .edit-name-input {
              width: 100%;
              padding: 6px 10px;
              border-radius: 6px;
              background: rgba(0, 0, 0, 0.2);
              border: 1px solid rgba(255, 255, 255, 0.2);
              color: white;
              font-size: 1rem;
              font-weight: 500;
              margin-bottom: 4px;

              &:focus {
                outline: none;
                border-color: rgba(255, 200, 7, 0.5);
                box-shadow: 0 0 0 2px rgba(255, 200, 7, 0.2);
              }
            }

            .host-name {
              font-size: 0.8rem;
              color: rgba(255, 255, 255, 0.5);
              margin-top: 0.25rem;
            }

            .status-badge {
              display: inline-block;
              padding: 0.2rem 0.5rem;
              border-radius: 4px;
              font-size: 0.7rem;
              text-transform: uppercase;
              font-weight: 500;
              margin-top: 0.25rem;

              &.confirmed {
                background-color: rgba(40, 167, 69, 0.2);
                color: #28a745;
              }

              &.pending {
                background-color: rgba(255, 193, 7, 0.2);
                color: #ffc107;
              }

              &.declined,
              &.cancelled {
                background-color: rgba(220, 53, 69, 0.2);
                color: #dc3545;
              }
            }

            .edit-pax-select {
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              background: rgba(0, 0, 0, 0.4);
              border: 1px solid rgba(255, 255, 255, 0.3);
              border-radius: 4px;
              color: white;
              padding: 2px 8px;
              font-size: 0.9rem;
              font-weight: 600;
              cursor: pointer;
              position: relative;
              min-width: 2.5rem;
              text-align: center;
              padding-right: 1.5rem;
              transition: all 0.2s ease;

              /* More robust dropdown arrow */
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' viewBox='0 0 10 6'%3E%3Cpath fill='%23ffffff' d='M0 0h10L5 6z'/%3E%3C/svg%3E");
              background-repeat: no-repeat;
              background-position: calc(100% - 6px) center;
              background-size: 10px;

              &:focus {
                outline: none;
                border-color: rgba(255, 200, 7, 0.7);
                box-shadow: 0 0 0 2px rgba(255, 200, 7, 0.2);
              }

              &:hover {
                background-color: rgba(0, 0, 0, 0.5);
                border-color: rgba(255, 255, 255, 0.4);
              }

              option {
                background-color: #2a2a2a;
                color: white;
                padding: 4px;
                font-weight: normal;
              }

              /* iOS Safari specific styles */
              @supports (-webkit-touch-callout: none) {
                background-color: rgba(30, 30, 30, 0.95);
                padding-right: 2rem;
                text-align-last: center;

                /* Simplify the background arrow for iOS */
                background-image: none;

                /* Add position relative for the custom arrow */
                position: relative;

                /* Add custom arrow using ::after for iOS */
                &::after {
                  content: "▼";
                  position: absolute;
                  right: 8px;
                  top: 50%;
                  transform: translateY(-50%);
                  pointer-events: none;
                  font-size: 8px;
                  opacity: 0.8;
                }
              }
            }
          }
        }

        .pax-count-badge {
          display: flex;
          align-items: center;
          gap: 0.75rem;
          padding: 0 1.25rem;
          border-left: 1px solid rgba(255, 255, 255, 0.1);
          border-right: 1px solid rgba(255, 255, 255, 0.1);
          height: 3rem;
          position: relative;
          z-index: 2;

          @media (max-width: 768px) {
            border-left: none;
            border-right: none;
            border-top: 1px solid rgba(255, 255, 255, 0.1);
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            padding: 0.75rem 0;
            height: auto;
            justify-content: center;
          }

          .people-icon {
            color: rgba(255, 255, 255, 0.7);
            font-size: 1.25rem;
          }

          .people-count {
            font-size: 1.1rem;
            font-weight: 600;
            color: white;
          }

          .people-separator {
            font-size: 0.9rem;
            color: rgba(255, 255, 255, 0.5);
            margin: 0 0.25rem;
          }

          .people-max {
            font-size: 0.9rem;
            color: rgba(255, 255, 255, 0.5);
          }

          .checked-count {
            margin-left: 0.25rem;
            color: #28a745;
          }
        }

        .reservation-actions {
          display: flex;
          gap: 0.75rem;
          position: relative;
          z-index: 2;

          @media (max-width: 768px) {
            justify-content: space-between;
            width: 100%;
          }

          button {
            width: 3rem;
            height: 3rem;
            border-radius: 0.5rem;
            border: none;
            background: rgba(255, 255, 255, 0.1);
            color: rgba(255, 255, 255, 0.7);
            cursor: pointer;
            transition: all 0.2s ease;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.4rem;

            @media (max-width: 768px) {
              width: 3.2rem;
              height: 3.2rem;
              font-size: 1.5rem;
            }

            &:hover {
              transform: translateY(-2px);
            }

            svg {
              width: 1.4rem;
              height: 1.4rem;
            }

            &.confirm {
              &:hover {
                background: rgba(40, 167, 69, 0.2);
                color: #28a745;
              }
            }

            &.decline,
            &.cancel,
            &.delete {
              &:hover {
                background: rgba(220, 53, 69, 0.2);
                color: #dc3545;
              }
            }

            &.view {
              &:hover {
                background: rgba(76, 175, 80, 0.2);
                color: #4caf50;
              }
            }

            &.download {
              &:hover {
                background: rgba(255, 193, 7, 0.2);
                color: #ffc107;
              }
            }

            &.email {
              &:hover {
                background: rgba(156, 39, 176, 0.2);
                color: #9c27b0;
              }
            }

            &.edit {
              &:hover {
                background: rgba(33, 150, 243, 0.2);
                color: #2196f3;
              }
            }

            &.reset {
              &:hover {
                background: rgba(111, 66, 193, 0.2);
                color: #6f42c1;
              }
            }

            &.save-edit-btn {
              background: rgba(76, 175, 80, 0.2);
              color: #4caf50;
              font-size: 1.4rem;
              font-weight: bold;

              &:hover {
                background: rgba(76, 175, 80, 0.3);
                transform: translateY(-2px);
              }
            }

            &.cancel-edit-btn {
              background: rgba(244, 67, 54, 0.2);
              color: #f44336;
              font-size: 1.4rem;
              font-weight: bold;

              &:hover {
                background: rgba(244, 67, 54, 0.3);
                transform: translateY(-2px);
              }
            }
          }
        }
      }
    }
  }

  .edit-form {
    /* @deprecated - Kept for backward compatibility, use inline editing instead */
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.75rem;
    background: rgba(30, 30, 30, 0.9);
    border-radius: 0.75rem;
    border: 1px solid rgba(255, 200, 7, 0.5);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);

    input,
    select {
      padding: 0.5rem;
      border-radius: 0.25rem;
      border: 1px solid rgba(255, 255, 255, 0.2);
      background: rgba(0, 0, 0, 0.2);
      color: #fff;
      font-size: 0.9rem;

      &:focus {
        outline: none;
        border-color: rgba(255, 200, 7, 0.5);
        box-shadow: 0 0 0 2px rgba(255, 200, 7, 0.2);
      }
    }

    .table-select {
      max-height: 250px;

      optgroup {
        font-weight: 700;
        padding: 4px 0;
        background-color: #2a2a2a;

        &[label="DJ Area"] {
          color: #ffd700; // Gold
        }

        &[label="Backstage"] {
          color: #80221c; // Rich red
        }

        &[label="Vip"] {
          color: #1b5e20; // Green
        }

        &[label="Premium"] {
          color: #4a90e2; // Blue
        }
      }

      option {
        padding: 8px 12px;
        margin: 2px 0;
        background-color: #2a2a2a;

        &:disabled {
          color: #888;
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }

    .edit-actions {
      display: flex;
      gap: 0.5rem;
      margin-top: 0.5rem;

      button {
        flex: 1;
        padding: 0.5rem;
        border-radius: 0.25rem;
        border: none;
        cursor: pointer;
        transition: all 0.2s ease;

        &.save-edit-btn {
          background: rgba(76, 175, 80, 0.2);
          color: #4caf50;
          font-size: 1.1rem;
          font-weight: bold;

          &:hover {
            background: rgba(76, 175, 80, 0.3);
            color: #4caf50;
            transform: translateY(-2px);
          }
        }

        &.cancel-edit-btn {
          background: rgba(244, 67, 54, 0.2);
          color: #f44336;
          font-size: 1.1rem;
          font-weight: bold;

          &:hover {
            background: rgba(244, 67, 54, 0.3);
            color: #f44336;
            transform: translateY(-2px);
          }
        }
      }
    }
  }

  .code-view-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  .code-view-content {
    background-color: #fff;
    padding: 1rem;
    border-radius: 8px;
    max-width: 300px;
    width: 80%;

    img {
      width: 100%;
      height: auto;
    }
  }

  .load-more-btn {
    display: block;
    width: 100%;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 0.75rem;
    color: white;
    cursor: pointer;
    margin-top: 1.5rem;
    font-weight: 600;
    font-size: 1rem;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.05) 50%,
        rgba(255, 255, 255, 0) 100%
      );
      transform: translateX(-100%);
      transition: transform 0.6s ease;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.15);
      transform: translateY(-3px);
      box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);

      &::before {
        transform: translateX(100%);
      }
    }

    &:active {
      transform: translateY(-1px);
    }
  }

  .delete-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .delete-modal-content {
      background: #151515;
      border-radius: 1rem;
      padding: 2rem;
      max-width: 90%;
      max-height: 90%;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
      border: 1px solid rgba(255, 255, 255, 0.1);
      position: relative;

      .close-btn {
        position: absolute;
        top: 1rem;
        right: 1rem;
        background: none;
        border: none;
        color: rgba(255, 255, 255, 0.7);
        font-size: 1.5rem;
        cursor: pointer;

        &:hover {
          color: white;
        }
      }

      p {
        margin-bottom: 2rem;
        color: rgba(255, 255, 255, 0.7);
        font-size: 1rem;
      }

      h3 {
        margin-bottom: 1rem;
        color: white;
        font-size: 1.25rem;
        text-align: center;
      }

      .delete-modal-buttons {
        display: flex;
        gap: 1rem;
        justify-content: center;

        button {
          padding: 0.75rem 1.5rem;
          border-radius: 0.5rem;
          border: none;
          font-weight: 500;
          cursor: pointer;
          transition: all 0.2s ease;

          &.cancel-btn {
            background: rgba(255, 255, 255, 0.1);
            color: white;

            &:hover {
              background: rgba(255, 255, 255, 0.2);
            }
          }

          &.confirm-btn {
            background: #f44336;
            color: white;

            &:hover {
              background: #d32f2f;
            }
          }
        }
      }
    }
  }

  .loading-state,
  .no-reservations {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 150px;
    margin: 20px 0;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
    text-align: center;

    p {
      font-size: 16px;
      color: #ffffff;
      opacity: 0.7;
      margin: 5px 0;
    }

    p:first-child {
      font-weight: 500;
      opacity: 0.9;
    }
  }

  .send-email-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
    animation: modalFadeIn 0.3s ease;
  }

  .send-email-modal-content {
    background: #1a1a1a;
    border-radius: 12px;
    width: 360px;
    max-width: 95%;
    padding: 1.5rem;
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.1);
    position: relative;
    animation: modalPopIn 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 4px;
      background: linear-gradient(90deg, #ffc107, #e3a31d);
    }

    .close-btn {
      position: absolute;
      top: 12px;
      right: 12px;
      background: none;
      border: none;
      color: rgba(255, 255, 255, 0.7);
      font-size: 1.25rem;
      cursor: pointer;
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease;

      &:hover {
        color: white;
        transform: scale(1.1);
      }
    }

    h3 {
      margin: 0 0 1.25rem;
      color: white;
      font-size: 1.1rem;
      padding-right: 1.5rem;
    }

    p {
      color: rgba(255, 255, 255, 0.7);
      margin-bottom: 1rem;
    }

    input[type="email"] {
      width: 100%;
      padding: 12px 16px;
      border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      background: rgba(255, 255, 255, 0.05);
      color: white;
      font-size: 0.95rem;
      margin-bottom: 1rem;
      transition: all 0.2s ease;

      &:focus {
        outline: none;
        border-color: #ffc107;
        box-shadow: 0 0 0 2px rgba(255, 193, 7, 0.3);
      }

      &::placeholder {
        color: rgba(255, 255, 255, 0.4);
      }
    }

    .send-email-modal-buttons {
      display: flex;
      gap: 8px;

      button {
        flex: 1;
        padding: 12px 16px;
        border-radius: 8px;
        border: none;
        font-weight: 600;
        cursor: pointer;
        transition: all 0.2s ease;

        &.confirm-btn {
          background: linear-gradient(45deg, #ffc107, #e3a31d);
          color: #212121;

          &:hover:not(:disabled) {
            transform: translateY(-2px);
            box-shadow: 0 5px 15px rgba(255, 193, 7, 0.4);
          }

          &:active:not(:disabled) {
            transform: translateY(0);
          }

          &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
            background: rgba(255, 255, 255, 0.1);
            color: rgba(255, 255, 255, 0.6);
          }
        }

        &.cancel-btn {
          background: rgba(255, 255, 255, 0.1);
          color: white;

          &:hover:not(:disabled) {
            background: rgba(255, 255, 255, 0.2);
          }

          &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
      }
    }
  }

  @keyframes modalPopIn {
    0% {
      opacity: 0;
      transform: scale(0.8);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  // PNG View Modal - Fullscreen
  .code-png-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .close-btn {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.7);
      color: white;
      border: 1px solid rgba(255, 255, 255, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      cursor: pointer;
      z-index: 1010;
      transition: all 0.2s ease;

      &:hover {
        background-color: rgba(50, 50, 50, 0.9);
        transform: scale(1.1);
      }
    }

    .png-container {
      max-width: 100%;
      max-height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 100%;
        max-height: 100vh;
        object-fit: contain;
        animation: imageFadeIn 0.5s ease;
      }
    }
  }

  @keyframes imageFadeIn {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes modalFadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes subtle-float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-3px);
    }
    100% {
      transform: translateY(0);
    }
  }
}

// Responsive adjustments
@media (max-width: 768px) {
  .table-code-management {
    .reservations-list {
      .reservation-item {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.75rem;
        padding: 0.75rem;

        .reservation-details {
          .reservation-info {
            width: 100%;
          }

          .pax-count-badge {
            border-left: none;
            border-right: none;
            border-top: 1px solid rgba(255, 255, 255, 0.1);
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            width: 100%;
            padding: 0.5rem 0;
            height: auto;
            justify-content: center;
          }

          .reservation-actions {
            width: 100%;
            justify-content: space-between;
          }
        }
      }
    }
  }
}
