.popup-overlay {
  position: fixed;
  inset: 0;
  z-index: 50;
  background: rgba(0, 0, 0, 0.35);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.popup-container {
  background: rgba(26, 26, 26, 0.98);
  border-radius: 16px;
  padding: 1.5rem;
  width: 340px; // Slightly wider than before
  box-shadow: 0 4px 20px -1px rgba(0, 0, 0, 0.3),
    0 2px 8px -1px rgba(0, 0, 0, 0.2), inset 0 1px 2px rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: absolute;
  animation: popupEnter 0.3s ease-out;

  // Position the popup relative to the table
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  // Gradient overlay
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      45deg,
      rgba(255, 215, 0, 0.03),
      rgba(0, 0, 0, 0) 70%
    );
    border-radius: inherit;
    pointer-events: none;
  }

  // Edge glow
  &::after {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-radius: inherit;
    background: linear-gradient(
      45deg,
      rgba(255, 215, 0, 0.1),
      transparent 30%,
      transparent 70%,
      rgba(255, 215, 0, 0.1)
    );
    pointer-events: none;
    z-index: -1;
  }
}

.popup-close {
  position: absolute;
  right: 1rem;
  top: 1rem;
  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.6);
  padding: 0.25rem;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    color: rgba(255, 255, 255, 0.9);
    transform: scale(1.1);
  }
}

.popup-header {
  margin-bottom: 1.5rem;
  padding-right: 1.5rem;

  h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.375rem;
    color: #ffd700;
  }

  p {
    font-size: 0.9375rem;
    color: rgba(255, 255, 255, 0.6);
  }
}

.popup-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  // Make all form elements the same width
  > * {
    width: 100% !important;
  }
}

.popup-input {
  background: rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 0.875rem 1rem;
  color: white;
  font-size: 1rem;
  transition: all 0.2s ease;

  &:focus {
    outline: none;
    border-color: rgba(255, 215, 0, 0.5);
    box-shadow: 0 0 0 1px rgba(255, 215, 0, 0.2);
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }
}

.popup-select {
  @extend .popup-input;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 0.65rem auto;
  padding-right: 2.5rem;
}

.popup-button {
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
  padding: 0.875rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 1rem;

  &:hover:not(:disabled) {
    background: rgba(255, 255, 255, 0.15);
  }

  &:disabled {
    background: rgba(255, 255, 255, 0.05);
    color: rgba(255, 255, 255, 0.3);
    cursor: not-allowed;
  }
}

@keyframes popupEnter {
  from {
    opacity: 0;
    transform: translate(-50%, calc(-50% - 10px));
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

/* Additional CSS styles */
.minimum-spend {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  color: #ffd700;
  font-weight: 500;
  padding: 0.375rem 0.75rem;
  background: rgba(255, 215, 0, 0.1);
  border-radius: 4px;
  display: inline-block;
}
