.create-ticket-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 1rem;
  animation: fadeIn 0.2s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.95);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.create-ticket-dialog {
  background: #1a1a1a;
  border-radius: 16px;
  width: 100%;
  max-width: 600px;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.5), 0 0 0 1px rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
  max-height: calc(100vh - 2rem);
  display: flex;
  flex-direction: column;
  animation: scaleIn 0.3s ease-out;

  .dialog-header {
    padding: 1.25rem 1.5rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(0, 0, 0, 0.3);

    h3 {
      margin: 0;
      font-size: 1.2rem;
      color: #fff;
      display: flex;
      align-items: center;
      gap: 0.75rem;
      font-weight: 600;

      svg {
        font-size: 1.3rem;
        color: #2196f3;
      }
    }

    .close-button {
      background: rgba(255, 255, 255, 0.05);
      border: none;
      color: rgba(255, 255, 255, 0.8);
      cursor: pointer;
      padding: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: all 0.2s ease;
      width: 32px;
      height: 32px;

      svg {
        font-size: 1.5rem;
      }

      &:hover {
        color: #fff;
        background: rgba(255, 255, 255, 0.15);
        transform: rotate(90deg);
      }
    }
  }

  .dialog-content {
    padding: 1.75rem;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    max-height: 70vh;

    .form-group {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      label {
        font-size: 0.9rem;
        color: rgba(255, 255, 255, 0.8);
        font-weight: 500;
        margin-bottom: 0.25rem;

        &.strikethrough {
          text-decoration: line-through;
          color: rgba(255, 255, 255, 0.6);
        }

        .hint {
          font-size: 0.8rem;
          color: rgba(255, 255, 255, 0.5);
          font-weight: normal;
          font-style: italic;
          margin-left: 0.5rem;
        }
      }

      input[type="text"],
      input[type="number"],
      input[type="datetime-local"],
      textarea {
        background: rgba(255, 255, 255, 0.05);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 10px;
        padding: 0.85rem 1rem;
        color: #fff;
        font-size: 0.95rem;
        transition: all 0.2s ease;

        &:focus {
          outline: none;
          border-color: rgba(33, 150, 243, 0.6);
          background: rgba(255, 255, 255, 0.07);
          box-shadow: 0 0 0 3px rgba(33, 150, 243, 0.15);
        }

        &.error {
          border-color: #ff3b30;
          background: rgba(255, 59, 48, 0.05);

          &:focus {
            box-shadow: 0 0 0 3px rgba(255, 59, 48, 0.15);
          }
        }

        &::placeholder {
          color: rgba(255, 255, 255, 0.3);
        }
      }

      textarea {
        min-height: 100px;
        resize: vertical;
      }

      .error-message {
        color: #ff3b30;
        font-size: 0.8rem;
        margin-top: 0.25rem;
        display: flex;
        align-items: center;
        gap: 0.25rem;

        &::before {
          content: "⚠️";
          font-size: 0.9rem;
        }
      }
    }

    .form-row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1.25rem;
    }

    .checkbox-group {
      label {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        cursor: pointer;
        user-select: none;
        padding: 0.5rem 0;

        input[type="checkbox"] {
          width: 20px;
          height: 20px;
          border-radius: 6px;
          border: 1px solid rgba(255, 255, 255, 0.2);
          background: rgba(255, 255, 255, 0.05);
          appearance: none;
          cursor: pointer;
          position: relative;
          transition: all 0.2s ease;

          &:checked {
            background: #2196f3;
            border-color: #2196f3;

            &::after {
              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) rotate(45deg);
              width: 5px;
              height: 10px;
              border: solid #fff;
              border-width: 0 2px 2px 0;
            }
          }

          &:focus {
            outline: none;
            box-shadow: 0 0 0 3px rgba(33, 150, 243, 0.2);
          }
        }
      }
    }

    .date-input-container {
      position: relative;

      svg {
        position: absolute;
        left: 1rem;
        top: 50%;
        transform: translateY(-50%);
        color: rgba(255, 255, 255, 0.5);
        font-size: 1.1rem;
        pointer-events: none;
      }

      input {
        padding-left: 2.5rem;
      }
    }

    .color-preview-container {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 0.85rem 1rem;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        background: rgba(0, 0, 0, 0.3);
        transform: translateY(-2px);
      }

      .color-preview {
        width: 40px;
        height: 40px;
        border-radius: 10px;
        border: 2px solid rgba(255, 255, 255, 0.1);
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
      }

      .color-value {
        font-size: 0.9rem;
        color: rgba(255, 255, 255, 0.8);
        font-family: monospace;
        letter-spacing: 0.5px;
        flex: 1;
      }

      .color-icon {
        font-size: 1.2rem;
        color: rgba(255, 255, 255, 0.6);
      }
    }

    .countdown-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-bottom: 1rem;
    }

    .date-picker-container {
      position: relative;
      display: flex;
      align-items: center;

      input[type="date"] {
        width: 100%;
        padding-right: 2.5rem;
        background: rgba(255, 255, 255, 0.05);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 10px;
        padding: 0.85rem 1rem;
        color: #fff;
        font-size: 0.95rem;

        &::-webkit-calendar-picker-indicator {
          filter: invert(1);
          opacity: 0.5;
          cursor: pointer;
        }

        &:focus {
          outline: none;
          border-color: rgba(33, 150, 243, 0.6);
          background: rgba(255, 255, 255, 0.07);
          box-shadow: 0 0 0 3px rgba(33, 150, 243, 0.15);
        }
      }

      .date-icon {
        position: absolute;
        right: 1rem;
        color: rgba(255, 255, 255, 0.5);
        font-size: 1.2rem;
        pointer-events: none;
      }
    }

    .input-with-icon {
      position: relative;
      display: flex;
      align-items: center;
      background: rgba(255, 255, 255, 0.05);
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 10px;
      padding: 0 1rem;

      svg {
        color: rgba(255, 255, 255, 0.5);
        font-size: 1.2rem;
        margin-right: 0.5rem;
      }

      input {
        background: transparent;
        border: none;
        flex: 1;
        padding: 0.85rem 0;
        color: #fff;
        font-size: 0.95rem;

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
  }

  .dialog-actions {
    padding: 1.25rem 1.75rem;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    background: rgba(0, 0, 0, 0.3);
    border-top: 1px solid rgba(255, 255, 255, 0.1);

    button {
      padding: 0.85rem 1.75rem;
      border-radius: 10px;
      font-size: 0.95rem;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.2s ease;

      &.cancel-button {
        background: rgba(255, 255, 255, 0.05);
        border: 1px solid rgba(255, 255, 255, 0.1);
        color: rgba(255, 255, 255, 0.8);

        &:hover {
          background: rgba(255, 255, 255, 0.1);
          color: #fff;
          transform: translateY(-2px);
        }
      }

      &.save-button {
        background: #2196f3;
        border: none;
        color: #fff;
        box-shadow: 0 4px 10px rgba(33, 150, 243, 0.3);

        &:hover {
          background: #1976d2;
          transform: translateY(-2px);
          box-shadow: 0 6px 15px rgba(33, 150, 243, 0.4);
        }
      }
    }
  }
}

// Media queries for responsiveness
@media (max-width: 768px) {
  .create-ticket-dialog {
    max-width: 100%;
    margin: 1rem;
    border-radius: 12px;

    .dialog-content {
      padding: 1.25rem;

      .form-row {
        grid-template-columns: 1fr;
        gap: 1rem;
      }
    }

    .dialog-actions {
      padding: 1rem 1.25rem;
    }
  }
}

@media (max-width: 480px) {
  .create-ticket-dialog {
    .dialog-header {
      padding: 1rem;

      h3 {
        font-size: 1rem;
      }
    }

    .dialog-content {
      padding: 1rem;
      gap: 1rem;

      .form-group {
        input,
        textarea {
          padding: 0.75rem;
          font-size: 0.9rem;
        }
      }
    }

    .dialog-actions {
      padding: 1rem;
      flex-direction: column;
      gap: 0.75rem;

      button {
        width: 100%;
        padding: 0.75rem 1rem;
        font-size: 0.9rem;
      }
    }
  }
}
