.alpha-access {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  padding: 2rem;

  &__container {
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(10px);
    border-radius: 12px;
    padding: 2rem;
    width: 100%;
    max-width: 500px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.1);
    text-align: center;

    h2 {
      color: #ffc807;
      font-size: 1.8rem;
      margin-bottom: 1rem;
      font-weight: 600;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }

    p {
      color: rgba(255, 255, 255, 0.8);
      margin-bottom: 2rem;
      font-size: 1rem;
    }
  }

  &__code-inputs {
    display: flex;
    justify-content: center;
    gap: 0.8rem;
    margin-bottom: 1.5rem;

    input {
      width: 60px;
      height: 70px;
      border-radius: 8px;
      border: 2px solid rgba(255, 255, 255, 0.2);
      background: rgba(21, 21, 21, 0.6);
      color: white;
      font-size: 2rem;
      text-align: center;
      transition: all 0.2s ease;

      &:focus {
        border-color: #ffc807;
        outline: none;
        box-shadow: 0 0 0 2px rgba(255, 200, 7, 0.3);
      }

      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }
    }
  }

  &__submit {
    background: #ffc807;
    color: #000;
    border: none;
    border-radius: 30px;
    padding: 0.8rem 2rem;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-top: 1rem;
    box-shadow: 0 4px 12px rgba(255, 200, 7, 0.2);

    &:hover:not(:disabled) {
      background: #e6b400;
      transform: translateY(-2px);
      box-shadow: 0 6px 16px rgba(255, 200, 7, 0.3);
    }

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }

  &__error {
    color: #ff4d4d;
    margin-bottom: 1rem;
    font-size: 0.9rem;
  }

  &__success {
    color: #4caf50;
    margin-bottom: 1rem;
    font-size: 0.9rem;
    padding: 0.75rem;
    background: rgba(76, 175, 80, 0.1);
    border-radius: 8px;
    border: 1px solid rgba(76, 175, 80, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: "✓";
      display: inline-block;
      margin-right: 8px;
      font-weight: bold;
    }
  }

  &--success {
    h2 {
      color: #ffc807;
      margin-bottom: 1rem;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }

    p {
      color: rgba(255, 255, 255, 0.8);
    }

    .alpha-access__container {
      background: rgba(0, 0, 0, 0.7);
      padding: 2rem;
      border-radius: 12px;
      text-align: center;
      border: 1px solid rgba(255, 255, 255, 0.1);
      box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
      position: relative;
      overflow: hidden;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 4px;
        background: #ffc807;
        animation: shimmerSuccess 2s infinite;
      }

      @keyframes shimmerSuccess {
        0% {
          opacity: 0.5;
        }
        50% {
          opacity: 1;
        }
        100% {
          opacity: 0.5;
        }
      }
    }
  }
}

// Responsive styles
@media (max-width: 600px) {
  .alpha-access {
    padding: 1rem;

    &__container {
      padding: 1.5rem;
    }

    &__code-inputs {
      gap: 0.5rem;

      input {
        width: 50px;
        height: 60px;
        font-size: 1.5rem;
      }
    }
  }
}
