@import "../../_variables";

.footer {
  @include grid-row(2);
  grid-gap: 1rem;
  background: #fff;
  margin-top: -1rem;
  z-index: 0;
  min-height: 11rem;
  padding-top: 4rem;
  &-title {
    color: rgba(0, 0, 0, 0.7);
    text-align: center;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.12rem;
    text-transform: uppercase;
  }

  &-links {
    @include grid(3);
    justify-self: center;
    grid-gap: 2rem;
  }
}
