@import "../../_variables";

.stripe-checkout {
  width: 100%;
  max-width: 100%;
  margin: 0;

  .tickets-container {
    display: grid;
    gap: 1.25rem;
    margin-bottom: 2rem;
    width: 100%;

    @media (min-width: 768px) {
      grid-template-columns: 1fr;
      gap: 1.5rem;
    }

    .ticket-card {
      background: rgba(0, 0, 0, 0.15);
      border-radius: 18px;
      padding: 2rem;
      border: none;
      border-left: 8px solid #ffc807;
      transition: all 0.2s ease;
      position: relative;
      overflow: visible;
      width: 100%;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);

      @media (max-width: 480px) {
        padding: 1.5rem;
      }

      &:hover {
        transform: translateY(-2px);
        border-color: #ffc807;
      }

      .ticket-countdown {
        position: absolute;
        top: -15px;
        right: -15px;
        background: linear-gradient(
          135deg,
          rgba(255, 200, 7, 0.3),
          rgba(255, 200, 7, 0.15)
        );
        padding: 8px 16px;
        border-radius: 25px;
        font-size: 1rem;
        color: #ffc807;
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 8px;
        backdrop-filter: blur(4px);
        z-index: 10;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
        border: 1px solid rgba(255, 200, 7, 0.4);
        transform: rotate(2deg);

        svg {
          font-size: 1.2rem;
        }
      }

      .ticket-discount {
        position: absolute;
        top: -15px;
        left: -15px;
        background: linear-gradient(
          135deg,
          rgba(255, 200, 7, 0.4),
          rgba(255, 200, 7, 0.2)
        );
        padding: 8px 16px;
        border-radius: 25px;
        font-size: 1rem;
        color: #ffc807;
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 8px;
        backdrop-filter: blur(4px);
        z-index: 10;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
        border: 1px solid rgba(255, 200, 7, 0.4);
        transform: rotate(-2deg);
      }

      .ticket-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.25rem;

        @media (min-width: 768px) {
          margin-bottom: 1.5rem;
        }

        h4 {
          margin: 0;
          font-size: 1.5rem;
          color: white;
        }

        .ticket-discount {
          background: rgba(255, 200, 7, 0.15);
          color: #ffc807;
          padding: 0.25rem 0.75rem;
          border-radius: 1rem;
          font-size: 0.8rem;
          font-weight: 500;
        }
      }

      .ticket-price {
        margin-bottom: 1.25rem;

        @media (min-width: 768px) {
          margin-bottom: 1.5rem;
        }

        .current-price {
          font-size: 2rem;
          font-weight: 700;
          color: white;

          @media (min-width: 768px) {
            font-size: 2.25rem;
          }
        }

        .original-price {
          color: rgba(255, 255, 255, 0.5);
          text-decoration: line-through;
          margin-left: 0.5rem;
          font-size: 1.1rem;

          @media (min-width: 768px) {
            font-size: 1.25rem;
          }
        }
      }

      .ticket-description {
        color: rgba(255, 255, 255, 0.8);
        font-size: 1rem;
        margin-bottom: 1.25rem;

        @media (min-width: 768px) {
          font-size: 1.1rem;
          margin-bottom: 1.5rem;
        }
      }

      .ticket-availability {
        margin-bottom: 1.5rem;

        .availability-bar {
          height: 8px;
          background: rgba(255, 255, 255, 0.1);
          border-radius: 4px;
          overflow: hidden;
          margin-bottom: 0.5rem;

          .availability-fill {
            height: 100%;
            background: #ffc807;
            transition: width 0.3s ease;
          }
        }

        .availability-text {
          font-size: 0.95rem;
          color: rgba(255, 255, 255, 0.7);
        }
      }

      .ticket-quantity {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1.25rem;
        margin-top: 1.25rem;
        padding: 0.75rem;
        background: rgba(255, 255, 255, 0.05);
        border-radius: 12px;
        border: 1px solid rgba(255, 255, 255, 0.05);

        @media (min-width: 768px) {
          gap: 1.5rem;
          margin-top: 1.5rem;
          padding: 1rem;
        }

        .quantity-btn {
          width: 42px;
          height: 42px;
          border: 1px solid rgba(255, 200, 7, 0.3);
          background: rgba(255, 200, 7, 0.15);
          color: #ffc807;
          border-radius: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.75rem;
          cursor: pointer;
          transition: all 0.2s ease;

          @media (min-width: 768px) {
            width: 48px;
            height: 48px;
            font-size: 2rem;
          }

          &:hover {
            background: rgba(255, 200, 7, 0.2);
            border-color: rgba(255, 200, 7, 0.4);
          }

          &:active {
            transform: scale(0.95);
          }
        }

        .quantity {
          font-size: 1.5rem;
          font-weight: 500;
          color: white;
          min-width: 2.5rem;
          text-align: center;

          @media (min-width: 768px) {
            font-size: 1.75rem;
            min-width: 3rem;
          }
        }
      }
    }
  }

  .checkout-summary {
    width: 100%;
    background: rgba(0, 0, 0, 0.15);
    border-radius: 18px;
    padding: 2rem;
    border: none;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);

    @media (max-width: 480px) {
      padding: 1.5rem;
    }

    .selected-tickets {
      margin-bottom: 1.25rem;

      @media (min-width: 768px) {
        margin-bottom: 1.5rem;
      }

      .selected-ticket-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.85rem 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        color: rgba(255, 255, 255, 0.9);
        font-size: 1.1rem;

        @media (min-width: 768px) {
          padding: 1rem 0;
          font-size: 1.2rem;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }

    .total-amount {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.25rem 0;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      margin-top: 1rem;
      font-weight: 600;
      color: white;
      font-size: 1.3rem;

      @media (min-width: 768px) {
        padding: 1.5rem 0;
        font-size: 1.5rem;
      }
    }

    .checkout-form {
      margin-top: 1.75rem;
      display: grid;
      gap: 1.25rem;

      @media (min-width: 768px) {
        margin-top: 2rem;
        gap: 1.5rem;
      }

      .form-group {
        input {
          width: 100%;
          padding: 1rem;
          border-radius: 12px;
          border: 1px solid rgba(255, 255, 255, 0.1);
          background: rgba(0, 0, 0, 0.2);
          color: white;
          font-size: 1.1rem;

          &::placeholder {
            color: rgba(255, 255, 255, 0.5);
          }

          &:focus {
            outline: none;
            border-color: rgba(255, 200, 7, 0.5);
          }

          &.error {
            border-color: rgba(255, 87, 87, 0.7);
            background-color: rgba(255, 87, 87, 0.05);
          }
        }

        &.error {
          margin-bottom: 2rem;
          position: relative;
        }

        .error-message {
          position: absolute;
          bottom: -1.5rem;
          left: 0;
          font-size: 1rem;
          color: rgba(255, 87, 87, 1);
          margin-top: 0.25rem;
        }
      }

      .checkout-button {
        margin-top: 1rem;
        width: 100%;
        padding: 1.25rem;
        border: none;
        border-radius: 12px;
        background: #ffc807;
        color: #000;
        font-weight: 600;
        font-size: 1.2rem;
        cursor: pointer;
        transition: all 0.2s ease;

        @media (min-width: 768px) {
          padding: 1.5rem;
          font-size: 1.4rem;
        }

        &:hover {
          transform: translateY(-2px);
          box-shadow: 0 4px 12px rgba(255, 200, 7, 0.2);
        }

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
          transform: none !important;
          box-shadow: none !important;
        }

        .button-loading {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;

          .loading-spinner {
            width: 22px;
            height: 22px;
            border: 2px solid rgba(0, 0, 0, 0.1);
            border-radius: 50%;
            border-top-color: #000;
            animation: spin 1s linear infinite;
          }

          @keyframes spin {
            to {
              transform: rotate(360deg);
            }
          }
        }
      }
    }
  }
}
