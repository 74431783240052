@import "../../_variables";

.notification-panel {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 400px;
  background: #151515;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    width: 100%;
  }

  .panel-header {
    padding: 1.25rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(21, 21, 21, 0.95);
    backdrop-filter: blur(10px);
    position: sticky;
    top: 0;
    z-index: 2;

    h2 {
      margin: 0;
      font-size: 1.25rem;
      color: #fff;
      font-weight: 600;
      letter-spacing: 0.5px;
    }

    .header-actions {
      display: flex;
      gap: 0.75rem;

      button {
        background: none;
        border: none;
        color: rgba(255, 255, 255, 0.6);
        width: 36px;
        height: 36px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.25rem;
        border-radius: 10px;
        transition: all 0.2s;

        &:hover {
          color: #fff;
          background: rgba(255, 255, 255, 0.1);
        }

        &.active {
          color: $primary-color;
          background: rgba($primary-color, 0.15);
        }
      }
    }
  }

  .notifications-list {
    flex: 1;
    overflow-y: auto;
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .loading,
    .empty-state {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 200px;
      color: rgba(255, 255, 255, 0.5);
      font-size: 0.9rem;
      text-align: center;
      font-style: italic;
    }

    .notification-item {
      position: relative;
      background: rgba(21, 21, 21, 0.95);
      border-radius: 12px;
      padding: 1rem;
      display: flex;
      gap: 1rem;
      cursor: pointer;
      transition: all 0.2s;
      border: 1px solid rgba(255, 255, 255, 0.08);

      &:hover {
        background: rgba(30, 30, 30, 0.95);
        border-color: rgba(255, 255, 255, 0.12);
      }

      &.unread {
        background: rgba(21, 21, 21, 0.98);
        border: 1px solid rgba($primary-color, 0.3);

        &:hover {
          background: rgba(30, 30, 30, 0.98);
        }

        .notification-icon,
        .notification-avatar {
          background: rgba($primary-color, 0.1);
          color: $primary-color;
        }

        .message {
          color: #fff;
          text-align: left !important;
        }
      }

      .content-container {
        display: flex;
        gap: 1rem;
        width: 100%;
      }

      .notification-icon,
      .notification-avatar {
        width: 44px;
        height: 44px;
        border-radius: 12px;
        background: rgba(255, 255, 255, 0.04);
        color: rgba(255, 255, 255, 0.9);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.25rem;
        flex-shrink: 0;
        transition: all 0.2s;
        overflow: hidden;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: relative;
          z-index: 2;
        }

        .default-avatar {
          width: 100%;
          height: 100%;
          background: rgba(255, 200, 7, 0.2);
          color: #ffc807;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 600;
          font-size: 1.2rem;
          z-index: 1;
        }

        img + .default-avatar {
          display: none;
        }

        &.show-default .default-avatar {
          display: flex;
        }
      }

      .content-wrapper {
        flex: 1;
        min-width: 0;
        display: flex;
        flex-direction: column;
        gap: 0.35rem;

        .content-main {
          display: flex;
          flex-direction: column;
          gap: 0.25rem;

          .message {
            color: rgba(255, 255, 255, 0.9);
            font-size: 0.925rem;
            line-height: 1.4;
            font-weight: 400;
            margin: 0;
            text-align: left;
            .entity-name {
              font-weight: 500;
              display: inline-block;

              &.user {
                color: $primary-color;
              }

              &.brand,
              &.event {
                &.clickable {
                  color: $primary-color;
                  cursor: pointer;
                  transition: all 0.2s;

                  &:hover {
                    filter: brightness(1.2);
                    text-decoration: underline;
                  }
                }
              }
            }
          }

          .timestamp {
            font-size: 0.8rem;
            color: rgba(255, 255, 255, 0.4);
            font-weight: 400;
            display: flex;
            align-items: center;
            gap: 0.25rem;

            svg {
              font-size: 1rem;
            }
          }
        }

        .actions {
          display: flex;
          gap: 0.75rem;
          margin-top: 0.75rem;

          button {
            flex: 1;
            padding: 0.6rem 1rem;
            border: none;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
            font-size: 0.875rem;
            font-weight: 500;
            cursor: pointer;
            transition: all 0.2s;

            &.accept {
              background: $primary-color;
              color: #151515;

              &:hover {
                filter: brightness(1.1);
              }
            }

            &.reject {
              background: rgba(255, 64, 129, 0.1);
              color: #ff4081;

              &:hover {
                background: rgba(255, 64, 129, 0.15);
              }
            }

            svg {
              font-size: 1.1rem;
            }
          }
        }
      }

      .status-badge {
        display: flex;
        align-items: center;
        gap: 0.35rem;
        font-size: 0.85rem;
        padding: 0.25rem 0.5rem;
        border-radius: 6px;
        margin-top: 0.5rem;
        width: fit-content;

        &.accepted {
          background: rgba(76, 209, 55, 0.1);
          color: #4cd137;
          border: 1px solid rgba(76, 209, 55, 0.2);

          svg {
            font-size: 1rem;
          }
        }

        &.rejected {
          background: rgba(255, 82, 82, 0.1);
          color: #ff5252;
          border: 1px solid rgba(255, 82, 82, 0.2);

          svg {
            font-size: 1rem;
          }
        }

        &.pending {
          background: rgba(255, 200, 7, 0.1);
          color: #ffc807;
          border: 1px solid rgba(255, 200, 7, 0.2);

          svg {
            font-size: 1rem;
          }
        }
      }
    }
  }

  .status-badge {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;

    span {
      display: flex;
      align-items: center;
      gap: 0.25rem;
      padding: 0.25rem 0.5rem;
      border-radius: 4px;
      font-size: 0.85rem;
      font-weight: 500;

      &.accepted {
        background: rgba(46, 213, 115, 0.15);
        color: #2ed573;
        border: 1px solid rgba(46, 213, 115, 0.3);
      }

      &.rejected {
        background: rgba(255, 71, 87, 0.15);
        color: #ff4757;
        border: 1px solid rgba(255, 71, 87, 0.3);
      }

      svg {
        font-size: 1rem;
      }
    }
  }
}

.notification-avatar {
  width: 44px;
  height: 44px;
  border-radius: 12px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.04);
  color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  flex-shrink: 0;
  transition: all 0.2s;
  position: relative;

  .profile-pic {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  .default-avatar {
    width: 100%;
    height: 100%;
    background: rgba($primary-color, 0.2);
    color: $primary-color;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 1.2rem;
    z-index: 1;
  }

  img + .default-avatar {
    display: none;
  }

  &.show-default .default-avatar {
    display: flex;
  }
}

.notification-content {
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 0;
}

.notification-text {
  flex: 1;
  min-width: 0;
  margin-right: 12px;

  .username,
  .brand-name {
    font-weight: 600;
    color: var(--primary-color);
  }
}

.notification-time {
  color: var(--text-secondary);
  font-size: 0.85em;
  white-space: nowrap;
}
