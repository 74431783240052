@import "../../_variables";

.analytics-container {
  background-color: #151515;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.analytics-header {
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  h2 {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0;
    font-size: 1.25rem;
    color: #fff;

    svg {
      color: #ffc807;
    }

    .event-name {
      margin-left: 5px;
      font-size: 0.9rem;
      opacity: 0.8;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .header-actions {
    display: flex;
    gap: 8px;

    button {
      background: none;
      border: none;
      color: rgba(255, 255, 255, 0.6);
      font-size: 1.25rem;
      cursor: pointer;
      padding: 6px;
      border-radius: 50%;
      transition: all 0.2s;

      &:hover {
        background: rgba(255, 255, 255, 0.1);
        color: #fff;
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    .refresh-btn {
      svg {
        transition: transform 1s ease;
      }

      .spinning {
        animation: spin 1s linear infinite;
      }
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.analytics-content {
  padding: 1.5rem;
  flex: 1;
  overflow-y: auto;

  .loading-state,
  .error-state,
  .empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    color: rgba(255, 255, 255, 0.7);

    p {
      margin-top: 1rem;
    }
  }

  .loader {
    width: 40px;
    height: 40px;
    border: 3px solid rgba(255, 200, 7, 0.3);
    border-top-color: #ffc807;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  .stats-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1.5rem;

    .total-card {
      grid-column: 1 / -1;
      background: linear-gradient(
        45deg,
        rgba(255, 200, 7, 0.05),
        rgba(255, 200, 7, 0.15)
      );
      border: 1px solid rgba(255, 200, 7, 0.2);
    }
  }

  .stat-card {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease;

    &.clickable {
      cursor: pointer;
      position: relative;

      &:after {
        content: "⌵";
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
        color: rgba(255, 255, 255, 0.5);
        font-size: 1.2rem;
        transition: transform 0.3s ease;
      }

      &.expanded:after {
        transform: rotate(180deg);
      }

      &:hover {
        background: rgba(255, 255, 255, 0.08);
        transform: translateY(-2px);
      }

      .card-header {
        padding-right: 2rem;
      }
    }

    .card-header {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    h3 {
      margin: 0;
      font-size: 1.1rem;
      color: #fff;
      font-weight: 500;
    }

    .stat-values {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.75rem;

      .stat-total,
      .stat-checked {
        display: flex;
        flex-direction: column;
        align-items: center;

        .value {
          font-size: 1.75rem;
          font-weight: 700;
          color: white;
        }

        .label {
          font-size: 0.75rem;
          color: rgba(255, 255, 255, 0.6);
          margin-top: 0.25rem;
        }
      }

      .stat-checked .value {
        color: #ffc807;
      }
    }

    .progress-bar {
      height: 8px;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 4px;
      overflow: hidden;

      .progress-fill {
        height: 100%;
        background: linear-gradient(to right, #ffc807, #ff9800);
        border-radius: 4px;
        transition: width 0.5s ease;
      }
    }
  }
}

// Host Summaries Styles
.host-summaries {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 0.5rem;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 0 0 8px 8px;
}

.host-summary {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  .host-name {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #ffc807;
    font-size: 0.9rem;

    svg {
      font-size: 1rem;
    }
  }

  .host-stats {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;

    .stat {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .value {
        font-size: 1.1rem;
        font-weight: 500;
        color: #fff;
      }

      .label {
        font-size: 0.7rem;
        color: rgba(255, 255, 255, 0.6);
        margin-top: 0.2rem;
      }
    }
  }

  .progress-bar {
    height: 4px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 2px;
    overflow: hidden;

    .progress-fill {
      height: 100%;
      background: #ffc807;
      border-radius: 2px;
      transition: width 0.3s ease;
    }
  }
}

.empty-host-summary {
  padding: 1rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.9rem;

  p {
    margin: 0;
  }
}

@media (max-width: 768px) {
  .analytics-container {
    border-radius: 0;
    height: 100vh;
    max-width: 100%;
  }

  .analytics-content {
    padding: 1rem;

    .stats-grid {
      grid-template-columns: 1fr;
    }
  }
}

.tickets-card {
  .stat-revenue {
    .value {
      color: #4caf50;
      font-weight: 600;
    }
  }
}

.ticket-categories {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 1rem;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: hidden;
}

.ticket-category {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  position: relative;
  overflow: hidden;

  .category-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
      margin: 0;
      font-size: 0.9rem;
      font-weight: 500;
      color: #fff;
    }

    .price {
      font-size: 0.9rem;
      color: #4caf50;
      font-weight: 600;
    }
  }

  .category-stats {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
    gap: 0.5rem;

    .stat {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .value {
        font-size: 1.1rem;
        font-weight: 500;
        color: #fff;
      }

      .label {
        font-size: 0.7rem;
        color: rgba(255, 255, 255, 0.6);
        margin-top: 0.2rem;
      }
    }
  }

  .progress-bar {
    height: 4px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 2px;
    overflow: hidden;

    .progress-fill {
      height: 100%;
      border-radius: 2px;
      transition: width 0.3s ease;
      opacity: 0.8;
    }
  }
}
