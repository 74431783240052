.after-payment {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #1a1a1a 0%, #2d2d2d 100%);
  padding: 1rem;

  .payment-status-card {
    background: white;
    border-radius: 1rem;
    padding: 2rem;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 500px;
    text-align: center;

    .status-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.5rem;

      svg {
        font-size: 4rem;
      }

      &.loading svg {
        color: #666;
      }

      &.success svg {
        color: #4caf50;
      }

      &.error svg {
        color: #f44336;
      }

      h2 {
        margin: 0;
        font-size: 1.5rem;
        font-weight: 600;
        color: #333;
      }

      p {
        margin: 0;
        color: #666;
        font-size: 1rem;
        line-height: 1.5;
      }

      .email-notification {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #f8f9fa;
        border-left: 4px solid #ffc807;
        padding: 1rem;
        border-radius: 0.5rem;
        width: 100%;
        margin-bottom: 0.5rem;

        svg {
          font-size: 2rem;
          color: #ffc807;
          margin-bottom: 0.5rem;
        }

        p {
          font-weight: 500;
          color: #333;
          font-size: 0.95rem;
        }
      }

      .order-details {
        background: #f5f5f5;
        padding: 1rem;
        border-radius: 0.5rem;
        width: 100%;

        p {
          margin: 0.5rem 0;
          font-size: 0.9rem;
          color: #333;
          font-weight: 500;
        }
      }

      .continue-button {
        background: linear-gradient(314deg, #d1a300 0%, #ffc807 100%);
        color: black;
        border: none;
        padding: 0.75rem 2rem;
        border-radius: 0.5rem;
        font-weight: 600;
        cursor: pointer;
        transition: transform 0.2s ease;
        margin-top: 1rem;

        &:hover {
          transform: translateY(-2px);
        }
      }
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinning {
  animation: spin 1s linear infinite;
}
