.brand-settings-container {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  background: #151515;
  color: #fff;
  z-index: 1000;
  transform: translateZ(1px);
  backface-visibility: hidden;
  isolation: isolate;
  pointer-events: auto !important;

  .brand-settings-card {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #151515;
    border-radius: 1rem;
    overflow: hidden;
    z-index: 1001;
  }

  .settings-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.25rem;
    background: rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    h2 {
      margin: 0;
      font-size: 1.25rem;
      font-weight: 600;
    }

    .close-btn {
      width: 36px;
      height: 36px;
      background: rgba(255, 255, 255, 0.1);
      border: none;
      color: rgba(255, 255, 255, 0.8);
      font-size: 1.25rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: all 0.2s;

      &:hover {
        background: rgba(255, 255, 255, 0.15);
        color: #fff;
      }
    }
  }

  .settings-content {
    flex: 1;
    overflow-y: auto;
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.1);
      border-radius: 3px;

      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }
    }
  }

  .settings-section {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 1rem;
    padding: 1.25rem;

    h3 {
      margin: 0 0 1rem 0;
      font-size: 1rem;
      font-weight: 600;
      color: rgba(255, 255, 255, 0.9);
    }

    .settings-btn {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 0.75rem;
      padding: 1rem;
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 0.75rem;
      color: #fff;
      font-size: 0.9rem;
      cursor: pointer;
      transition: all 0.2s;

      svg {
        font-size: 1.25rem;
        color: #ffc807;
      }

      &:hover {
        background: rgba(255, 255, 255, 0.15);
        border-color: rgba(255, 200, 7, 0.3);
      }
    }

    .setting-item {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin-bottom: 1rem;
      color: rgba(255, 255, 255, 0.9);

      &:last-child {
        margin-bottom: 0;
      }

      &.role-setting {
        margin-top: 1.5rem;

        h4 {
          font-size: 0.9rem;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.7);
          margin: 0 0 0.5rem 0;
        }
      }

      select {
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 0.75rem;
        color: #fff;
        padding: 0.75rem 1rem;
        font-size: 0.9rem;
        cursor: pointer;
        appearance: none;
        -webkit-appearance: none;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: right 0.75rem center;
        background-size: 1rem;
        padding-right: 2.5rem;
        transition: all 0.2s;

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }

        &:not(:disabled) {
          &:hover {
            background-color: rgba(255, 255, 255, 0.15);
            border-color: rgba(255, 200, 7, 0.3);
          }

          &:focus {
            outline: none;
            border-color: #ffc807;
            background-color: rgba(255, 255, 255, 0.15);
          }
        }

        option {
          background: #151515;
          color: #fff;
          padding: 0.75rem;

          &:hover {
            background: rgba(255, 200, 7, 0.15);
          }

          &:checked {
            background: rgba(255, 200, 7, 0.15);
            color: #ffc807;
          }
        }
      }
    }
  }

  .settings-section.settings-join {
    .setting-item {
      .settings-btn {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 0.75rem;
        padding: 1rem;
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 0.75rem;
        color: #fff;
        font-size: 0.9rem;
        cursor: pointer;
        transition: all 0.2s;

        &.active {
          background: rgba(255, 200, 7, 0.15);
          border-color: rgba(255, 200, 7, 0.3);
          color: #ffc807;

          svg {
            color: #ffc807;
          }
        }

        svg {
          font-size: 1.25rem;
          color: rgba(255, 255, 255, 0.6);
        }

        &:hover {
          background: rgba(255, 255, 255, 0.15);
          border-color: rgba(255, 200, 7, 0.3);
        }
      }
    }
  }

  .danger-zone {
    border: 1px solid rgba(255, 68, 68, 0.2);
    background: rgba(255, 68, 68, 0.05);

    .delete-btn {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 0.75rem;
      padding: 1rem;
      background: rgba(255, 68, 68, 0.1);
      border: 1px solid rgba(255, 68, 68, 0.2);
      border-radius: 0.75rem;
      color: #ff4444;
      font-size: 0.9rem;
      cursor: pointer;
      transition: all 0.2s;

      svg {
        font-size: 1.25rem;
      }

      &:hover {
        background: rgba(255, 68, 68, 0.15);
        border-color: rgba(255, 68, 68, 0.3);
      }
    }
  }
}

.full-screen-panel {
  position: fixed;
  inset: 0;
  background: #151515;
  z-index: 1100;
  overflow-y: auto;
  width: 100%;
  max-width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  isolation: isolate;
  transform: translateZ(2px);
  backface-visibility: hidden;
  pointer-events: auto !important;
}

.delete-confirmation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}

.delete-confirmation {
  background: #151515;
  border-radius: 12px;
  padding: 1.5rem;
  width: 90%;
  max-width: 400px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.4);

  h3 {
    color: #fff;
    margin: 0 0 1rem;
    font-size: 1.25rem;
    font-weight: 600;
  }

  p {
    color: rgba(255, 255, 255, 0.8);
    margin: 0 0 1.5rem;
    font-size: 0.95rem;
    line-height: 1.5;
  }

  .confirmation-actions {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;

    button {
      padding: 0.75rem 1.5rem;
      border-radius: 8px;
      border: none;
      font-size: 0.9rem;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.2s ease;

      &.cancel-btn {
        background: rgba(255, 255, 255, 0.1);
        color: #fff;

        &:hover {
          background: rgba(255, 255, 255, 0.15);
        }
      }

      &.confirm-delete-btn {
        background: rgba(255, 68, 68, 0.1);
        color: #ff4444;
        border: 1px solid rgba(255, 68, 68, 0.2);

        &:hover {
          background: rgba(255, 68, 68, 0.2);
          border-color: rgba(255, 68, 68, 0.3);
        }
      }
    }
  }
}
