.event-form-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;

  @media (max-width: 768px) {
    padding: 1rem;
  }
}

.event-form {
  width: 100%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  background: #151515;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  color: #fff;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.4);

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 200, 7, 0.3);
    border-radius: 4px;

    &:hover {
      background: rgba(255, 200, 7, 0.5);
    }
  }

  .close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    color: rgba(255, 255, 255, 0.6);
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    z-index: 10;

    &:hover {
      color: #fff;
      transform: scale(1.1);
    }
  }

  h2 {
    text-align: center;
    padding: 2rem;
    margin: 0;
    font-size: 1.75rem;
    font-weight: 700;
    background: linear-gradient(314deg, #d1a300 0%, #ffc807 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  form {
    padding: 1.25rem;

    .form-section {
      margin-bottom: 1.5rem;

      h3 {
        font-size: 1rem;
        color: rgba(255, 255, 255, 0.9);
        margin-bottom: 1rem;
        font-weight: 600;

        &:after {
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          background: rgba(255, 255, 255, 0.1);
          margin-top: 0.5rem;
        }
      }

      .flyer-options {
        display: flex;
        gap: 1rem;
        justify-content: center;
        margin: 1rem 0;

        .flyer-option {
          position: relative;
          width: 120px;
          height: 120px;
          background: rgba(255, 255, 255, 0.05);
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 8px;
          cursor: pointer;
          transition: all 0.2s ease;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 1rem;

          &.portrait .ratio-preview {
            width: 45px;
            height: 80px;
          }

          &.square .ratio-preview {
            width: 60px;
            height: 60px;
          }

          &.landscape .ratio-preview {
            width: 80px;
            height: 45px;
          }

          .ratio-preview {
            background: rgba(255, 255, 255, 0.15);
            border-radius: 4px;
            transition: all 0.2s ease;
            width: 100%;
            height: 100%;
            overflow: hidden;
            position: relative;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .ratio-text {
            position: absolute;
            bottom: 0.75rem;
            font-size: 0.8rem;
            color: rgba(255, 255, 255, 0.6);
          }

          .check-icon {
            position: absolute;
            top: 0.5rem;
            right: 0.5rem;
            color: #ffc807;
            opacity: 0;
            transform: scale(0.8);
            transition: all 0.2s ease;
          }

          .upload-progress {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 4px;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 0 0 4px 4px;
            overflow: hidden;

            .progress-bar {
              height: 100%;
              background: #ffc807;
              transition: width 0.3s ease;
            }
          }

          &.selected {
            border-color: #ffc807;
            background: rgba(255, 200, 7, 0.1);

            .ratio-preview {
              background: rgba(255, 200, 7, 0.3);
            }

            .check-icon {
              opacity: 1;
              transform: scale(1);
            }

            .ratio-text {
              color: #ffc807;
            }
          }

          &:hover {
            background: rgba(255, 255, 255, 0.08);
            border-color: rgba(255, 200, 7, 0.3);

            .ratio-preview {
              background: rgba(255, 200, 7, 0.2);
            }

            .ratio-text {
              color: rgba(255, 255, 255, 0.8);
            }
          }
        }
      }

      &.danger-zone {
        border: 1px solid rgba(255, 68, 68, 0.2);
        background: rgba(255, 68, 68, 0.05);
        border-radius: 12px;
        padding: 1rem;

        .settings-items {
          margin-top: 0.5rem;
        }

        .settings-item {
          display: flex;
          align-items: center;
          gap: 1rem;
          padding: 1rem;
          border-radius: 12px;
          background: rgba(0, 0, 0, 0.2);

          &.danger {
            border: 1px solid rgba(244, 67, 54, 0.2);
          }

          .item-icon {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(244, 67, 54, 0.15);
            color: #f44336;
            font-size: 1.25rem;
          }

          .item-content {
            flex: 1;

            h4 {
              margin: 0 0 0.25rem;
              font-size: 1rem;
              color: #fff;
            }

            p {
              margin: 0;
              font-size: 0.9rem;
              color: rgba(255, 255, 255, 0.7);
            }
          }

          .item-actions {
            .delete-button {
              width: 36px;
              height: 36px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: rgba(244, 67, 54, 0.15);
              color: #f44336;
              border: 1px solid rgba(244, 67, 54, 0.3);
              font-size: 1.25rem;
              cursor: pointer;
              transition: all 0.2s;

              &:hover {
                background-color: rgba(244, 67, 54, 0.25);
                transform: scale(1.1);
              }

              &:active {
                transform: scale(0.9);
              }
            }
          }
        }

        .delete-btn {
          width: 100%;
          display: flex;
          align-items: center;
          gap: 0.75rem;
          padding: 1rem;
          background: rgba(255, 68, 68, 0.1);
          border: 1px solid rgba(255, 68, 68, 0.2);
          border-radius: 0.75rem;
          color: #ff4444;
          font-size: 0.9rem;
          cursor: pointer;
          transition: all 0.2s;

          svg {
            font-size: 1.25rem;
          }

          &:hover {
            background: rgba(255, 68, 68, 0.15);
            border-color: rgba(255, 68, 68, 0.3);
          }
        }
      }

      .date-picker-container {
        position: relative;
        width: 100%;

        .date-picker {
          width: 100%;
          padding: 0.75rem 1rem 0.75rem 2.5rem;
          background: rgba(0, 0, 0, 0.2);
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 8px;
          color: #fff;
          font-size: 0.9rem;
          transition: all 0.2s ease;

          &:focus {
            border-color: rgba(255, 200, 7, 0.5);
            box-shadow: 0 0 0 2px rgba(255, 200, 7, 0.2);
            outline: none;
          }

          &:hover {
            border-color: rgba(255, 255, 255, 0.2);
          }
        }

        .date-icon {
          position: absolute;
          left: 0.75rem;
          top: 50%;
          transform: translateY(-50%);
          color: rgba(255, 200, 7, 0.8);
          font-size: 1.2rem;
        }
      }

      .form-group.required {
        label {
          &:after {
            content: " *";
            color: rgba(255, 200, 7, 0.8);
          }
        }
      }
    }

    .form-group {
      margin-bottom: 1.25rem;
      position: relative;

      &.required {
        label::after {
          content: "*";
          color: #ffc807;
          margin-left: 4px;
        }
      }

      label {
        display: block;
        margin-bottom: 0.5rem;
        font-size: 0.9rem;
        color: rgba(255, 255, 255, 0.8);
      }

      &.date-time-container {
        display: flex;
        gap: 1rem;
        width: 100%;
      }
    }

    .date-time-container {
      display: flex;
      gap: 1.5rem;
      width: 100%;
      margin-bottom: 0.5rem;

      .date-time-column {
        flex: 1;
        min-width: 0;
      }

      @media (max-width: 480px) {
        flex-direction: column;
        gap: 0;
      }
    }

    .input-with-icon {
      position: relative;

      svg {
        position: absolute;
        left: 1rem;
        top: 50%;
        transform: translateY(-50%);
        color: rgba(255, 255, 255, 0.4);
        font-size: 1.1rem;
        pointer-events: none;
      }

      input {
        padding-left: 2.75rem !important;
        width: 100%;
      }
    }

    input[type="text"],
    input[type="time"],
    textarea {
      width: 100%;
      padding: 0.75rem 1rem;
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.05);
      color: #fff;
      font-size: 0.9rem;
      transition: all 0.2s ease;

      &:focus {
        outline: none;
        border-color: #ffc807;
        background: rgba(255, 255, 255, 0.08);
      }

      &::placeholder {
        color: rgba(255, 255, 255, 0.3);
      }
    }

    textarea {
      resize: vertical;
      min-height: 80px;
    }

    .field-note {
      font-size: 12px;
      color: #666;
      margin-top: 4px;
      font-style: italic;
    }

    input:disabled,
    select:disabled,
    textarea:disabled,
    .date-picker:disabled {
      background-color: #f5f5f5;
      cursor: not-allowed;
      opacity: 0.7;
    }

    .time-inputs {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
      margin-bottom: 1rem;

      .form-group {
        margin-bottom: 0;

        .input-with-icon input[type="time"] {
          padding-left: 2.75rem;
          color-scheme: dark;
        }
      }
    }

    .flyer-upload-section {
      margin-bottom: 1.5rem;

      .flyer-options {
        display: flex;
        gap: 0.75rem;
        padding: 0.5rem;
        justify-content: space-between;

        .flyer-option {
          position: relative;
          width: calc(33.33% - 0.5rem);
          height: 60px;
          background: rgba(255, 255, 255, 0.05);
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 8px;
          cursor: pointer;
          transition: all 0.2s ease;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .ratio-text {
            color: rgba(255, 255, 255, 0.4);
            font-size: 0.75rem;
            font-weight: 500;
            position: absolute;
            bottom: 0.5rem;
          }

          .check-icon {
            position: absolute;
            top: 0.5rem;
            right: 0.5rem;
            color: #ffc807;
            opacity: 0;
            transform: scale(0.8);
            transition: all 0.2s ease;
            font-size: 0.8rem;
          }

          &.portrait {
            .ratio-preview {
              width: 16px;
              height: 28px;
              background: rgba(255, 255, 255, 0.15);
              border-radius: 2px;
              margin-bottom: 0.5rem;
            }
          }

          &.square {
            .ratio-preview {
              width: 24px;
              height: 24px;
              background: rgba(255, 255, 255, 0.15);
              border-radius: 2px;
              margin-bottom: 0.5rem;
            }
          }

          &.landscape {
            .ratio-preview {
              width: 28px;
              height: 16px;
              background: rgba(255, 255, 255, 0.15);
              border-radius: 2px;
              margin-bottom: 0.5rem;
            }
          }

          &.selected {
            border-color: #ffc807;
            background: rgba(255, 200, 7, 0.1);

            .ratio-preview {
              background: rgba(255, 200, 7, 0.3);
            }

            .check-icon {
              opacity: 1;
              transform: scale(1);
            }

            .ratio-text {
              color: #ffc807;
            }
          }

          &:hover {
            background: rgba(255, 255, 255, 0.08);
            border-color: rgba(255, 200, 7, 0.3);

            .ratio-preview {
              background: rgba(255, 200, 7, 0.2);
            }

            .ratio-text {
              color: rgba(255, 255, 255, 0.6);
            }
          }
        }
      }
    }

    .form-actions {
      display: flex;
      gap: 1rem;
      margin-top: 1.5rem;
      padding-top: 1rem;
      border-top: 1px solid rgba(255, 255, 255, 0.1);

      button {
        flex: 1;
        padding: 0.75rem;
        border-radius: 8px;
        font-size: 0.9rem;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.2s ease;

        &.cancel-button {
          background: rgba(255, 255, 255, 0.05);
          border: 1px solid rgba(255, 255, 255, 0.1);
          color: rgba(255, 255, 255, 0.7);

          &:hover {
            background: rgba(255, 255, 255, 0.08);
            color: #fff;
          }
        }

        &.submit-button {
          background: #ffc807;
          border: none;
          color: #000;

          &:hover {
            filter: brightness(1.1);
          }

          &:disabled {
            opacity: 0.7;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}

.date-picker-container {
  position: relative;
  width: 100%;
  margin-bottom: 1rem;

  .date-picker {
    width: 100%;
    padding: 0.75rem 1rem 0.75rem 2.5rem;
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    color: #fff;
    font-size: 0.9rem;
    transition: all 0.2s ease;
    cursor: pointer;

    &:focus {
      border-color: rgba(255, 200, 7, 0.5);
      box-shadow: 0 0 0 2px rgba(255, 200, 7, 0.2);
      outline: none;
    }

    &:hover {
      border-color: rgba(255, 255, 255, 0.2);
    }
  }

  .date-icon {
    position: absolute;
    left: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    color: rgba(255, 200, 7, 0.8);
    font-size: 1.2rem;
    pointer-events: none;
  }
}

// React DatePicker custom styling
.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker {
  font-family: inherit;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  background-color: #1a1a1a;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  color: #fff;

  &__header {
    background-color: #222;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding-top: 10px;
  }

  &__month {
    margin: 0.5rem;
  }

  &__day-name,
  &__day,
  &__time-name {
    color: #fff;
    margin: 0.2rem;
  }

  &__day--selected,
  &__day--keyboard-selected,
  &__time-list-item--selected {
    background-color: #ffc807 !important;
    color: #000 !important;
    font-weight: bold;

    &:hover {
      background-color: darken(#ffc807, 10%) !important;
    }
  }

  &__day:hover,
  &__time-list-item:hover {
    background-color: rgba(255, 200, 7, 0.2);
    color: #fff;
  }

  &__day--disabled,
  &__time-list-item--disabled {
    color: #666;

    &:hover {
      background-color: transparent;
      color: #666;
    }
  }

  &__navigation {
    top: 10px;

    &-icon {
      &::before {
        border-color: #fff;
      }
    }

    &:hover *::before {
      border-color: #ffc807;
    }
  }

  &__triangle {
    border-bottom-color: #222 !important;

    &::before {
      border-bottom-color: rgba(255, 255, 255, 0.1) !important;
    }
  }

  &__current-month,
  &__day-name {
    color: #fff;
  }

  &__time-container {
    border-left-color: rgba(255, 255, 255, 0.1);

    .react-datepicker__time {
      background-color: #1a1a1a;

      .react-datepicker__time-box {
        width: 85px;

        ul.react-datepicker__time-list {
          height: 180px;
          padding-right: 0;

          &::-webkit-scrollbar {
            width: 6px;
          }

          &::-webkit-scrollbar-track {
            background: rgba(255, 255, 255, 0.05);
          }

          &::-webkit-scrollbar-thumb {
            background: rgba(255, 200, 7, 0.3);
            border-radius: 3px;

            &:hover {
              background: rgba(255, 200, 7, 0.5);
            }
          }

          li.react-datepicker__time-list-item {
            padding: 5px 10px;
            height: auto;
            line-height: 1.4;

            &--selected {
              background-color: #ffc807;
              color: #000;
            }
          }
        }
      }
    }
  }

  &__year-dropdown,
  &__month-dropdown {
    background-color: #1a1a1a;
    border: 1px solid rgba(255, 255, 255, 0.1);

    &-container {
      .react-datepicker__year-option,
      .react-datepicker__month-option {
        &:hover {
          background-color: rgba(255, 200, 7, 0.2);
        }

        &--selected {
          background-color: #ffc807;
          color: #000;

          .react-datepicker__year-option--selected_year,
          .react-datepicker__month-option--selected_month {
            color: #000;
          }
        }
      }
    }
  }
}

// Mobile adjustments for date picker
@media (max-width: 768px) {
  .react-datepicker {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 2rem);
    max-width: 320px;
    margin: 0;
    z-index: 1000;

    .react-datepicker__month-container {
      float: none;
      width: 100%;
    }

    .react-datepicker__day {
      width: 2.5rem;
      height: 2.5rem;
      line-height: 2.5rem;
    }
  }

  .event-form-overlay {
    padding: 1rem;
  }

  .event-form {
    max-height: calc(100vh - 2rem);
    border-radius: 16px;
  }
}

.weekly-event-toggle {
  margin: 1rem 0;
  padding: 0.5rem 0;

  .toggle-container {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }

  .toggle-switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 24px;
    cursor: pointer;

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + .toggle-slider {
        background-color: #ffc807;
      }

      &:checked + .toggle-slider:before {
        transform: translateX(24px);
      }
    }

    .toggle-slider {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #2c2c2c;
      transition: 0.4s;
      border-radius: 24px;

      &:before {
        position: absolute;
        content: "";
        height: 18px;
        width: 18px;
        left: 3px;
        bottom: 3px;
        background-color: white;
        transition: 0.4s;
        border-radius: 50%;
      }
    }
  }

  .toggle-label {
    font-size: 0.95rem;
    font-weight: 500;
    color: #fff;
  }

  .toggle-hint {
    margin-top: 0.5rem;
    margin-left: calc(48px + 0.75rem);
    font-size: 0.85rem;
    color: rgba(255, 255, 255, 0.6);
    font-style: italic;
  }
}

.child-event-warning {
  background-color: #ffd700;
  color: #000;
  padding: 12px 15px;
  border-radius: 6px;
  margin-bottom: 20px;
  font-weight: 500;

  p {
    margin: 0;
  }
}

.lineup-button-container {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}

.lineup-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  background: rgba(255, 200, 7, 0.1);
  border: 1px solid rgba(255, 200, 7, 0.3);
  color: #ffc807;
  padding: 0.75rem 1.5rem;
  border-radius: 12px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 100%;
  max-width: 200px;

  &:hover {
    background: rgba(255, 200, 7, 0.15);
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  svg {
    font-size: 1.25rem;
  }
}

.selected-lineups {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;

  .selected-lineup-item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 8px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 6px;
    transition: all 0.2s ease;

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }

    .lineup-avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      background: #333;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .avatar-placeholder {
        width: 100%;
        height: 100%;
        background: linear-gradient(45deg, #333, #444);
      }
    }

    .lineup-info {
      display: flex;
      flex-direction: column;
      flex: 1;

      .lineup-category {
        font-size: 0.75rem;
        color: rgba(255, 255, 255, 0.6);
        text-transform: uppercase;
      }

      .lineup-name {
        font-size: 0.9rem;
        font-weight: 500;
        color: #fff;
      }
    }
  }
}

.delete-confirmation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}

.delete-confirmation {
  background: #151515;
  border-radius: 12px;
  padding: 1.5rem;
  width: 90%;
  max-width: 400px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.4);

  h3 {
    color: #fff;
    margin: 0 0 1rem;
    font-size: 1.25rem;
    font-weight: 600;
  }

  p {
    color: rgba(255, 255, 255, 0.8);
    margin: 0 0 1.5rem;
    font-size: 0.95rem;
    line-height: 1.5;
  }

  .confirmation-actions {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;

    button {
      padding: 0.75rem 1.5rem;
      border-radius: 8px;
      border: none;
      font-size: 0.9rem;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.2s ease;

      &.cancel-btn {
        background: rgba(255, 255, 255, 0.1);
        color: #fff;

        &:hover {
          background: rgba(255, 255, 255, 0.15);
        }
      }

      &.confirm-delete-btn {
        background: rgba(255, 68, 68, 0.1);
        color: #ff4444;
        border: 1px solid rgba(255, 68, 68, 0.2);

        &:hover {
          background: rgba(255, 68, 68, 0.2);
          border-color: rgba(255, 68, 68, 0.3);
        }
      }
    }
  }
}

.location-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-top: 0.5rem;
}
