@import "../../_variables";

$background-color: #1a1a1a;
$text-color: #ffffff;
$vip-color: #ffd700; // Gold for VIP tables
$backstage-color: #80221c; // Rich, deeper red for backstage
$regular-table-color: #4a90e2; // Keep the blue for regular tables
$border-color: #333333;
$accent-color: #8a2be2; // Deep purple for accents
$premium-green: #2e8b57; // Premium emerald green for A tables
$premium-green-border: #3cb371; // Lighter green for border contrast

$dj-area-color: #ffd700; // Premium gold color
$dj-area-glow: rgba(255, 215, 0, 0.3);

$anthracite-bg: #2d2d2d;
$anthracite-border: #626262;
$anthracite-hover: #252525;

.table-layout {
  display: grid;
  grid-gap: 0.1rem;
  margin-top: 2rem;
  justify-self: center;
  position: relative;
  width: 360px;
  justify-self: center;

  background: rgba(26, 26, 26, 0.95);
  border-radius: 12px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.25rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06), inset 0 1px 2px rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  position: relative;

  // Subtle gradient overlay
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      45deg,
      rgba(255, 215, 0, 0.03),
      // Very subtle gold
      rgba(0, 0, 0, 0) 70%
    );
    border-radius: inherit;
    pointer-events: none;
  }

  // Optional hover effect
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px -2px rgba(0, 0, 0, 0.15),
      0 3px 6px -2px rgba(0, 0, 0, 0.1),
      inset 0 1px 2px rgba(255, 255, 255, 0.15);
  }

  // Optional modern edge glow
  &::after {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-radius: inherit;
    background: linear-gradient(
      45deg,
      rgba(255, 215, 0, 0.1),
      // Subtle gold
      transparent 30%,
      transparent 70%,
      rgba(255, 215, 0, 0.1)
    );
    pointer-events: none;
    z-index: -1;
  }

  // Floating guide message
  .table-guide {
    position: absolute;
    top: -2.5rem;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(255, 255, 255, 0.1);
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(5px);
    animation: floatGuide 2s ease-in-out infinite;
    white-space: nowrap;
    pointer-events: none;

    &::after {
      content: "";
      position: absolute;
      bottom: -8px;
      left: 50%;
      transform: translateX(-50%);
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid rgba(255, 255, 255, 0.1);
    }
  }

  &-title {
    position: absolute;
    top: 3.85rem;
    left: 50%; /* Position the left edge of the element at the center of the container */
    transform: translateX(-50%);
    font-size: 2.5rem !important;
    color: darkgrey;
    opacity: 0.25;
    z-index: -1 !important;
  }

  &-01 {
    display: grid;

    grid-template-columns: repeat(6, 1fr);
    @include grid-row(2);
    justify-self: end;

    .b1 {
      grid-column: 3/4;
    }
    .b2 {
      grid-column: 4/5;
    }

    .e1 {
      grid-row: 1/2;
      grid-column: 3/4;
      justify-self: center;
    }
    .e2 {
      grid-column: 4/6;
      grid-row: 1/2;
      justify-self: center;
    }
    .p1 {
      grid-row: 2/3;
      grid-column: 3/4;
      justify-self: center;
    }
    .p2 {
      grid-column: 4/6;
      grid-row: 2/3;
      justify-self: center;
    }

    .p3 {
      grid-row: 2/4;
      grid-column: 6/8;
      justify-self: center;
      z-index: 1;
    }

    .bar-area-backstage {
      display: grid;
      border-right: 1px solid rgb(212, 184, 29);
      border-bottom: 1px solid rgb(212, 184, 29);
      border-top: 1px solid rgb(212, 184, 29);
      border-left: 1px solid rgb(212, 184, 29);
      border-radius: 1.5rem;
      grid-row: 1/3;
      grid-column: 1/2;
      z-index: 0;
      position: absolute;
      top: 2rem;
      height: 6rem;
      width: 3rem;
      p {
        align-self: center;
        padding-bottom: 1rem;
        padding-top: 1rem;
      }
    }
  }

  &-02 {
    display: grid;

    grid-template-columns: repeat(6, 1fr);
    @include grid-row(2);
    justify-self: end;
    // border-bottom: 1px solid rgb(212, 184, 29);
    // margin-bottom: 0.5rem;
    // padding-bottom: 0.75rem;
    position: relative;
    .dj-area {
      display: grid;
      border-left: 1px solid rgb(212, 184, 29);
      border-right: 1px solid rgb(212, 184, 29);
      border-bottom: 1px solid rgb(212, 184, 29);
      border-radius: 1.5rem;
      grid-row: 2/5;
      grid-column: 3/5;
      z-index: 0;
      position: absolute;
      justify-self: center;
      height: 3.5rem;
      width: 6rem;
      top: 1.5rem;

      &.premium-zone {
        border: 2px solid $dj-area-color !important;
        background: rgba(255, 215, 0, 0.1) !important;
        box-shadow: 0 0 15px $dj-area-glow;

        p {
          color: $dj-area-color !important;
          font-weight: bold;
          text-shadow: 0 0 5px rgba(255, 215, 0, 0.5);
        }
      }
    }

    .a9 {
      grid-row: 2/3;
      grid-column: 1/2;
      justify-self: center;
    }

    .a10 {
      grid-row: 1/2;
      grid-column: 1/2;
      justify-self: center;
    }
    .p4 {
      grid-row: 1/2;
      grid-column: 6/8;
      justify-self: center;
    }

    .p5 {
      grid-row: 2/3;
      grid-column: 6/8;
      justify-self: center;
    }

    .p6 {
      grid-row: 3/4;
      grid-column: 6/8;
      justify-self: center;
    }

    .b1 {
      grid-column: 3/4;
      grid-row: 1/2;
      z-index: 2;
      justify-self: center;
    }
    .b2 {
      grid-column: 4/5;
      grid-row: 1/2;
      z-index: 1;
      justify-self: center;
    }
    .b3 {
      grid-row: 1/2;
      grid-column: 5/6;
      justify-self: end;
    }

    .b4 {
      grid-row: 2/3;
      grid-column: 5/7;
    }

    .b5 {
      grid-row: 3/4;
      grid-column: 5/7;
    }
  }

  &-03 {
    display: grid;

    grid-template-columns: repeat(6, 1fr);
    @include grid-row(2);
    justify-self: end;
    width: 100%;
    margin-top: 8px;

    .k2 {
      grid-row: 2/3;
      grid-column: 1/2;
      justify-self: center;
    }
    .k4 {
      grid-row: 2/3;
      grid-column: 1/2;
      justify-self: center;
    }

    .a5 {
      grid-row: 2/3;
      grid-column: 1/2;
      justify-self: center;
    }

    .a8,
    .a6 {
      grid-row: 1/2;
      grid-column: 1/2;
      justify-self: center;
    }

    .d1,
    .d2,
    .d3 {
      grid-column: 3/5;
      grid-row: 1/3;
    }

    .d1 {
    }

    .d2 {
      justify-self: center;
      align-self: center;
    }

    .d3 {
      justify-self: end;
    }

    .f1 {
      grid-row: 1/2;
      grid-column: 6/8;
      justify-self: center;
    }

    .f2 {
      grid-row: 2/3;
      grid-column: 6/8;
      justify-self: center;
    }

    .f4 {
      grid-row: 2/3;
      grid-column: 6/8;
      justify-self: center;
    }

    .f3 {
      grid-row: 1/2;
      grid-column: 6/8;
      justify-self: center;
    }

    .k9 {
      grid-row: 1/2;
      grid-column: 5/6;
    }

    .a1 {
      grid-row: 1/2;
      grid-column: 5/6;
      margin-top: -0.5rem;
    }

    .a2 {
      grid-row: 2/3;
      grid-column: 5/6;
      margin-top: -0.5rem;
    }

    .a3 {
      grid-row: 1/2;
      grid-column: 5/6;
      margin-top: -0.5rem;
    }
  }

  &-04 {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    @include grid-row(2);
    justify-self: end;
    width: 100%;

    .k1 {
      grid-row: 2/3;
      grid-column: 6/8;
      justify-self: center;
    }

    .a4 {
      grid-row: 1/2;
      grid-column: 1/2;
      justify-self: center;
    }
    .a3 {
      grid-row: 2/3;
      grid-column: 1/2;
      justify-self: center;
    }
    .a2 {
      grid-row: 2/3;
      grid-column: 1/3;
      align-self: end;
      justify-self: center;
      margin-left: 0.6rem;
    }
    .a1 {
      grid-row: 2/3;
      grid-column: 2/4;
      align-self: end;
      justify-self: center;
    }

    .bar-area {
      display: grid;
      border-left: 1px solid rgb(212, 184, 29);
      border-right: 1px solid rgb(212, 184, 29);
      border-top: 1px solid rgb(212, 184, 29);
      border-radius: 1.5rem;
      grid-row: 3/4;
      grid-column: 1/3;
      z-index: 0;
      margin-top: 4rem;
      align-self: start;
      height: 3.5rem;
      width: 6rem;
      margin-left: 2rem;
      p {
        align-self: center;
        justify-self: center;
      }
    }

    .sound-area {
      display: grid;
      border-left: 1px solid rgb(212, 184, 29);
      border-right: 1px solid rgb(212, 184, 29);
      border-top: 1px solid rgb(212, 184, 29);
      border-radius: 1.5rem;
      grid-row: 3/4;
      grid-column: 6/8;
      z-index: 0;
      // margin-top: 4rem;
      max-width: 5rem;
      height: 3.5rem;
      align-self: end;

      p {
        align-self: center;

        justify-self: center;
      }
    }
  }
}

.bar {
  width: 60%;
  text-align: center;
  padding: 8px;
  background-color: #333;
  color: #fff;
}

.tables {
  gap: 8px;
  grid-template-columns: repeat(7, 45px);
  display: grid;
}

.table {
  width: 50px;
  height: 30px;
  border: 2px solid #000;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.selected {
  animation: pulse 1.5s infinite;
}

.table-round {
  display: grid;

  width: 30px !important;
  height: 30px !important;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  p {
    align-self: center;
    justify-self: center;
    margin: 0;
  }
}

.table-vertical {
  border: 2px solid #000;
  font-weight: bold;
  cursor: pointer;
  justify-self: center;
  width: 35px !important;
  height: 35px !important;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.red-table {
  background-color: $backstage-color;
  border: 2px solid #ff6b6b; // Lighter red border for contrast
  color: #ffffff;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.5); // Subtle text glow
}

.exit {
  background-color: #333;
  grid-column: 4/6;
  grid-row: 3/4;
  justify-self: center;
  align-self: end;
  text-align: center;
}

.r1 {
  background-color: #333;
  grid-column: 3/5;
  grid-row: 2/3;
  justify-self: center;
  // align-self: end;
  text-align: center;
}

.wc {
  background-color: #333;
  grid-column: 2/3;
  grid-row: 1/2;
  align-self: end;
  text-align: center;
}

.gold-table {
  background: linear-gradient(145deg, #ffd700, #ffa500);
  border: 2px solid #ffd700;
  color: #000000;
  font-weight: bold;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 15px $dj-area-glow;

  &:hover {
    background: linear-gradient(145deg, #ffa500, #ffd700);
    box-shadow: 0 0 20px $dj-area-glow;
  }
}

.blue-table {
  background-color: $regular-table-color;
  border: 2px solid #74b9ff;
}

.green-table {
  background-color: $premium-green;
  border: 2px solid $premium-green-border; // Keeping the light golden border as per your original
  color: #ffffff; // Changed to white for better contrast against the dark green
}

.black-table {
  background-color: $anthracite-bg;
  border: 3px solid $anthracite-border; // Keeping the light golden border as per your original
  color: #ffffff; // Changed to white for better contrast against the dark green
}

.dj-table {
  background-color: rgb(212, 184, 29);
}

/* Apply specific styles for the B13 and B14 tables, which are yellow */
#b13,
#b14 {
  background-color: yellow;
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
}

.table-booked {
  cursor: not-allowed;
  opacity: 0.5; /* Example styling for booked tables */
}

.bar-area,
.bar-area-backstage,
.sound-area,
.dj-area {
  border: 2px solid $accent-color;
  background-color: rgba(138, 43, 226, 0.2); // Matching the new accent color
  border-radius: 15px;

  p {
    font-weight: bold;
    text-transform: uppercase;
    color: $accent-color; // Text color matching the border
    justify-self: center;
    align-self: center;
  }
}

// Animations
@keyframes floatGuide {
  0%,
  100% {
    transform: translateX(-50%) translateY(0);
  }
  50% {
    transform: translateX(-50%) translateY(-5px);
  }
}

@keyframes pulseBorder {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  70% {
    transform: scale(1.1);
    opacity: 0;
  }
  100% {
    transform: scale(1.1);
    opacity: 0;
  }
}

// Optional click effect
@keyframes clickRipple {
  0% {
    transform: scale(0.95);
  }
  40% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}
