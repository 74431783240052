@import "../../_variables";
@import "../../_animations";

.guest-code-settings {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 2rem;
  background-color: $background-color;
  gap: 2rem;

  h2 {
    color: $primary-color;
    margin-bottom: 2rem;
  }

  .input-section,
  .output-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 1rem;
    justify-items: center;
    align-items: center;
    width: 100%;
    max-width: $container-width;
    animation: fadeIn 0.5s ease-in-out forwards;
  }

  input,
  select {
    width: 100%;
    padding: 0.5rem;
    font-family: $font-family;
    font-size: $font-size;
    border: 2px solid $border-color;
    border-radius: 4px;
    background-color: $light-color;
    color: $text-color;
    transition: border-color 0.3s;

    &:focus {
      border-color: $primary-color;
      outline: none;
    }
  }

  .input-section {
    margin-bottom: 2rem;
  }

  .output-section {
    margin-bottom: 2rem;
  }

  button {
    @include button;
    margin-top: 1rem;
    animation: scaleIn 0.3s ease-in-out forwards;
  }

  .constructed-sentence {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 0.5rem;
    justify-items: center;
    align-items: center;
    font-family: $font-family;
    font-size: 1.25rem;
    font-weight: 500;
    color: $primary-color;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: $container-width;
    padding: 0 1rem;
    text-align: center;

    span {
      padding: 0 0.25rem;
    }
  }

  // Add your media queries for responsive design
  @media (max-width: 768px) {
    .input-section,
    .output-section {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.ticket-code-settings {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  color: #fff;
}

// Ticket List Styles
.ticket-list {
  padding: 0.5rem 0;

  .no-tickets-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 1rem;
    text-align: center;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    margin: 1rem 0;

    .icon {
      font-size: 3rem;
      margin-bottom: 1rem;
      color: rgba(255, 255, 255, 0.3);
    }

    p {
      margin-bottom: 1.5rem;
      color: rgba(255, 255, 255, 0.6);
    }
  }

  .tickets-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    h4 {
      font-size: 1rem;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.9);
    }
  }

  .add-ticket-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .add-ticket-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--primary-color);
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    transition: all 0.2s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: var(--primary-color-dark);
      transform: scale(1.05);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    }

    svg {
      font-size: 1.2rem;
    }
  }

  .tickets-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 20px;
  }

  .ticket-item {
    background: rgba(30, 30, 30, 0.6);
    border: 1px solid rgba(255, 255, 255, 0.08);
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease;

    margin-bottom: 15px;

    &:hover {
      transform: translateY(-2px);
    }

    .ticket-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      background: rgba(0, 0, 0, 0.2);
      border-bottom: 1px solid rgba(255, 255, 255, 0.05);

      .ticket-title {
        h4 {
          font-size: 1.1rem;
          margin: 0;
          color: #fff;
        }

        .ticket-price {
          display: flex;
          align-items: baseline;
          gap: 0.5rem;
          margin-top: 0.25rem;

          .current-price {
            font-size: 1.2rem;
            font-weight: 600;
            color: #2196f3;
            order: 1;
          }

          .original-price {
            font-size: 0.9rem;
            color: rgba(255, 255, 255, 0.5);
            text-decoration: line-through;
            order: 2;
          }
        }
      }

      .ticket-actions {
        display: flex;
        gap: 0.5rem;

        button {
          background: transparent;
          border: none;
          color: rgba(255, 255, 255, 0.7);
          padding: 0.5rem;
          cursor: pointer;
          transition: all 0.2s ease;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            color: #fff;
            transform: translateY(-2px);
          }

          &.delete-button {
            color: rgba(255, 59, 48, 0.7);
            &:hover {
              color: #ff3b30;
            }
          }

          svg {
            font-size: 1.25rem;
          }
        }
      }
    }

    .ticket-details {
      padding: 1rem;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 1rem;
      overflow: hidden;

      .detail-item {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        padding: 0.75rem;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        border: 1px solid rgba(255, 255, 255, 0.05);

        svg {
          font-size: 1.25rem;
          color: rgba(255, 255, 255, 0.5);
        }

        .detail-content {
          .label {
            font-size: 0.8rem;
            color: rgba(255, 255, 255, 0.5);
            margin-bottom: 0.25rem;
          }

          .value {
            font-size: 0.95rem;
            color: #fff;
            font-weight: 500;
          }
        }
      }
    }

    .toggle-details-button {
      position: absolute;
      bottom: -15px;
      left: 50%;
      transform: translateX(-50%);
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.5);
      border: 1px solid rgba(255, 255, 255, 0.1);
      color: rgba(255, 255, 255, 0.8);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 2;
      transition: all 0.2s ease;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

      &:hover {
        background: rgba(0, 0, 0, 0.7);
        color: white;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
      }

      svg {
        font-size: 1rem;
      }
    }
  }
}

// Ticket Form Styles
.ticket-form {
  .form-header {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    position: relative;

    .back-button {
      position: absolute;
      left: 0;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      background: transparent;
      border: none;
      color: rgba(255, 255, 255, 0.7);
      font-size: 0.9rem;
      cursor: pointer;
      transition: all 0.2s ease;
      padding: 0.5rem;

      &:hover {
        color: #fff;
      }
    }

    h4 {
      width: 100%;
      text-align: center;
      font-size: 1.1rem;
      font-weight: 500;
      margin: 0;
    }
  }

  .form-content {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    label {
      font-size: 0.9rem;
      color: rgba(255, 255, 255, 0.7);
      font-weight: 500;

      .optional {
        font-size: 0.8rem;
        color: rgba(255, 255, 255, 0.4);
        font-weight: normal;
      }
    }

    input,
    textarea,
    select,
    .date-picker-input {
      background: rgba(255, 255, 255, 0.05);
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 8px;
      padding: 0.75rem 1rem;
      color: #fff;
      font-size: 0.95rem;
      width: 100%;
      transition: all 0.2s ease;

      &:focus {
        outline: none;
        border-color: rgba(33, 150, 243, 0.5);
        background: rgba(255, 255, 255, 0.07);
        box-shadow: 0 0 0 3px rgba(33, 150, 243, 0.1);
      }

      &::placeholder {
        color: rgba(255, 255, 255, 0.3);
      }
    }

    textarea {
      resize: vertical;
      min-height: 80px;
    }

    .input-with-icon {
      position: relative;

      .input-icon {
        position: absolute;
        left: 1rem;
        top: 50%;
        transform: translateY(-50%);
        color: rgba(255, 255, 255, 0.4);
        font-size: 1.1rem;
      }

      input,
      .date-picker-input {
        padding-left: 2.5rem;
      }
    }
  }

  .form-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  .checkbox-group {
    .checkbox-label {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      cursor: pointer;
      user-select: none;

      input[type="checkbox"] {
        width: 18px;
        height: 18px;
        margin: 0;
      }

      span {
        font-size: 0.95rem;
        color: rgba(255, 255, 255, 0.9);
      }
    }

    .help-text {
      margin: 0.25rem 0 0 1.75rem;
      font-size: 0.85rem;
      color: rgba(255, 255, 255, 0.5);
    }
  }

  .color-selector {
    display: flex;
    align-items: center;
    gap: 1rem;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 0.75rem 1rem;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background: rgba(255, 255, 255, 0.07);
    }

    .color-preview {
      width: 24px;
      height: 24px;
      border-radius: 6px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }

    span {
      flex: 1;
      color: #fff;
    }

    .color-icon {
      color: rgba(255, 255, 255, 0.5);
      font-size: 1.1rem;
    }
  }

  .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 2rem;
    padding-top: 1.5rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);

    button {
      padding: 0.75rem 1.5rem;
      border-radius: 8px;
      font-size: 0.95rem;
      cursor: pointer;
      transition: all 0.2s ease;

      &.cancel-button {
        background: rgba(255, 255, 255, 0.05);
        border: 1px solid rgba(255, 255, 255, 0.1);
        color: rgba(255, 255, 255, 0.7);

        &:hover {
          background: rgba(255, 255, 255, 0.1);
          color: #fff;
        }
      }

      &.save-button {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        background: #2196f3;
        border: none;
        color: #fff;
        font-weight: 500;

        &:hover {
          background: #1e88e5;
          transform: translateY(-2px);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
        }
      }
    }
  }
}

// Color Picker Styles
.color-picker-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.color-picker-dialog {
  background: #1a1a1a;
  border-radius: 12px;
  width: 90%;
  max-width: 400px;
  padding: 1.5rem;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.1);

  h3 {
    margin: 0 0 1.25rem;
    font-size: 1.2rem;
    color: #fff;
    text-align: center;
    font-weight: 600;
  }

  .color-options {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 12px;
    margin-bottom: 1rem;

    .color-option {
      width: 40px;
      height: 40px;
      border-radius: 8px;
      cursor: pointer;
      transition: all 0.2s ease;
      border: 2px solid transparent;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);

      &:hover {
        transform: scale(1.1);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
      }
    }
  }
}

// React DatePicker Overrides
.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker {
  font-family: inherit;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: #1a1a1a;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);

  &__header {
    background-color: #222;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  &__current-month,
  &__day-name,
  &__day,
  &__time-name {
    color: #fff;
  }

  &__day:hover,
  &__month-text:hover,
  &__quarter-text:hover,
  &__year-text:hover {
    background-color: rgba(33, 150, 243, 0.2);
  }

  &__day--selected,
  &__day--keyboard-selected,
  &__month-text--selected,
  &__month-text--keyboard-selected,
  &__quarter-text--selected,
  &__quarter-text--keyboard-selected,
  &__year-text--selected,
  &__year-text--keyboard-selected {
    background-color: #2196f3;
    color: #fff;
  }

  &__day--disabled,
  &__month-text--disabled,
  &__quarter-text--disabled,
  &__year-text--disabled {
    color: #666;
  }

  &__triangle {
    border-bottom-color: #1a1a1a !important;
  }
}

// Responsive Adjustments
@media (max-width: 768px) {
  .ticket-form {
    .form-row {
      grid-template-columns: 1fr;
      gap: 1rem;
    }
  }

  .color-picker-dialog {
    max-width: 320px;
    padding: 1rem;

    .color-options {
      grid-template-columns: repeat(4, 1fr);
      gap: 8px;

      .color-option {
        width: 36px;
        height: 36px;
      }
    }
  }
}
