.user-interface {
  height: 100%;
  display: flex;
  flex-direction: column;
  color: #fff;
  padding: 1.5rem;
  background: #151515;
  position: relative;

  .interface-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    h2 {
      margin: 0;
      font-size: 1.5rem;
      font-weight: 600;
      background: linear-gradient(45deg, #fff, rgba(255, 200, 7, 0.8));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .close-btn {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      border: none;
      background: rgba(255, 255, 255, 0.1);
      color: rgba(255, 255, 255, 0.7);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        background: rgba(255, 200, 7, 0.2);
        color: #ffc807;
        transform: rotate(90deg);
      }

      svg {
        font-size: 1.25rem;
      }
    }
  }

  .search-bar {
    position: relative;
    margin-bottom: 1.5rem;

    .search-icon {
      position: absolute;
      left: 1rem;
      top: 50%;
      transform: translateY(-50%);
      color: rgba(255, 255, 255, 0.5);
      font-size: 1.1rem;
      pointer-events: none;
    }

    input {
      width: 100%;
      padding: 1rem 1rem 1rem 2.75rem;
      background: rgba(255, 255, 255, 0.05);
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 12px;
      color: #fff;
      font-size: 0.95rem;
      transition: all 0.2s ease;

      &::placeholder {
        color: rgba(255, 255, 255, 0.3);
      }

      &:focus {
        outline: none;
        border-color: rgba(255, 200, 7, 0.3);
        background: rgba(255, 255, 255, 0.08);
        box-shadow: 0 0 0 4px rgba(255, 200, 7, 0.1);
      }
    }
  }

  .members-list {
    flex: 1;
    overflow-y: auto;
    margin: 0 -1.5rem;
    padding: 0 1.5rem;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.1);
      border-radius: 3px;

      &:hover {
        background: rgba(255, 200, 7, 0.3);
      }
    }

    .loading-state,
    .empty-state {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 200px;
      color: rgba(255, 255, 255, 0.5);
      font-size: 0.95rem;
      text-align: center;
      background: rgba(255, 255, 255, 0.02);
      border-radius: 12px;
      margin: 1rem 0;
    }

    .member-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;
      border-radius: 12px;
      margin-bottom: 0.75rem;
      background: rgba(255, 255, 255, 0.03);
      border: 1px solid rgba(255, 255, 255, 0.05);
      transition: all 0.2s ease;

      &:hover {
        background: rgba(255, 255, 255, 0.05);
        transform: translateY(-1px);
        border-color: rgba(255, 200, 7, 0.1);
      }

      .member-info {
        display: flex;
        align-items: center;
        gap: 1rem;

        .member-avatar,
        .member-avatar-placeholder {
          width: 44px;
          height: 44px;
          border-radius: 50%;
          background: linear-gradient(
            45deg,
            rgba(255, 200, 7, 0.1),
            rgba(255, 200, 7, 0.2)
          );
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffc807;
          font-weight: 600;
          font-size: 1.2rem;
          border: 2px solid rgba(255, 200, 7, 0.3);
        }

        .member-avatar {
          object-fit: cover;
        }

        .member-details {
          display: flex;
          flex-direction: column;
          gap: 0.25rem;

          .member-name {
            font-weight: 500;
            color: #fff;
            font-size: 0.95rem;
          }

          .member-role {
            font-size: 0.8rem;
            color: rgba(255, 255, 255, 0.5);
            text-transform: capitalize;
          }
        }
      }

      .member-avatar-placeholder {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        background: linear-gradient(
          45deg,
          rgba(255, 200, 7, 0.1),
          rgba(255, 200, 7, 0.2)
        );
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffc807;
        font-weight: 600;
        font-size: 1.2rem;
        border: 2px solid rgba(255, 200, 7, 0.3);

        .placeholder-icon {
          font-size: 1.5rem;
          color: #ffc807;
        }
      }

      .member-actions {
        display: flex;
        align-items: center;
        gap: 0.75rem;

        .role-select {
          padding: 0.5rem 1rem;
          border-radius: 8px;
          background: rgba(255, 255, 255, 0.05);
          border: 1px solid rgba(255, 255, 255, 0.1);
          color: #fff;
          font-size: 0.9rem;
          outline: none;
          cursor: pointer;
          transition: all 0.2s ease;

          &:hover {
            border-color: rgba(255, 200, 7, 0.3);
            background: rgba(255, 255, 255, 0.08);
          }

          &:focus {
            border-color: rgba(255, 200, 7, 0.5);
            box-shadow: 0 0 0 3px rgba(255, 200, 7, 0.1);
          }

          option {
            background: #151515;
            color: #fff;
            padding: 8px;
          }
        }

        .action-btn {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: all 0.2s ease;

          svg {
            font-size: 1.1rem;
          }

          &.remove {
            background: rgba(255, 68, 68, 0.1);
            color: #ff4444;

            &:hover {
              background: rgba(255, 68, 68, 0.2);
              transform: scale(1.05);
            }

            &:active {
              transform: scale(0.95);
            }
          }

          &.ban {
            background: rgba(255, 200, 7, 0.1);
            color: #ffc807;

            &:hover {
              background: rgba(255, 200, 7, 0.2);
              transform: scale(1.05);
            }

            &:active {
              transform: scale(0.95);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .user-interface {
    padding: 1rem;

    .members-list {
      margin: 0 -1rem;
      padding: 0 1rem;

      .member-item {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;

        .member-actions {
          width: 100%;
          justify-content: flex-end;
        }
      }
    }
  }
}

// Add styling for the role badge
.role-badge {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 0.8rem;
  font-weight: 500;

  &.founder {
    background-color: rgba(255, 200, 7, 0.15);
    color: #ffc807;
    border: 1px solid rgba(255, 200, 7, 0.25);

    svg {
      font-size: 1rem;
    }
  }
}
