// Scanner.scss - Minimalistic redesign
.scanner-container {
  width: 100%;
  min-height: 100vh;
  background: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.scanner-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  padding-top: 5rem;
  max-width: 500px;
  margin: 0 auto;
  width: 100%;
  position: relative;
}

// Home screen
.scanner-home {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  .scanner-title {
    text-align: center;

    h1 {
      font-size: 1.75rem;
      font-weight: 700;
      margin-bottom: 0.5rem;
      background: linear-gradient(90deg, #ffc107, #ff9800);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    p {
      color: rgba(255, 255, 255, 0.7);
      font-size: 1rem;
    }
  }

  .scanner-actions {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .scan-button {
      background: linear-gradient(90deg, #ffc107, #ff9800);
      color: #000;
      border: none;
      border-radius: 12px;
      padding: 1rem;
      font-size: 1rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.75rem;
      cursor: pointer;
      box-shadow: 0 4px 12px rgba(255, 193, 7, 0.3);

      svg {
        font-size: 1.5rem;
      }
    }

    .manual-input {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;

      input {
        background: rgba(255, 255, 255, 0.05);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 12px;
        padding: 1rem;
        color: #fff;
        font-size: 1rem;

        &::placeholder {
          color: rgba(255, 255, 255, 0.5);
        }

        &:focus {
          outline: none;
          border-color: #ffc107;
        }
      }

      button {
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 12px;
        padding: 0.75rem;
        color: #fff;
        font-size: 0.9rem;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }
}

// Error container
.error-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2rem;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  gap: 1.5rem;

  .error-icon {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background: rgba(244, 67, 54, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      font-size: 2rem;
      color: #f44336;
    }
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #f44336;
    margin: 0;
  }

  p {
    color: rgba(255, 255, 255, 0.7);
    font-size: 1rem;
    margin: 0;
    max-width: 300px;
  }

  .try-again-btn {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 12px;
    padding: 0.75rem 1.5rem;
    color: #fff;
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
    margin-top: 1rem;

    &:hover {
      background: rgba(255, 255, 255, 0.15);
    }
  }
}

// Camera screen
.scanner-camera {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .camera-container {
    width: 100%;
    aspect-ratio: 1;
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    background: #000;

    .scan-overlay {
      position: absolute;
      inset: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .scan-frame {
        width: 70%;
        aspect-ratio: 1;
        border: 2px solid #ffc107;
        border-radius: 12px;
        box-shadow: 0 0 0 4000px rgba(0, 0, 0, 0.5);
      }

      p {
        position: absolute;
        bottom: 2rem;
        color: #fff;
        font-size: 0.9rem;
        background: rgba(0, 0, 0, 0.7);
        padding: 0.5rem 1rem;
        border-radius: 20px;
      }
    }
  }

  .cancel-scan {
    background: rgba(255, 255, 255, 0.1);
    border: none;
    border-radius: 12px;
    padding: 0.75rem;
    color: #fff;
    font-size: 0.9rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    cursor: pointer;

    svg {
      font-size: 1.25rem;
      color: rgba(255, 255, 255, 0.8);
    }
  }

  .scanner-error {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 2rem;
    text-align: center;

    p {
      color: #f44336;
      font-size: 1rem;
    }
  }
}

// Result screen
.scan-result {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 2rem;

  .result-header {
    padding: 1.5rem;
    border-radius: 12px 12px 0 0;
    color: white;
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.2)
    );
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: inherit;
      filter: blur(10px);
      z-index: -1;
    }

    h2 {
      margin: 0 0 0.5rem;
      font-size: 1.2rem;
      font-weight: 600;
      display: inline-flex;
      align-items: center;

      &::after {
        content: "";
        display: inline-block;
        width: 8px;
        height: 8px;
        background-color: #4caf50;
        border-radius: 50%;
        margin-left: 0.5rem;
      }
    }

    .result-event {
      opacity: 0.9;
      font-size: 0.9rem;
      font-weight: 500;
      margin-top: 0.25rem;

      p {
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    // Enhanced color classes for different code types
    &.guest-code {
      background: linear-gradient(135deg, #3a7bd5, #00d2ff);
    }

    &.friends-code {
      background: linear-gradient(135deg, #ff8008, #ffc837);
    }

    &.backstage-code {
      background: linear-gradient(135deg, #834d9b, #d04ed6);
    }

    &.table-code {
      background: linear-gradient(135deg, #4cb8c4, #3cd3ad);
    }

    &.ticket-code {
      background: linear-gradient(135deg, #1d976c, #93f9b9);
    }

    &.custom-code {
      background: linear-gradient(135deg, #614385, #516395);
    }
  }

  .event-banner {
    background: rgba(255, 255, 255, 0.03);
    border-radius: 0 0 12px 12px;
    padding: 1rem;
    margin-top: -1.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-top: none;

    .event-info {
      display: flex;
      align-items: center;
      gap: 0.75rem;

      svg {
        color: #ffc107;
        font-size: 1.2rem;
        flex-shrink: 0;
      }

      .info-text {
        flex: 1;

        .label {
          font-size: 0.75rem;
          color: rgba(255, 255, 255, 0.5);
        }

        .value {
          font-size: 0.9rem;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.9);
        }
      }
    }
  }

  .result-details {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .detail-item {
      display: flex;
      align-items: center;
      gap: 1rem;
      background: rgba(255, 255, 255, 0.05);
      padding: 1rem;
      border-radius: 12px;
      transition: all 0.2s ease;

      svg {
        font-size: 1.5rem;
        color: #ffc107;
        flex-shrink: 0;
      }

      div {
        flex: 1;

        label {
          display: block;
          font-size: 0.8rem;
          color: rgba(255, 255, 255, 0.6);
          margin-bottom: 0.25rem;
        }

        p {
          font-size: 1rem;
          font-weight: 500;
          margin: 0;
          word-break: break-word;
        }
      }

      &.highlight-condition {
        background: rgba(255, 193, 7, 0.15);
        border-left: 3px solid #ffc107;

        svg {
          color: #ffb300;
        }

        div label {
          color: rgba(255, 255, 255, 0.8);
          font-weight: 500;
        }

        div p {
          font-weight: 600;
          color: #fff;
        }
      }
    }
  }

  .counter-section {
    background: rgba(255, 255, 255, 0.08);
    border-radius: 12px;
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 0.5rem;
    border: 1px solid rgba(255, 255, 255, 0.1);

    .counter-label {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        font-size: 0.95rem;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.8);
      }

      .counter-info {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        .current {
          font-size: 1.75rem;
          font-weight: 700;
          color: #ffc107;
        }

        .divider {
          color: rgba(255, 255, 255, 0.3);
        }

        .max {
          font-size: 1.25rem;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }

    .counter-controls {
      display: flex;
      gap: 1rem;

      .counter-btn {
        flex: 1;
        background: rgba(255, 255, 255, 0.1);
        border: none;
        border-radius: 8px;
        height: 3.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 1.75rem;
        cursor: pointer;
        transition: all 0.2s ease;

        &:disabled {
          opacity: 0.3;
          cursor: not-allowed;
        }

        &:not(:disabled):hover {
          transform: translateY(-2px);
        }

        &:not(:disabled):active {
          transform: translateY(1px);
        }

        &.increase {
          background: rgba(76, 175, 80, 0.2);
          color: #81c784;

          &:not(:disabled):hover {
            background: rgba(76, 175, 80, 0.3);
          }
        }

        &.decrease {
          background: rgba(244, 67, 54, 0.2);
          color: #e57373;

          &:not(:disabled):hover {
            background: rgba(244, 67, 54, 0.3);
          }
        }
      }
    }
  }

  .scan-again-btn {
    background: linear-gradient(90deg, #ffc107, #ff9800);
    color: #000;
    border: none;
    border-radius: 12px;
    padding: 1rem;
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    cursor: pointer;
    margin-top: 1rem;

    svg {
      font-size: 1.25rem;
    }
  }
}

// Processing overlay
.processing-overlay {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  z-index: 100;
  backdrop-filter: blur(4px);

  .loader {
    width: 40px;
    height: 40px;
    border: 3px solid rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    border-top-color: #ffc107;
    animation: spin 1s ease-in-out infinite;
  }

  p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 1rem;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

// Responsive adjustments
@media (max-width: 480px) {
  .scanner-content {
    padding: 1rem;
    padding-top: 4.5rem;
  }

  .scanner-home {
    .scanner-title {
      h1 {
        font-size: 1.5rem;
      }
    }
  }

  .error-container {
    padding: 1.5rem;

    .error-icon {
      width: 56px;
      height: 56px;

      svg {
        font-size: 1.75rem;
      }
    }

    h2 {
      font-size: 1.25rem;
    }
  }

  .scan-result {
    .result-header {
      padding: 1rem;

      h2 {
        font-size: 1.1rem;
      }
    }

    .counter-section {
      .counter-label {
        .counter-info {
          .current {
            font-size: 1.25rem;
          }

          .max {
            font-size: 1rem;
          }
        }
      }
    }
  }
}
