@import "../../_variables";

.headerDashboard {
  margin-top: 01rem;

  display: grid;

  &-avatar {
    position: relative;

    display: grid;
    &-wrapper {
      @include grid(3);
      grid-gap: 0.25rem;
      justify-self: center;

      .share-icon {
        align-self: end;
        visibility: hidden;
      }

      .edit-icon {
        align-self: end;
      }

      .profile-icon {
        justify-self: center;
        border-radius: 50%;
        width: 64px;
        height: 64px;
        border: none;
        transition: all 0.3s ease;

        &:hover {
          transform: scale(1.05);
          box-shadow: 0 4px 12px rgba(255, 200, 7, 0.2);
        }
      }

      .online-status {
        position: absolute;
        bottom: 2px;
        right: 2px;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: 2px solid #151515;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
        z-index: 10;

        &.online {
          background: radial-gradient(circle at 30% 30%, #4cd137, #44bd32);
        }

        &.offline {
          background: radial-gradient(circle at 30% 30%, #ff6b6b, #ee5253);
        }
      }

      .profile-icon-placeholder {
        justify-self: center;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(145deg, #1a1a1a, #2a2a2a);
        color: #ffc807;
        font-size: 2rem;
        border: none;
        transition: all 0.3s ease;

        &:hover {
          transform: scale(1.05);
          box-shadow: 0 4px 12px rgba(255, 200, 7, 0.2);
        }

        svg {
          filter: drop-shadow(0 2px 4px rgba(255, 200, 7, 0.2));
        }
      }
    }

    .avatar-icon {
      width: 1.5rem;
      cursor: pointer;
    }

    .avatar-cancel-icon {
      justify-self: center;
    }

    .avatar-controls {
      cursor: pointer;
      width: 2.5rem;
      margin: 0.5rem;
    }

    .profileApp {
      width: 5.125rem;
      justify-self: center;

      .image-upload-file {
        display: none;
      }
    }

    .avatar-crop {
      display: grid;
      grid-gap: 1rem;
      justify-content: center;
      &-controls {
        display: grid;

        &-range {
          -webkit-appearance: none;
          width: 100%;
          height: 8px;
          background: #f4c430; /* Gold background */
          outline: none;
          opacity: 0.75;
          -webkit-transition: 0.2s;
          transition: opacity 0.2s;

          &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 25px;
            height: 25px;
            background: #ffd700; /* Solid gold color */
            cursor: pointer;
            border-radius: 50%;
          }

          &::-moz-range-thumb {
            width: 25px;
            height: 25px;
            background: #ffd700; /* Solid gold color */
            cursor: pointer;
            border-radius: 50%;
          }

          &:focus::-webkit-slider-thumb {
            background: #b8860b; /* Darker gold tone on focus */
          }

          &:focus::-moz-range-thumb {
            background: #b8860b; /* Darker gold tone on focus */
          }
        }
      }
    }
  }

  &-name {
    color: #fff;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &-image {
    width: 7rem;
    justify-self: center;
  }
  &-subtitle {
    color: #fff;
    text-align: center;
    font-family: Manrope;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    color: #fff;
    font-family: Manrope;

    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    letter-spacing: 4.2px;
    text-transform: uppercase;
  }

  &-price {
    font-style: italic;
    text-align: center;
    color: #ffd700;
    font-weight: 600;
  }
  &-email {
    font-style: italic;
  }

  &-info {
    @include grid-row(3);
    margin-top: 1rem;

    &-greeting {
      color: #d6d6d6;
      text-align: center;
      font-size: 1rem;
      font-style: normal;
      font-weight: 300;
      line-height: 1.5rem;
      font-family: Lato;
    }

    &-name {
      color: #fff;
      text-align: center;
      font-family: Lato;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 800;
      line-height: 1.5rem;
      margin-bottom: 1rem;
    }

    &-username {
      color: #fff;
      font-size: 1.25rem;
      font-style: normal;
      line-height: normal;
    }

    &-role {
      color: #d6d6d6;
      text-align: center;
      font-size: 1rem;
      font-style: normal;
      font-weight: 300;
      line-height: 1.5rem;
      font-family: Lato;
    }

    &-status {
      color: #d6d6d6;
      font-size: 0.9rem;
      margin-top: 0.5rem;
    }
  }

  &-selection {
    width: 12.5rem;
    height: 2.5rem;
    padding: 0.25rem 0rem;
    border-radius: 1.5625rem;
    background: #000;
    gap: 0.625rem;
    justify-self: center;
    margin-top: 0.5rem;

    &-event {
      display: grid;
      grid-template-columns: 0.25fr 0.5fr 0.25fr;
      &-image {
        display: grid;
        background: linear-gradient(180deg, #656565 0%, #161616 100%);
        width: 1.9375rem;
        height: 1.9375rem;
        border-radius: 50%;
        justify-self: center;

        img {
          width: 1.6rem;
          align-self: center;
          justify-self: center;
        }
      }

      &-name {
        color: #fff;
        font-family: Lato;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        align-self: center;
        text-align: center;
      }

      &-dropdown {
        align-self: center;
        justify-self: center;
      }
    }
  }

  .avatar-upload-overlay {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(4px);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .avatar-upload-container {
    position: relative;
    background: #151515;
    border-radius: 12px;
    padding: 2rem;
    width: 90%;
    max-width: 400px;
    border: 1px solid rgba(255, 200, 7, 0.1);
    z-index: 1001;

    .close-button {
      position: absolute;
      top: 1rem;
      right: 1rem;
      width: 32px;
      height: 32px;
      border-radius: 8px;
      border: none;
      background: rgba(255, 255, 255, 0.1);
      color: rgba(255, 255, 255, 0.7);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        background: rgba(255, 255, 255, 0.15);
        color: white;
      }
    }

    .upload-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.5rem;

      input[type="file"] {
        display: none;
      }

      label {
        position: relative;
        cursor: pointer;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        overflow: hidden;
        background: rgba(255, 255, 255, 0.05);
        border: 2px dashed rgba(255, 200, 7, 0.3);
        transition: all 0.2s ease;

        &:hover {
          background: rgba(255, 255, 255, 0.08);
          border-color: rgba(255, 200, 7, 0.4);

          .upload-icon {
            transform: translateY(-2px);
            background: rgba(255, 200, 7, 0.15);
          }
        }

        .current-avatar {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .avatar-placeholder {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 3rem;
          color: rgba(255, 200, 7, 0.5);
        }

        .upload-icon {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 32px;
          height: 32px;
          background: rgba(255, 200, 7, 0.1);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffc807;
          transition: all 0.2s ease;
        }
      }
    }
  }
}

.cropper-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 400px;
  background: #151515;
  border-radius: 12px;
  overflow: hidden;
  z-index: 1001;
  border: 1px solid rgba(255, 200, 7, 0.1);

  .cropper-wrapper {
    position: relative;
    height: 400px;
    background: #000;
  }

  .controls {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);

    .zoom-slider {
      -webkit-appearance: none;
      width: 100%;
      height: 4px;
      background: rgba(255, 200, 7, 0.1);
      outline: none;
      border-radius: 2px;

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 20px;
        height: 20px;
        background: #ffc807;
        border-radius: 50%;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    .buttons {
      display: flex;
      justify-content: center;
      gap: 1rem;

      button {
        width: 40px;
        height: 40px;
        border-radius: 8px;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.2s ease;

        &.cancel-btn {
          background: rgba(255, 255, 255, 0.1);

          &:hover {
            background: rgba(255, 255, 255, 0.15);
          }
        }

        &.save-btn {
          background: rgba(255, 200, 7, 0.1);
          color: #ffc807;

          &:hover {
            background: rgba(255, 200, 7, 0.15);
          }
        }

        img {
          width: 20px;
          height: 20px;

          &.rotating {
            animation: rotate 1s linear infinite;
          }
        }
      }
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dashboard-header {
  // padding: 0.75rem 1rem;
  margin-top: 3.5rem;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  z-index: 10;

  .dashboard-header-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }

  // Profile Section
  .profile-section {
    display: flex;
    align-items: center;
    gap: 1.5rem;

    .avatar-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      height: 72px;

      .user-avatar {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 5;

        .avatar-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }

      .profile-online-indicator {
        position: absolute;
        bottom: 2px;
        right: 2px;
        z-index: 20;
      }
    }

    .user-info {
      min-width: 0;
      padding-top: 0.25rem;

      .user-info-main {
        display: flex;
        flex-direction: column;
        gap: 0.35rem;

        .name-group {
          display: flex;
          align-items: center;
          gap: 0.75rem;

          .display-name {
            font-size: 1.1rem;
            font-weight: 600;
            margin: 0;
            color: white;
          }

          .username {
            font-size: 0.9rem;
            color: #ffc807;
            background: rgba(255, 200, 7, 0.08);
            padding: 0.15rem 0.5rem;
            border-radius: 4px;
            transition: all 0.2s ease;

            &:hover {
              background: rgba(255, 200, 7, 0.12);
            }
          }
        }

        .user-stats {
          display: flex;
          align-items: center;
          gap: 0.75rem;
          color: rgba(255, 255, 255, 0.7);
          font-size: 0.9rem;

          .stat-item {
            display: flex;
            align-items: center;
            gap: 0.35rem;
            transition: all 0.2s ease;

            &:hover {
              color: rgba(255, 200, 7, 0.9);
            }

            .stat-value {
              font-weight: 600;
              color: white;
            }
          }

          .stat-divider {
            color: rgba(255, 255, 255, 0.2);
            font-size: 0.85rem;
          }
        }

        .user-bio {
          font-size: 0.9rem;
          color: rgba(255, 255, 255, 0.8);
          margin-top: 0.25rem;
          line-height: 1.4;
          text-align: left;
        }
      }
    }
  }

  // Event Section
  .event-section {
    position: relative;

    .event-selector {
      display: flex;
      align-items: center;
      gap: 0.6rem;
      padding: 0.35rem 0.6rem;
      background: rgba(255, 255, 255, 0.06);
      border: 1px solid rgba(255, 255, 255, 0.08);
      border-radius: 6px;
      cursor: pointer;
      transition: all 0.2s ease;
      max-width: 220px; /* Limit width for long brand names */
      min-width: 140px; /* Maintain width even when empty */
      min-height: 34px; /* Maintain height even when empty */

      &:hover {
        background: rgba(255, 255, 255, 0.1);
        border-color: rgba(255, 255, 255, 0.12);
      }

      .event-logo {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.8rem;
        color: #fff;
        flex-shrink: 0;
        overflow: hidden;

        .brand-logo-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .brand-initial {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(45deg, #ffc807, #ff9800);
          color: #000;
          font-weight: 600;
        }
      }

      .event-name {
        font-size: 0.85rem;
        color: rgba(255, 255, 255, 0.9);
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 140px; /* Limit text width */
      }

      .dropdown-icon {
        color: rgba(255, 255, 255, 0.5);
        font-size: 1rem;
        display: flex;
        align-items: center;
        flex-shrink: 0;
      }
    }

    .brand-options {
      position: absolute;
      top: calc(100% + 8px);
      left: 0;
      width: 100%;
      min-width: 200px;
      background: #151515;
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 8px;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
      z-index: 10;
      overflow: hidden;

      .brand-option {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 8px 12px;
        transition: all 0.2s ease;
        cursor: pointer;

        &:hover,
        &.active {
          background: rgba(255, 255, 255, 0.1);
        }

        img.brand-logo {
          width: 24px;
          height: 24px;
          border-radius: 6px;
          object-fit: cover;
        }

        .brand-initial {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba(255, 200, 7, 0.2);
          color: #ffc807;
          border-radius: 6px;
          font-weight: 600;
          font-size: 0.85rem;
        }

        span {
          font-size: 0.9rem;
          color: rgba(255, 255, 255, 0.9);
        }
      }

      .no-brands {
        padding: 12px;
        text-align: center;
        color: rgba(255, 255, 255, 0.6);
        font-size: 0.9rem;
      }
    }
  }

  // Date Section
  .date-section {
    position: relative;

    .date-display {
      display: flex;
      align-items: center;
      gap: 0.6rem;
      padding: 0.35rem 0.6rem;
      background: rgba(255, 255, 255, 0.06);
      border: 1px solid rgba(255, 255, 255, 0.08);
      border-radius: 6px;
      cursor: pointer;
      transition: all 0.2s ease;
      white-space: nowrap;
      min-width: 120px; /* Maintain width even when empty */
      min-height: 34px; /* Maintain height even when empty */

      &:hover {
        background: rgba(255, 255, 255, 0.1);
        border-color: rgba(255, 255, 255, 0.12);
      }

      &.has-date {
        background: rgba(255, 200, 7, 0.08);
        border-color: rgba(255, 200, 7, 0.15);

        .calendar-icon {
          color: rgba(255, 200, 7, 0.8);
        }

        span {
          color: rgba(255, 255, 255, 1);
          font-weight: 500;
        }
      }

      .calendar-icon {
        font-size: 0.95rem;
        color: rgba(255, 255, 255, 0.5);
      }

      span {
        font-size: 0.85rem;
        color: rgba(255, 255, 255, 0.9);
      }

      .dropdown-icon {
        color: rgba(255, 255, 255, 0.5);
        font-size: 1rem;
        display: flex;
        align-items: center;
      }
    }

    .date-options {
      position: absolute;
      top: calc(100% + 8px);
      right: 0;
      background: #151515;
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 8px;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
      z-index: 10;
      width: 240px;
      max-height: 300px;
      overflow-y: auto;

      .date-option {
        padding: 8px 12px;
        cursor: pointer;
        transition: all 0.2s ease;
        border-bottom: 1px solid rgba(255, 255, 255, 0.05);

        &:last-child {
          border-bottom: none;
        }

        &:hover,
        &.active {
          background: rgba(255, 255, 255, 0.1);
        }

        &.active {
          border-left: 3px solid #ffc807;
        }

        &.selected {
          background-color: rgba(255, 255, 255, 0.15);
          font-weight: 600;
          color: #fff;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 3px;
            background-color: #ffc807;
            border-radius: 0 2px 2px 0;
          }
        }

        .date-info {
          display: flex;
          flex-direction: column;
          gap: 2px;

          .date-text {
            font-size: 0.9rem;
            color: rgba(255, 255, 255, 0.9);
          }

          .event-count {
            font-size: 0.75rem;
            color: rgba(255, 200, 7, 0.9);
          }
        }

        &.view-all {
          text-align: center;
          color: #ffc807;
          font-weight: 500;
          padding: 8px;
          background: rgba(255, 200, 7, 0.05);
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            background: rgba(255, 200, 7, 0.1);
          }

          .more-icon {
            font-size: 1.2rem;
          }
        }
      }

      .no-dates {
        padding: 12px;
        text-align: center;
        color: rgba(255, 255, 255, 0.6);
        font-size: 0.9rem;
      }
    }

    // Keep this for backward compatibility
    .date-controls {
      position: absolute;
      top: calc(100% + 8px);
      right: 0;
      display: flex;
      align-items: center;
      gap: 8px;
      background: #151515;
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 8px;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
      padding: 8px 12px;
      z-index: 10;

      .nav-arrow {
        background: transparent;
        border: none;
        color: rgba(255, 255, 255, 0.6);
        font-size: 1rem;
        cursor: pointer;
        padding: 4px 8px;
        border-radius: 4px;
        transition: all 0.2s ease;

        &:hover {
          background: rgba(255, 255, 255, 0.1);
          color: rgba(255, 255, 255, 0.9);
        }

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }

      .current-date {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 4px 12px;
        cursor: pointer;
        transition: all 0.2s ease;
        border-radius: 4px;

        &:hover {
          background: rgba(255, 255, 255, 0.1);
        }

        svg {
          color: rgba(255, 255, 255, 0.6);
          font-size: 0.9rem;
        }

        span {
          font-size: 0.9rem;
          color: rgba(255, 255, 255, 0.9);
        }
      }
    }
  }

  .notification-section {
    position: relative;
    margin: 0 1rem;

    .notification-trigger {
      position: relative;
      background: none;
      border: none;
      color: rgba(255, 255, 255, 0.7);
      font-size: 1.5rem;
      cursor: pointer;
      padding: 0.5rem;
      border-radius: 50%;
      transition: all 0.3s ease;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: rgba(255, 200, 7, 0.1);
        color: #ffc807;
      }

      &.has-notification {
        color: #ffc807;
      }
    }

    .notification-badge {
      position: absolute;
      top: 0;
      right: 0;
      background: #ffc807;
      color: #151515;
      font-size: 0.75rem;
      padding: 0.25rem 0.5rem;
      border-radius: 10px;
      border: 2px solid #151515;
      min-width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
    }
  }
}

@media (max-width: 768px) {
  .dashboard-header {
    padding: 0.5rem 0.75rem;

    .dashboard-header-content {
      flex-wrap: wrap;
      gap: 0.75rem;
    }

    .profile-section {
      flex-basis: 100%;
      gap: 1rem;

      .user-info {
        .user-info-main {
          gap: 0.5rem;
        }
      }
    }

    .event-section,
    .date-section {
      flex: 1;
      min-width: auto;

      .event-selector,
      .date-display {
        justify-content: center;
      }

      .brand-options,
      .date-options,
      .date-controls {
        width: 100%;
      }
    }
  }
}

@media (max-width: 480px) {
  .dashboard-header {
    .dashboard-header-content {
      gap: 0.5rem;
    }

    .profile-section {
      gap: 0.75rem;

      .user-info {
        .user-info-main {
          .display-name {
            font-size: 1rem;
          }

          .username {
            font-size: 0.8rem;
          }
        }

        .user-stats {
          .stat-item {
            .stat-value {
              font-size: 0.9rem;
            }

            .stat-label {
              font-size: 0.8rem;
            }
          }
        }
      }
    }

    .event-section {
      .event-selector {
        padding: 0.35rem 0.6rem;
        gap: 0.5rem;

        .event-logo {
          width: 20px;
          height: 20px;

          img {
            width: 16px;
            height: 16px;
          }
        }

        .event-name {
          font-size: 0.85rem;
        }
      }
    }

    .date-section {
      .date-display {
        padding: 0.35rem 0.6rem;

        span {
          font-size: 0.8rem;
        }
      }
    }
  }
}

.online-status-dot {
  display: none;
}

.menu-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;

  &:hover {
    color: #ffc807;
    transform: scale(1.1);
  }
}

// Fix the CSS for brand logos in the dropdown

.event-logo {
  .brand-logo-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
}

.brand-option-logo {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  overflow: hidden;

  .brand-logo-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .brand-initial {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 200, 7, 0.2);
    color: #ffc807;
    font-weight: 600;
    font-size: 0.85rem;
  }
}
