.page-wrapper {
  width: 100%;
  min-height: 100vh;
  background: #000;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 2rem;
}

.events {
  padding: 1rem;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 6rem;
  padding-bottom: 4rem;

  .events-header {
    text-align: center;
    margin-bottom: 2rem;

    h1 {
      color: #fff;
      font-size: 2rem;
      margin-bottom: 0.5rem;
    }

    p {
      color: rgba(255, 255, 255, 0.7);
      font-size: 1.1rem;
      margin-bottom: 2rem;
    }

    .brand-selector {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      width: 250px;
      margin: 0 auto 2rem;

      .selected-brand {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 0.75rem;
        padding: 0.75rem 1rem;
        background: rgba(255, 255, 255, 0.05);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 12px;
        cursor: pointer;
        transition: all 0.2s ease;

        img,
        .brand-initial {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          object-fit: cover;
        }

        .brand-initial {
          background: rgba(255, 200, 7, 0.2);
          color: #ffc807;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 600;
          font-size: 1rem;
        }

        .brand-name {
          color: rgba(255, 255, 255, 0.8);
          font-size: 0.9rem;
        }

        &:hover {
          background: rgba(255, 255, 255, 0.08);
        }
      }

      .brand-options {
        position: absolute;
        top: calc(100% + 0.5rem);
        left: 0;
        width: 100%;
        background: #151515;
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 12px;
        overflow: hidden;
        z-index: 10;
        display: none;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);

        &.open {
          display: block;
        }

        .brand-option {
          display: flex;
          align-items: center;
          gap: 0.75rem;
          padding: 0.75rem 1rem;
          cursor: pointer;
          transition: all 0.2s ease;
          border-bottom: 1px solid rgba(255, 255, 255, 0.05);

          &:last-child {
            border-bottom: none;
          }

          img,
          .brand-initial {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            object-fit: cover;
          }

          .brand-initial {
            background: rgba(255, 200, 7, 0.2);
            color: #ffc807;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            font-size: 1rem;
          }

          .brand-name {
            color: rgba(255, 255, 255, 0.8);
            font-size: 0.9rem;
          }

          &:hover {
            background: rgba(255, 255, 255, 0.08);
          }

          &.selected {
            background: rgba(255, 200, 7, 0.1);
            .brand-name {
              color: #ffc807;
            }
          }
        }
      }
    }

    .add-event-button {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.75rem 1.5rem;
      background: #ffc807;
      border: none;
      border-radius: 8px;
      color: #000;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.2s ease;

      svg {
        font-size: 1.25rem;
      }

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba(255, 200, 7, 0.25);
      }
    }
  }

  .events-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
    padding: 1rem;
    margin-bottom: 2rem;
  }

  .loading-state {
    grid-column: 1 / -1;
    text-align: center;
    padding: 2rem;
    color: rgba(255, 255, 255, 0.7);
    font-size: 1.1rem;
  }
}

.event-card {
  position: relative;
  width: 100%;
  background: #151515;
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
  cursor: pointer;
  transform-style: preserve-3d;
  transition: transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  perspective: 1000px;
  min-height: 600px;

  .weekly-navigation {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;

    .nav-arrow {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: rgba(21, 21, 21, 0.95);
      backdrop-filter: blur(10px);
      border: 1px solid rgba(255, 255, 255, 0.15);
      color: rgba(255, 255, 255, 0.9);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      pointer-events: auto;
      font-size: 1.5rem;
      margin: 0 -24px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);

      &:hover:not(:disabled) {
        background: rgba(255, 200, 7, 0.15);
        border-color: rgba(255, 200, 7, 0.5);
        color: #ffc807;
        transform: scale(1.15);
        box-shadow: 0 4px 20px rgba(255, 200, 7, 0.25);
      }

      &:disabled {
        opacity: 0.2;
        cursor: not-allowed;
      }

      &.prev {
        transform-origin: center;
        margin-left: -12px;
      }

      &.next {
        transform-origin: center;
        margin-right: -12px;
      }
    }
  }

  &:hover {
    .weekly-navigation {
      .nav-arrow {
        margin: 0;
        &.prev {
          margin-left: 12px;
        }
        &.next {
          margin-right: 12px;
        }
      }
    }
  }

  &.flipped {
    transform: rotateY(180deg);

    .card-front {
      pointer-events: none;
    }

    .card-back {
      pointer-events: auto;
    }
  }

  &.add-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.05);
    border: 2px dashed rgba(255, 255, 255, 0.1);
    padding: 2rem;
    gap: 1rem;
    min-height: 600px;

    .add-icon {
      font-size: 3rem;
      color: #ffc807;
    }

    p {
      color: rgba(255, 255, 255, 0.7);
      text-align: center;
      font-size: 1.1rem;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.08);
      border-color: #ffc807;

      .add-icon {
        transform: scale(1.1);
      }
    }
  }

  &.child-event {
    .week-indicator {
      display: none;
    }
  }
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background: #151515;
  border-radius: 24px;
}

.card-back {
  transform: rotateY(180deg);
  pointer-events: none;

  .no-permission-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 2rem;
    text-align: center;
    background: rgba(0, 0, 0, 0.8);

    h3 {
      color: #f44336;
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }

    p {
      color: rgba(255, 255, 255, 0.8);
      margin-bottom: 2rem;
      font-size: 1.1rem;
      line-height: 1.5;
    }

    .back-button {
      padding: 0.75rem 1.5rem;
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 8px;
      color: #fff;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        background: rgba(255, 255, 255, 0.2);
        transform: translateY(-2px);
      }
    }
  }
}

.event-card-header {
  position: relative;
  height: 180px;
  overflow: hidden;

  .event-cover-image {
    height: 100%;
    width: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.3s ease;
    }
  }

  .card-actions {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    display: flex;
    gap: 0.5rem;
    z-index: 2;

    .action-button {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      border: none;
      background: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(4px);
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.2s ease;

      svg {
        font-size: 1.1rem;
      }

      &:hover {
        background: rgba(255, 200, 7, 0.2);
        color: #ffc807;
      }
    }
  }
}

.event-card-content {
  padding: 1.5rem;
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: calc(100% - 180px);
  overflow-y: visible;

  .event-info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0.5rem;

    .title-container {
      flex: 1;

      h3 {
        color: #fff;
        margin: 0 0 0.25rem;
        font-size: 1.25rem;
      }

      .subtitle {
        color: rgba(255, 255, 255, 0.7);
        font-size: 0.95rem;
      }
    }

    .go-live-button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.4rem;
      background: rgba(0, 0, 0, 0.3);
      color: rgba(255, 255, 255, 0.8);
      border: none;
      padding: 0.4rem 0.75rem;
      border-radius: 20px;
      font-size: 0.8rem;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.2s ease;
      margin-left: 0.5rem;
      flex-shrink: 0;
      min-width: 80px;

      svg {
        font-size: 0.9rem;
      }

      &:hover {
        background: rgba(0, 0, 0, 0.5);
        color: #fff;
      }

      &.live {
        background: rgba(76, 175, 80, 0.8);
        color: #fff;

        &:hover {
          background: rgba(76, 175, 80, 0.9);
        }
      }
    }
  }

  .event-details {
    display: grid;
    gap: 0.75rem;
    margin: 0.75rem 0;
    padding: 1rem;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    margin-bottom: auto;

    .weekly-date-navigation {
      display: flex;
      align-items: center;
      padding: 0.5rem 0;
      position: relative;

      .navigation-controls {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .date-display {
          color: #ffc807;
          font-size: 1rem;
          font-weight: 500;
          display: flex;
          align-items: center;
          gap: 0.5rem;

          .calendar-icon {
            font-size: 1.2rem;
            color: #ffc807;
            flex-shrink: 0;
          }
        }
      }
    }

    .detail-item {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      color: rgba(255, 255, 255, 0.7);
      position: relative;
      padding: 0.5rem 0;

      svg {
        font-size: 1.2rem;
        color: #ffc807;
        flex-shrink: 0;
      }

      span {
        font-size: 0.95rem;
      }
    }
  }

  .event-features {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);

    .feature {
      background-color: rgba(255, 255, 255, 0.1);
      color: #fff;
      padding: 4px 10px;
      border-radius: 12px;
      font-size: 12px;
      font-weight: 500;
      display: inline-flex;
      align-items: center;

      &.weekly-badge {
        background-color: rgba(103, 58, 183, 0.2);
        color: #b388ff;
      }

      &.live-badge {
        background-color: rgba(76, 175, 80, 0.2);
        color: #81c784;
      }

      &.guest-code {
        background-color: rgba(255, 193, 7, 0.2);
        color: #ffd54f;
      }

      &.friends-code {
        background-color: rgba(33, 150, 243, 0.2);
        color: #64b5f6;
      }

      &.ticket-code {
        background-color: rgba(233, 30, 99, 0.2);
        color: #f48fb1;
      }

      &.table-code {
        background-color: rgba(0, 188, 212, 0.2);
        color: #4dd0e1;
      }
    }
  }
}

.events-container {
  .no-brands-message {
    text-align: center;
    margin: 2rem auto;
    padding: 2rem;
    max-width: 400px;
    background: rgba(255, 255, 255, 0.03);
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.1);

    h2 {
      color: #ffc807;
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }

    p {
      color: rgba(255, 255, 255, 0.7);
      font-size: 1rem;
      margin-bottom: 1.5rem;
    }

    .brand-button {
      background: #ffc807;
      color: #000;
      border: none;
      padding: 0.75rem 2.5rem;
      border-radius: 8px;
      font-weight: 600;
      font-size: 1rem;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        transform: translateY(-2px);
        filter: brightness(1.1);
        box-shadow: 0 4px 12px rgba(255, 200, 7, 0.25);
      }
    }
  }
}

.events-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  width: 100%;
}

.no-content-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  width: 100%;
  padding: 2rem;

  .no-content-card {
    background: rgba(25, 25, 25, 0.8);
    backdrop-filter: blur(10px);
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 3rem 2rem;
    text-align: center;
    width: 100%;
    max-width: 450px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);

    .icon-container {
      background: linear-gradient(
        135deg,
        rgba(255, 200, 7, 0.2),
        rgba(255, 150, 7, 0.3)
      );
      width: 80px;
      height: 80px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 1.5rem;

      .icon {
        color: #ffc807;
        font-size: 2.5rem;
      }
    }

    h3 {
      color: white;
      font-size: 1.75rem;
      font-weight: 600;
      margin-bottom: 1rem;
    }

    p {
      color: rgba(255, 255, 255, 0.7);
      font-size: 1.1rem;
      line-height: 1.6;
      margin-bottom: 2rem;
    }

    .primary-button {
      background: linear-gradient(135deg, #ffc807, #ff9607);
      border: none;
      padding: 0.85rem 2rem;
      border-radius: 50px;
      color: #000;
      font-weight: 600;
      font-size: 1rem;
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      transition: all 0.3s ease;
      box-shadow: 0 4px 12px rgba(255, 200, 7, 0.3);

      svg {
        font-size: 1.25rem;
      }

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 6px 16px rgba(255, 200, 7, 0.4);
      }

      &:active {
        transform: translateY(1px);
      }
    }
  }
}

// Responsive adjustments
@media (max-width: 768px) {
  .events {
    padding: 1rem;
    padding-top: 5rem;
    padding-bottom: 5rem;

    .events-header {
      h1 {
        font-size: 1.75rem;
      }
    }

    .events-grid {
      grid-template-columns: 1fr;
      gap: 1rem;
    }
  }

  .event-card {
  }

  .event-form-overlay {
    padding: 0;
  }

  .event-form {
    height: 100vh;
    max-height: none;
    border-radius: 0;

    .form-header {
      padding: 1rem;

      h2 {
        font-size: 1.25rem;
      }
    }

    form {
      padding: 1rem;

      .form-grid {
        grid-template-columns: 1fr;
        gap: 1rem;
      }
    }
  }
}
