*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: "Manrope", sans-serif;
  margin: 0;
}

$breakpoints: (
  smallest: 200px,
  supersmall: 400px,
  small: 480px,
  smallmid: 600px,
  midsmall: 800px,
  midmedium: 1000px,
  medium: 1200px,
  midlarge: 1500px,
  large: 2000px,
);
@mixin mq($key) {
  $size: map-get($breakpoints, $key);
  @media (min-width: $size) {
    @content;
  }
}

body {
  background-color: black;
}

.code {
  position: relative;
  display: grid;
  justify-content: center;

  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5rem;
  width: 100%;
  grid-template-columns: 1fr;
  overflow: hidden;
  justify-self: center;

  border-right: 1px solid #504f4f;
  border-bottom: 1px solid #504f4f;
  border-left: 1px solid #504f4f;
  background: linear-gradient(180deg, #1c1c1c 0%, #1c1c1c 50%, #000 100%);
  box-shadow: 0px 21px 17.3px 3px rgba(0, 0, 0, 0.25);

  &-wrapper {
    border-radius: 0rem 0rem 0.9375rem 0.9375rem;
    border: 1px solid #504f4f;
    background: linear-gradient(180deg, #1c1c1c 0%, #1c1c1c 50%, #000 100%);
    box-shadow: 0px 21px 17.3px 3px rgba(0, 0, 0, 0.25);
    z-index: 1;
    display: grid;
  }

  &-nav {
    margin-top: 1.88rem;
  }

  &-navigation {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  &-btn {
    justify-self: center;
    border-radius: 0.3125rem;
    background: linear-gradient(314deg, #d1a300 0%, #ffc807 100%);
    backdrop-filter: blur(8px);
    color: #000 !important;
    width: 100% !important;
    font-weight: 700;
  }
  &-select {
    // width: 20.375rem;
    height: 3.5rem;
    color: #fff;
    font-family: Manrope;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 5px;
    border: 2px solid #fff;
    background: #fff;
    backdrop-filter: blur(8px);
    padding-left: 1rem;
    text-align: center;

    border-radius: 0.3125rem;
    border: 2px solid #fff;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(8px);
  }

  &-count {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    position: relative;

    h4 {
      font-size: 0.9rem;
      color: rgba(255, 255, 255, 0.7);
      text-align: center;
      margin-bottom: 0.75rem;
      font-weight: 500;
      letter-spacing: 0.02rem;
    }

    &-number {
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      background: linear-gradient(314deg, #d1a300 0%, #ffc807 100%);
      box-shadow: 0 6px 15px rgba(209, 163, 0, 0.3);
      transition: all 0.3s ease;

      &::before {
        content: "";
        position: absolute;
        top: -5px;
        left: -5px;
        right: -5px;
        bottom: -5px;
        border-radius: 50%;
        background: linear-gradient(
          45deg,
          rgba(255, 200, 7, 0.5),
          rgba(255, 200, 7, 0)
        );
        z-index: -1;
        animation: pulse 2s infinite ease-in-out;
      }

      &:hover {
        transform: scale(1.05);
        box-shadow: 0 8px 20px rgba(209, 163, 0, 0.4);
      }

      p {
        color: #000;
        font-size: 2.2rem;
        font-weight: 700;
        line-height: 1;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
      }
    }
  }

  @include mq(medium) {
    width: 25rem;
  }

  &-title {
    color: rgba(255, 255, 255, 0.7);
    text-align: center;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.06rem;
    text-transform: uppercase;
    margin-top: 1.88rem;
  }

  &-subtitle {
    text-align: center;
    font-style: italic;
    margin-bottom: 1rem;
    margin-top: -1rem;
  }

  &-description {
    margin-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: italic;
    font-weight: 300;
    line-height: normal;
    margin-top: 1rem;
  }

  &-image {
    width: 80%;
  }
  &-logo {
    width: 5rem;
    height: 5rem;
    object-fit: cover;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 0.25rem;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);
    animation: 1.5s ease-out 0s 1 LogoRotate;
    border: 2px solid rgba(255, 200, 7, 0.5);
    z-index: 10;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: scale(1.05);
      box-shadow: 0 6px 20px rgba(0, 0, 0, 0.5);
    }
  }

  &-sub {
    z-index: 2;
    p {
      text-align: left;
      margin-top: 0.5rem;
      font-weight: 500;
      margin-bottom: 1rem;
    }
  }
}

.code-admin {
  color: #fff;
  display: grid;
  grid-template-rows: repeat(auto, 1fr);
  grid-gap: 0.5rem;
  width: 100% !important;

  justify-content: center;
  padding-bottom: 2rem;
  justify-self: center;
  border-radius: 5px;

  overflow: hidden;
  text-align: center;

  h1 {
    font-size: 1.5rem;
    z-index: 2;
    font-weight: 800;
  }

  p {
    font-size: 1rem;
    font-weight: 800;
    margin-top: 1rem;
  }

  input {
    width: 100% !important;
    height: 3.5rem;
    font-style: normal;
    font-size: 1rem;
    line-height: 1.375rem;

    border-radius: 5px;
    border: 2px solid #fff;
    background: #fff;
    backdrop-filter: blur(8px);
    padding-left: 1rem;
    text-align: center;
    border-radius: 0.3125rem;
    border: 2px solid #fff;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(8px);
    color: #fff;

    &::placeholder {
      font-family: Manrope;
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: #fff;
    }
  }

  button {
    width: 20.375rem !important;
    height: 3.5rem;
    font-style: normal;
    font-weight: 800;
    font-size: 1rem;
    line-height: 1.375rem;
    color: white;
    margin-top: 0.25rem;
    z-index: 1;
    background-color: #000;
    border: none;
    border-radius: 0.25rem;
    border: 2px solid #fff;
  }
}

.customerName {
  z-index: 1;
}

#email {
  z-index: 1;
}

.guestcode-number {
  width: 12rem !important;
}

.checkList {
  display: grid;
  grid-template-columns: repeat(2, minmax(min-content, max-content));
  grid-gap: 1rem;
  z-index: 5;

  &-single {
    display: grid;
    grid-template-columns: repeat(2, minmax(min-content, max-content));
    align-items: center;
    grid-gap: 0.5rem;

    input {
      width: 1rem;
    }
  }
}

.code {
  &-wrapper {
    max-width: 25rem; // More specific width control
  }

  &-admin {
    width: 100% !important;
    padding: 1rem 0 2rem;

    // New input group styling
    .input-group {
      display: grid;
      gap: 0.75rem;
      margin-bottom: 1rem;
      width: 100%;

      label {
        color: rgba(255, 255, 255, 0.7);
        font-size: 0.875rem;
        font-weight: 500;
      }
    }

    // Improved input/select styling
    input,
    select,
    .code-select {
      width: 100%;
      height: 3.5rem;
      padding: 0 1rem;
      border-radius: 0.3125rem;
      border: 2px solid rgba(255, 255, 255, 0.2);
      background: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(8px);
      color: #fff;
      font-size: 1rem;
      font-weight: 500;
      text-align: center;
      transition: all 0.2s ease;
      margin-top: 0.5rem;
      &:focus {
        border-color: #ffc807;
        outline: none;
      }

      &::placeholder {
        color: rgba(255, 255, 255, 0.5);
        font-weight: 500;
      }
    }

    // Radio group styling
    .radio-group {
      display: grid;
      gap: 0.75rem;
      margin: 1rem 0;

      label {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.75rem;
        border-radius: 0.3125rem;
        border: 2px solid rgba(255, 255, 255, 0.2);
        background: rgba(0, 0, 0, 0.5);
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
          border-color: rgba(255, 255, 255, 0.4);
        }

        input[type="radio"] {
          width: 1.25rem !important;
          height: 1.25rem;
          margin: 0;
        }
      }
    }
  }

  // Improved button styling
  &-btn {
    width: 100% !important;
    height: 3.5rem;
    margin-top: 1.5rem;
    border-radius: 0.3125rem;
    background: linear-gradient(314deg, #d1a300 0%, #ffc807 100%);
    color: #000 !important;
    font-weight: 700;
    font-size: 1rem;
    border: none;
    cursor: pointer;
    transition: transform 0.2s ease;

    &:hover {
      transform: translateY(-1px);
    }

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }

  // Counter styling
  &-count {
    text-align: center;
    margin-top: -0.75rem;
    h4 {
      font-size: 0.875rem;
      color: rgba(255, 255, 255, 0.7);
      margin-bottom: 1rem;
    }

    &-number {
      width: 4rem;
      height: 4rem;
      margin: 0 auto;
      border-radius: 50%;
      background: #ffc807;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        color: #000;
        font-size: 2rem;
        font-weight: 700;
        line-height: 1;
      }
    }
  }
}

.brand-logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0 2rem;
  z-index: 10;
  position: relative;
  margin-top: 4rem !important;
  &::before {
    content: "";
    position: absolute;
    width: 7rem;
    height: 7rem;
    background: radial-gradient(
      circle,
      rgba(255, 200, 7, 0.2) 0%,
      rgba(255, 200, 7, 0) 70%
    );
    border-radius: 50%;
    z-index: -1;
    animation: pulse 3s infinite ease-in-out;
  }

  .brand-name {
    color: rgba(255, 255, 255, 0.9);
    font-size: 1.2rem;
    margin-top: 0.75rem;
    font-weight: 600;
    text-align: center;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }
}

.code-logo-placeholder {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background: linear-gradient(314deg, #d1a300 0%, #ffc807 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: 700;
  color: #000;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);
  animation: 1.5s ease-out 0s 1 LogoRotate;
  border: 2px solid rgba(255, 200, 7, 0.5);
  z-index: 10;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.5);
  }
}

@keyframes LogoRotate {
  0% {
    transform: scale(0.7);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.8;
  }
  100% {
    transform: scale(0.95);
    opacity: 0.5;
  }
}

.code-type-selector {
  margin: 0.5rem auto 1.5rem;
  width: 100%;

  .type-tabs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5rem;
    background: rgba(30, 30, 30, 0.5);
    border-radius: 8px;
    padding: 0.5rem;
    margin: 0 auto;

    .type-tab {
      flex: 0 1 auto;
      padding: 0.6rem 1rem;
      border-radius: 6px;
      cursor: pointer;
      transition: all 0.2s ease;
      position: relative;

      &:hover {
        background: rgba(50, 50, 50, 0.8);
      }

      &.selected {
        background: linear-gradient(314deg, #d1a300 0%, #ffc807 100%);
        box-shadow: 0 2px 10px rgba(209, 163, 0, 0.3);
        transform: translateY(-1px);

        .tab-name {
          color: #000;
          font-weight: 700;
        }
      }

      .tab-name {
        font-weight: 600;
        font-size: 0.9rem;
        color: #fff;
        text-align: center;
        white-space: nowrap;

        @media (max-width: 480px) {
          font-size: 0.8rem;
        }
      }

      // Remove the tab-limit class styles as we're not showing this anymore
    }
  }
}

.code-generator {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 1rem;
  background: rgba(21, 21, 21, 0.8);
  border-radius: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    padding: 0.5rem;
    border-radius: 0;
    border: none;
    box-shadow: none;
  }

  &-container {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: 1rem;

    @media (max-width: 768px) {
      padding: 0.5rem;
    }
  }

  .code-form {
    width: 100%;
    max-width: 100%;
    margin: 1rem 0;

    .input-container {
      margin-bottom: 1rem;
      width: 100%;

      input,
      select {
        width: 100%;
        padding: 1rem;
        background: rgba(0, 0, 0, 0.3);
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 0.75rem;
        color: white;
        font-size: 1rem;
        transition: all 0.2s ease;

        &:focus {
          outline: none;
          border-color: rgba(255, 200, 7, 0.5);
          box-shadow: 0 0 0 2px rgba(255, 200, 7, 0.1);
        }

        &::placeholder {
          color: rgba(255, 255, 255, 0.5);
        }

        @media (max-width: 768px) {
          padding: 0.875rem;
          font-size: 0.95rem;
          border-radius: 0.5rem;
        }
      }

      select {
        appearance: none;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='white'%3e%3cpath d='M7 10l5 5 5-5z'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right 1rem center;
        background-size: 1.5rem;
        padding-right: 3rem;
      }
    }
  }

  .counter-and-button {
    width: 100%;
    max-width: 100%;
    margin-top: 1rem;

    @media (max-width: 768px) {
      margin-top: 0.5rem;
    }
  }

  .code-btn {
    width: 100%;
    padding: 1rem;
    background: linear-gradient(45deg, #d1a300, #ffc807);
    border: none;
    border-radius: 0.75rem;
    color: #000;
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-top: 1rem;
    position: relative;
    overflow: hidden;

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
      &::after {
        display: none;
      }
    }

    &:hover:not(:disabled) {
      transform: translateY(-2px);
      box-shadow: 0 4px 15px rgba(209, 163, 0, 0.3);
    }
  }

  .code-count {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem 0;
    text-align: center;

    h4 {
      font-size: 0.875rem;
      color: rgba(255, 255, 255, 0.7);
      text-transform: uppercase;
      letter-spacing: 0.1em;
      margin-bottom: 0.5rem;
    }

    .code-count-number {
      width: 6rem;
      height: 6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(145deg, #1a1a1a, #2a2a2a);
      border-radius: 50%;
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
      transition: all 0.3s ease;
      position: relative;
      margin: 0.5rem 0;

      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: radial-gradient(
          circle at center,
          rgba(255, 200, 7, 0.1),
          transparent 70%
        );
        animation: pulse 2s infinite;
      }

      &:hover {
        transform: scale(1.05);
        box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
      }

      p {
        font-size: 2rem;
        font-weight: 700;
        color: white;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
        line-height: 1;
        margin: 0;
      }
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      opacity: 0.5;
    }
    50% {
      transform: scale(1.05);
      opacity: 0.8;
    }
    100% {
      transform: scale(0.95);
      opacity: 0.5;
    }
  }
}

// Improve header container styling
.header-container {
  display: flex;
  flex-direction: column; // Stack items vertically on mobile
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  // For larger screens, adjust to horizontal layout
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }

  .brand-logo-container {
    margin-bottom: 1rem;

    @media (min-width: 768px) {
      margin-bottom: 0;
    }
  }

  .code-counter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: 5rem;
      height: 5rem;
      background: radial-gradient(
        circle,
        rgba(255, 200, 7, 0.15) 0%,
        rgba(255, 200, 7, 0) 70%
      );
      border-radius: 50%;
      z-index: -1;
      animation: pulse 3s infinite ease-in-out;
    }

    .counter-label {
      font-size: 0.9rem;
      color: rgba(255, 255, 255, 0.7);
      margin-bottom: 0.5rem;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.05rem;
    }

    .counter-value {
      font-size: 2.5rem;
      font-weight: 700;
      color: #ffc807;
      text-shadow: 0 2px 10px rgba(255, 200, 7, 0.3);
      transition: all 0.3s ease;

      &:hover {
        transform: scale(1.05);
        text-shadow: 0 4px 15px rgba(255, 200, 7, 0.4);
      }
    }
  }
}

// View toggle buttons
.view-toggle-container {
  display: flex;
  justify-content: center;
  margin: 1rem auto;
  background: rgba(30, 30, 30, 0.5);
  border-radius: 8px;
  padding: 0.25rem;
  width: fit-content;

  .view-toggle-btn {
    padding: 0.6rem 1.5rem;
    border: none;
    background: transparent;
    color: rgba(255, 255, 255, 0.7);
    font-weight: 600;
    font-size: 0.9rem;
    cursor: pointer;
    border-radius: 6px;
    transition: all 0.2s ease;

    &:hover {
      color: #fff;
      background: rgba(50, 50, 50, 0.8);
    }

    &.active {
      background: linear-gradient(314deg, #d1a300 0%, #ffc807 100%);
      color: #000;
    }
  }
}

// Code management container
.code-management-container {
  padding: 0 1rem;
  margin-bottom: 2rem;
  max-height: 70vh;
  overflow-y: auto;

  // Custom scrollbar
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 200, 7, 0.3);
    border-radius: 4px;

    &:hover {
      background: rgba(255, 200, 7, 0.5);
    }
  }
}

// Update the counter container styling
.counter-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 1.2rem;
  border-radius: 0.75rem;
  background-color: rgba(15, 15, 15, 0.9);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.5),
    inset 0 0 10px rgba(255, 200, 7, 0.12), 0 0 0 1px rgba(255, 255, 255, 0.08);
  margin: 0.5rem auto 1.5rem;
  max-width: 160px;
  border: 1px solid rgba(255, 200, 7, 0.25);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  transform-style: preserve-3d;
  perspective: 800px;

  // Outer frame effect
  &::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-radius: 0.85rem;
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.05) 15%,
      rgba(0, 0, 0, 0.2) 100%
    );
    z-index: 0;
    pointer-events: none;
    box-shadow: inset 0 0 2px rgba(255, 255, 255, 0.2);
  }

  // Glass overlay effect
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(255, 255, 255, 0.05) 40%,
      rgba(0, 0, 0, 0.1) 100%
    );
    border-radius: 0.75rem;
    z-index: 2;
    pointer-events: none;
    box-shadow: inset 0 0 15px rgba(255, 255, 255, 0.05),
      inset 0 0 5px rgba(255, 200, 7, 0.05);

    // Subtle glass texture
    background-image: repeating-linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.03),
      rgba(255, 255, 255, 0.03) 1px,
      transparent 1px,
      transparent 6px
    );
  }

  // Top light reflection
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 35%;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0.05) 60%,
      transparent
    );
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
    z-index: 1;
    opacity: 0.8;
  }

  // Bottom edge shadow
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 10%;
    right: 10%;
    height: 10%;
    background: rgba(0, 0, 0, 0.2);
    filter: blur(4px);
    z-index: 0;
  }

  &:hover {
    transform: translateY(-3px) scale(1.03);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.6),
      inset 0 0 15px rgba(255, 200, 7, 0.15),
      0 0 0 1px rgba(255, 255, 255, 0.12);

    .counter-value {
      text-shadow: 0 0 12px rgba(255, 200, 7, 0.9),
        0 0 20px rgba(255, 200, 7, 0.4);
    }
  }

  // Inner display area
  &:before {
    content: "";
    position: absolute;
    top: 25%;
    left: 10%;
    right: 10%;
    bottom: 15%;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 0.25rem;
    z-index: 1;
    box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.5),
      inset 0 0 4px rgba(255, 200, 7, 0.1);
  }

  .counter-label {
    font-size: 0.7rem;
    font-weight: 700;
    color: rgba(255, 200, 7, 0.9);
    margin-bottom: 0.25rem;
    text-transform: uppercase;
    letter-spacing: 0.12rem;
    text-shadow: 0 0 5px rgba(255, 200, 7, 0.5);
    position: relative;
    z-index: 3;
  }

  .counter-value {
    font-size: 2.5rem;
    font-weight: 700;
    color: #ffc807;
    text-shadow: 0 0 10px rgba(255, 200, 7, 0.7),
      0 0 20px rgba(255, 200, 7, 0.3);
    font-family: "Digital-7", "DS-Digital", monospace;
    transition: all 0.3s ease;
    position: relative;
    z-index: 3;
    line-height: 1;
    transform: translateZ(5px);

    // Retro LCD segments effect
    -webkit-text-stroke: 0.5px rgba(255, 200, 7, 0.5);
    letter-spacing: 0.05em;

    // Add subtle LCD segment effect
    background-image: linear-gradient(
      0deg,
      rgba(255, 200, 7, 0.9) 0%,
      rgba(255, 220, 100, 1) 50%,
      rgba(255, 200, 7, 0.9) 100%
    );
    background-clip: text;
    -webkit-background-clip: text;

    // Glow animation
    animation: digitalGlow 2s infinite alternate;
  }
}

// Digital glow animation
@keyframes digitalGlow {
  0% {
    text-shadow: 0 0 5px rgba(255, 200, 7, 0.7), 0 0 10px rgba(255, 200, 7, 0.3);
    opacity: 0.9;
    filter: brightness(0.95);
  }
  50% {
    text-shadow: 0 0 7px rgba(255, 200, 7, 0.8), 0 0 12px rgba(255, 200, 7, 0.4);
    opacity: 0.95;
    filter: brightness(1);
  }
  100% {
    text-shadow: 0 0 8px rgba(255, 200, 7, 0.9), 0 0 15px rgba(255, 200, 7, 0.5);
    opacity: 1;
    filter: brightness(1.05);
  }
}
