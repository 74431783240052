@import "../../_variables";

// Avatar specific variables
$avatar-background: rgba(26, 26, 26, 0.95);
$avatar-border: rgba(255, 255, 255, 0.1);
$avatar-accent: #ffd700;
$avatar-glow: rgba(255, 215, 0, 0.3);

.avatar-upload {
  position: relative;
  width: 72px;
  height: 72px;
  margin-top: -8px;

  .avatar-display {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.05);
    box-shadow: 0 0 10px rgba(255, 200, 7, 0.2);
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.05);
      box-shadow: 0 0 15px rgba(255, 200, 7, 0.3);
      border-color: #ffc807;

      .avatar-overlay {
        opacity: 1;
      }
    }

    .avatar-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }

    .avatar-placeholder {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(145deg, #1a1a1a, #2a2a2a);

      .upload-icon {
        font-size: 1.8rem;
        color: #ffc807;
        opacity: 0.8;
      }
    }

    .avatar-overlay {
      position: absolute;
      inset: 0;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: opacity 0.2s ease;
      border-radius: 50%;

      .edit-icon {
        font-size: 1.5rem;
        color: #ffc807;
        filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
      }
    }
  }
}

.avatar-modal-container {
  position: fixed;
  inset: 0;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none; // Let clicks pass through the container
  padding: 20px;
}

.avatar-modal-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(8px);
  z-index: 9999;
  pointer-events: auto; // Catch clicks on the overlay
}

.avatar-modal-content {
  position: relative;
  width: 90%;
  max-width: 400px;
  height: auto;
  min-height: 360px;
  max-height: 90vh;
  background: #151515;
  border-radius: 16px;
  overflow: hidden;
  z-index: 9999;
  border: 1px solid rgba(255, 200, 7, 0.1);
  display: flex;
  flex-direction: column;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.4);
  pointer-events: auto; // Catch clicks on the content

  .upload-dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px dashed rgba(255, 200, 7, 0.3);
    margin: 1rem;
    border-radius: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    background: rgba(255, 200, 7, 0.02);
    padding: 1.5rem;
    gap: 0.75rem;

    &:hover,
    &.active {
      border-color: #ffc807;
      background: rgba(255, 200, 7, 0.05);
      transform: scale(0.99);
    }

    .upload-icon {
      font-size: 3rem;
      color: #ffc807;
      opacity: 0.8;
      margin-bottom: 0.5rem;
    }

    .upload-text {
      color: rgba(255, 255, 255, 0.9);
      font-size: 1.1rem;
      margin: 0;
      text-align: center;
    }

    .upload-hint {
      color: rgba(255, 255, 255, 0.5);
      font-size: 0.9rem;
      margin: 0;
      text-align: center;
    }
  }

  .crop-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #000;
    position: relative;
    overflow: hidden;

    .crop-area {
      position: relative;
      flex: 1;
      height: 290px;
      overflow: hidden;

      // Fix for react-easy-crop container
      & > div {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
      }

      // Ensure the cropper stays within bounds
      .reactEasyCrop_Container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        height: 100%;
        width: 100%;
      }

      // Improve the crop box appearance
      .reactEasyCrop_CropArea {
        border: 2px solid #ffc807;
        box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.7);
        color: rgba(255, 200, 7, 0.5);
      }
    }

    .crop-controls {
      display: flex;
      flex-direction: column;
      padding: 1rem;
      background: #151515;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      z-index: 10;

      // Slider container
      .zoom-slider {
        -webkit-appearance: none;
        width: 100%;
        height: 6px;
        background: rgba(255, 200, 7, 0.15);
        outline: none;
        border-radius: 3px;
        margin: 0 0 1rem 0;

        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          width: 24px;
          height: 24px;
          background: #ffc807;
          border-radius: 50%;
          cursor: pointer;
          transition: all 0.2s ease;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);

          &:hover {
            transform: scale(1.1);
          }
        }

        &::-moz-range-thumb {
          width: 24px;
          height: 24px;
          background: #ffc807;
          border-radius: 50%;
          cursor: pointer;
          transition: all 0.2s ease;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
          border: none;

          &:hover {
            transform: scale(1.1);
          }
        }
      }

      // Action buttons container
      .action-buttons {
        display: grid;
        grid-template-columns: repeat(2, minmax(min-content, max-content));
        align-items: center;
        gap: 1rem;
        width: 100%;
        margin-top: 0.5rem;
        justify-content: center;
        button {
          width: 30px;
          height: 30px;
          border-radius: 12px;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: all 0.2s ease;
          font-size: 2rem;

          &.cancel-button {
            background: rgba(255, 255, 255, 0.15);
            color: rgba(255, 255, 255, 0.9);

            &:hover {
              background: rgba(255, 255, 255, 0.2);
              transform: scale(1.05);
            }
          }

          &.save-button {
            background: rgba(255, 200, 7, 0.15);
            color: #ffc807;

            &:hover {
              background: rgba(255, 200, 7, 0.25);
              transform: scale(1.05);
            }
          }

          &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
            transform: none;
          }
        }
      }
    }
  }
}

.loading-spinner {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 200, 7, 0.1);
  border-top-color: #ffc807;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

// Mobile-specific adjustments
@media (max-width: 768px) {
  .avatar-modal-content {
    width: 95%;
    height: auto;
    min-height: 280px;
    max-height: 90vh;
    margin: 0;

    .upload-dropzone {
      margin: 0.75rem;
      padding: 1rem;
    }

    .crop-container {
      max-height: 400px;

      .crop-area {
        height: 250px;
        max-height: 250px;
      }

      .crop-controls {
        padding: 0.75rem;

        .zoom-slider {
          height: 5px;
          margin-bottom: 0.75rem;

          &::-webkit-slider-thumb {
            width: 22px;
            height: 22px;
          }

          &::-moz-range-thumb {
            width: 22px;
            height: 22px;
          }
        }

        .action-buttons {
          gap: 0.75rem;

          button {
            width: 50px;
            height: 50px;
            font-size: 1.8rem;
          }
        }
      }
    }
  }
}
