@import "../../../_variables";

// Enhanced color variables
$primary-gold: #ffc807;
$secondary-gold: #d1a300;
$dark-gold: #a58300;
$light-gold: #ffdf80;
$highlight-gold: #ffe680;
$black: #000000;
$dark-gray: #1c1c1c;
$darker-gray: #0f0f0f;
$deep-black: #050505;

// Animation durations
$pulse-duration: 8s;

// Loading spinner animation
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.forgot-password {
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(180deg, $darker-gray 0%, $black 100%);
  color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-x: hidden;

  // Background glow
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100vh;
    background: radial-gradient(
      circle at center,
      rgba($primary-gold, 0.15) 0%,
      rgba(0, 0, 0, 0) 70%
    );
    z-index: 0;
    pointer-events: none;
  }

  .forgot-password-container {
    width: 100%;
    max-width: 450px;
    margin: 8rem auto 2rem;
    padding: 2rem;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
    z-index: 1;

    // Subtle inner glow
    position: relative;
    overflow: hidden;

    &::after {
      content: "";
      position: absolute;
      top: -50%;
      left: -50%;
      width: 200%;
      height: 200%;
      background: radial-gradient(
        circle at center,
        rgba($primary-gold, 0.1) 0%,
        transparent 70%
      );
      opacity: 0.5;
      pointer-events: none;
      z-index: -1;
    }

    .forgot-password-title {
      font-size: 2rem;
      font-weight: 600;
      margin-bottom: 1rem;
      text-align: center;
      background: linear-gradient(to right, #fff 0%, $primary-gold 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.5));
    }

    .forgot-password-subtitle {
      text-align: center;
      color: rgba(255, 255, 255, 0.8);
      margin-bottom: 2rem;
      font-size: 0.95rem;
      line-height: 1.5;
    }

    .forgot-password-form {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      .input-group {
        position: relative;

        input {
          width: 100%;
          padding: 1rem;
          font-size: 1rem;
          background: rgba(0, 0, 0, 0.3);
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 5px;
          color: #fff;
          transition: all 0.3s ease;

          &:focus {
            outline: none;
            border-color: $primary-gold;
            background: rgba(0, 0, 0, 0.4);
            box-shadow: 0 0 10px rgba($primary-gold, 0.2);
          }

          &::placeholder {
            color: rgba(255, 255, 255, 0.5);
          }
        }
      }

      .forgot-password-button {
        margin-top: 1rem;
        padding: 1rem;
        font-size: 1rem;
        font-weight: 600;
        background: linear-gradient(45deg, $dark-gold, $primary-gold);
        border: none;
        border-radius: 5px;
        color: #000;
        cursor: pointer;
        transition: all 0.3s ease;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;

        &:hover:not(:disabled) {
          transform: translateY(-2px);
          box-shadow: 0 4px 15px rgba($primary-gold, 0.3);
          background: linear-gradient(45deg, $primary-gold, $light-gold);
        }

        &:active:not(:disabled) {
          transform: translateY(0);
        }

        &:disabled {
          opacity: 0.6;
          cursor: not-allowed;
        }

        .loading-spinner {
          display: inline-block;
          width: 18px;
          height: 18px;
          border: 2px solid rgba(0, 0, 0, 0.3);
          border-radius: 50%;
          border-top-color: #000;
          animation: spin 1s linear infinite;
        }
      }

      // Auth links styling
      .auth-links {
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;
        width: 100%;
        padding: 0 0.5rem;

        p {
          font-size: 0.9rem;
          color: rgba(255, 255, 255, 0.7);
          margin: 0;

          span {
            color: $primary-gold;
            cursor: pointer;
            transition: all 0.2s ease;

            &:hover {
              color: $light-gold;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  // Success message styling
  .reset-sent {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1rem;

    .success-icon {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      background: $primary-gold;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 1.5rem;
      box-shadow: 0 0 15px rgba($primary-gold, 0.5);
    }

    h2 {
      font-size: 1.8rem;
      margin-bottom: 1rem;
      background: linear-gradient(to right, #fff 0%, $primary-gold 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    p {
      color: rgba(255, 255, 255, 0.8);
      margin-bottom: 2rem;
      font-size: 1rem;
      line-height: 1.5;
    }

    .back-to-login {
      padding: 0.8rem 1.5rem;
      font-size: 1rem;
      font-weight: 600;
      background: linear-gradient(45deg, $dark-gold, $primary-gold);
      border: none;
      border-radius: 5px;
      color: #000;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 15px rgba($primary-gold, 0.3);
        background: linear-gradient(45deg, $primary-gold, $light-gold);
      }
    }
  }
}

// Responsive styling
@media (max-width: 768px) {
  .forgot-password {
    .forgot-password-container {
      margin-top: 6rem;
      padding: 1.5rem;
      width: 90%;

      .forgot-password-title {
        font-size: 1.7rem;
      }
    }
  }
}
