@import "../../_variables";

.dropfiles {
  display: grid;
  justify-content: center;
  justify-items: center;
  padding: 2rem;
  &-logo {
    width: 5rem;
    margin-top: 5rem;
  }

  &-text {
    color: #fff;
    text-align: center;
    font-family: Manrope;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 700;
    width: 19ch;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 2rem;
    color: #fff;
    font-family: Manrope;

    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    letter-spacing: 4.2px;
    text-transform: uppercase;
    margin-top: 3rem;
  }

  &-submit {
    width: 100%;
    height: 3rem;
    color: #000;
    font-family: Manrope;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background-color: #fff;
    border: 2px solid #fff;
    margin-top: 1rem;
    border-radius: 5px;
  }
}

.dropfiles-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  // You can adjust the size of the icon container if needed
  width: 100px;
  height: 100px;

  label {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      // Adjust as necessary to fit your design
      max-width: 100%;
      max-height: 100%;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.pulse {
  animation: pulse 2s infinite;
}
