@import "../../_variables";
@import "../../_animations";
@import "../../_glassy";

.dashboard {
  display: grid;
  align-self: center;
  height: 100%;
  position: relative;

  // Golden Arrow Guide for new users
  .golden-arrow-guide {
    position: fixed;
    top: 15px;
    right: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 90;
    pointer-events: none;

    .arrow-text {
      color: #ffc807;
      font-size: 10px;
      font-weight: 500;
      margin-top: 2px;
    }

    .arrow-icon {
      font-size: 18px;
      color: #ffc807;
      filter: drop-shadow(0 0 3px rgba(255, 200, 7, 0.5));
    }
  }

  @keyframes pulse {
    0% {
      transform: translateY(0);
      opacity: 0.8;
    }
    50% {
      transform: translateY(-10px);
      opacity: 1;
    }
    100% {
      transform: translateY(0);
      opacity: 0.8;
    }
  }

  &-wrapper {
    display: grid;
    text-align: center;
    align-self: center;
    border-radius: 0rem 0rem 0.9375rem 0.9375rem;
    border: 1px solid #504f4f;
    background: linear-gradient(180deg, #1c1c1c 0%, #1c1c1c 50%, #000 100%);
    box-shadow: 0px 21px 17.3px 3px rgba(0, 0, 0, 0.25);
    z-index: 1;
  }

  &-navigation {
    border-radius: 0rem 0rem 0.9375rem 0.9375rem;
    background: #151515;
    box-shadow: 0px 4px 4px 0px rgba(104, 104, 104, 0.25);
    min-height: 5.5rem;
    // @include grid(5);
    width: 100%;

    display: grid;
    grid-template-columns: 0.15fr 0.15fr 0.4fr 0.15fr 0.15fr;
    justify-items: center;
    align-items: center;

    &-title {
      color: rgba(255, 255, 255, 0.7);
      text-align: center;
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.06rem;
      text-transform: uppercase;
      font-family: Poppins;
    }

    &-inbox {
      width: 1.5rem;
    }
  }

  &-logo {
    width: 5rem;
    justify-self: center;
    margin-top: 2rem;
  }

  &-logout {
    @include grid(2);
    grid-gap: 1rem;
    margin-top: 3rem;
    padding-left: 3rem;
    padding-bottom: 1rem;
    &-button {
      background: none;
      border: none;
    }
  }
}

// Glassy Stats Cards Example
.dashboard-stats {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  margin: 1.5rem 0;

  .stat-card {
    @include glassy-base;
    padding: 1.25rem;

    .stat-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(255, 200, 7, 0.9);
      margin-bottom: 0.75rem;
      font-size: 1.75rem;
    }

    .stat-value {
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 0.25rem;
      color: white;
    }

    .stat-label {
      font-size: 0.85rem;
      color: rgba(255, 255, 255, 0.7);
    }

    // Premium stat card
    &.premium {
      @include glassy-premium;
    }
  }
}

// Glassy Action Button
.glassy-action-btn {
  @include glassy-base;
  @include glassy-button;
  margin: 1rem 0;
  font-size: 0.9rem;

  svg {
    margin-right: 8px;
  }
}
