@import "../../_variables";

.app-navigation {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: #151515;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  .nav-content {
    max-width: 1440px;
    margin: 0 auto;

    padding: 0.75rem 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .nav-left {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .nav-brand {
        font-size: 1.25rem;
        font-weight: 700;
        color: #ffc807;
        cursor: pointer;
        transition: all 0.2s ease;
        padding: 0.5rem;
        border-radius: 0.5rem;

        &:hover {
          background: rgba(255, 200, 7, 0.1);
        }
      }
    }

    .nav-right {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    .nav-icon-wrapper {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;
      color: rgba(255, 255, 255, 0.7);
      transition: all 0.2s ease;
      position: relative;

      &:hover {
        background: rgba(255, 255, 255, 0.1);
        color: #fff;
      }

      &.has-notification {
        color: #ffc807;

        &:hover {
          color: #ffc807;
        }

        .notification-count {
          position: absolute;
          top: -2px;
          right: -2px;
          background: #ff3b3b;
          color: white;
          font-size: 0.7rem;
          font-weight: 600;
          min-width: 18px;
          height: 18px;
          border-radius: 9px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 2px solid #151515;
        }
      }

      .icon {
        font-size: 1.25rem;
      }
    }
  }
}

@media (max-width: 768px) {
  .app-navigation {
    .nav-content {
      padding: 0.75rem 1rem;

      .nav-left {
        .nav-brand {
          font-size: 1.1rem;
        }
      }

      .nav-icon-wrapper {
        width: 32px;
        height: 32px;

        .icon {
          font-size: 1.1rem;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .app-navigation {
    .nav-content {
      padding: 0.5rem 0.75rem;

      .nav-left {
        .nav-brand {
          font-size: 1rem;
        }
      }

      .nav-right {
        gap: 0.25rem;
      }

      .nav-icon-wrapper {
        width: 2rem;
        height: 2rem;

        .icon {
          font-size: 1rem;
        }
      }
    }
  }
}
