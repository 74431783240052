.progressive-image {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    &.blur {
      filter: blur(10px);
    }
  }

  &.loading {
    background: #f0f0f0;
    animation: pulse 1.5s infinite;
  }

  .error {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #ff4444;
    background: #f8f8f8;
    padding: 1rem;
    text-align: center;
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
