@import "../../_variables";

// Define CSS variable for dynamic primary color with fallback
:root {
  --ticket-primary-color: #{$primary-color};
}

// Animation for loading spinner
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// Animation for ticket hover
@keyframes subtle-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(0);
  }
}

// Button press animation
@keyframes button-press {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(2px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-1px);
  }
  100% {
    transform: translateY(0);
  }
}

// Main wrapper - no borders, padding, or margins
.tickets-wrapper {
  width: 100%;
  margin: 1.5rem 0;
  padding: 1.5rem;
  background: rgba(18, 18, 18, 0.7);
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;

  // Paper texture overlay
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='200' height='200' viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noise'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.75' numOctaves='2' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noise)' opacity='0.15'/%3E%3C/svg%3E"),
      linear-gradient(to right, rgba(255, 255, 255, 0.03) 1px, transparent 1px),
      linear-gradient(to bottom, rgba(255, 255, 255, 0.03) 1px, transparent 1px);
    background-size: 200px 200px, 15px 15px, 15px 15px;
    border-radius: 12px;
    pointer-events: none;
    z-index: 1;
    opacity: 0.7;
    mix-blend-mode: overlay;
  }

  &.seamless {
    background: transparent;
    padding: 0;
    box-shadow: none;

    &::before {
      display: none;
    }
  }

  // All content should be above the texture overlay
  & > * {
    position: relative;
    z-index: 2;
  }
}

.tickets-container {
  width: 100%;
}

.tickets-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #fff;
  position: relative;
  display: inline-block;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

  &::after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 60px;
    height: 3px;
    background: linear-gradient(
      to right,
      var(--ticket-primary-color),
      transparent
    );
    border-radius: 3px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }
}

.tickets-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.tickets-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  color: rgba(255, 255, 255, 0.7);

  .spinner {
    margin-bottom: 1rem;
    border: 3px solid rgba(255, 255, 255, 0.1);
    border-top: 3px solid var(--ticket-primary-color);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
  }
}

.no-tickets {
  text-align: center;
  padding: 2rem;
  color: rgba(255, 255, 255, 0.7);
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;

  .retry-button {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background: rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    color: var(--ticket-primary-color);
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background: rgba(0, 0, 0, 0.4);
      transform: translateY(-2px);
    }
  }
}

.ticket-item {
  --ticket-accent-color: var(--ticket-primary-color);
  position: relative;
  overflow: visible !important;
  background: linear-gradient(
    145deg,
    rgba(30, 30, 30, 0.9),
    rgba(20, 20, 20, 0.95)
  );
  border-radius: 12px;
  padding: 1.5rem 1.5rem 1.5rem 2.5rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15),
    inset 0 1px 1px rgba(255, 255, 255, 0.05);
  transition: all 0.3s ease;
  overflow: hidden;
  transform-style: preserve-3d;
  perspective: 1000px;
  border-left: 15px solid var(--ticket-accent-color);

  // Remove all conflicting pseudo-elements
  &:before,
  &:after {
    display: none;
  }

  // Ticket perforation pattern on the left
  &::after {
    content: "";
    position: absolute;
    top: 10px;
    bottom: 10px;
    left: 15px;
    width: 2px;
    background-image: linear-gradient(
      to bottom,
      transparent 0px,
      transparent 5px,
      rgba(255, 255, 255, 0.5) 5px,
      rgba(255, 255, 255, 0.5) 7px
    );
    background-size: 2px 15px;
    background-repeat: repeat-y;
    z-index: 3;
    display: block !important;
  }

  // Animation only when active (quantity > 0)
  &.active {
    animation: float 6s ease-in-out infinite;
    transform: translateY(-1px) rotateX(2deg);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2),
      0 10px 30px -10px rgba(0, 0, 0, 0.3);
  }

  &:hover {
    transform: translateY(-1px) rotateX(2deg);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2),
      0 10px 30px -10px rgba(0, 0, 0, 0.3);
  }

  // All content should be above the textures
  & > * {
    position: relative;
    z-index: 2;
  }

  // Countdown badge
  .ticket-countdown {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: linear-gradient(135deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9));
    color: var(--ticket-accent-color);
    padding: 0.4rem 0.7rem;
    border-radius: 20px;
    font-size: 0.8rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.1);
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);

    // Metallic effect
    background-image: linear-gradient(
      135deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(30, 30, 30, 0.9) 50%,
      rgba(0, 0, 0, 0.8) 100%
    );

    svg {
      font-size: 1rem;
      filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.5));
    }
  }

  // Discount badge - adjusted position
  .ticket-discount {
    position: absolute;
    top: -0.8rem;
    left: 3rem;
    background-color: var(--ticket-accent-color);
    color: #000;
    padding: 0.4rem 0.9rem;
    font-size: 0.8rem;
    font-weight: 700;
    clip-path: polygon(0 0, 100% 0, 90% 100%, 0 100%);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.3);
    z-index: 10;
    min-width: 80px;
    text-align: center;

    // Add a subtle pattern to the discount badge
    background-image: repeating-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.1) 0px,
      rgba(255, 255, 255, 0.1) 1px,
      transparent 1px,
      transparent 3px
    );
  }

  // Ticket header
  .ticket-header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
    padding-top: 0.5rem;

    svg {
      font-size: 1.3rem;
      color: var(--ticket-accent-color);
      filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.5));
    }

    h4 {
      margin: 0;
      font-size: 1.2rem;
      font-weight: 600;
      color: #fff;
      text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
      letter-spacing: 0.5px;
    }
  }

  // Ticket price
  .ticket-price {
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 0.8rem;
    color: var(--ticket-accent-color);
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    position: relative;
    display: inline-block;

    // Add a subtle underline
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -4px;
      width: 100%;
      height: 2px;
      background: var(--ticket-accent-color);
      opacity: 0.5;
      border-radius: 2px;
    }

    // Original price (crossed out)
    .original-price {
      position: absolute;
      top: -0.8rem;
      right: -0.5rem;
      font-size: 1rem;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.7);
      text-decoration: line-through;
      text-shadow: none;
    }
  }

  // Ticket description
  .ticket-description {
    color: rgba(255, 255, 255, 0.8);
    font-size: 0.9rem;
    line-height: 1.4;
    margin-bottom: 1.2rem;
    min-height: 2.5rem;
    position: relative;
    padding-left: 0.5rem;

    // Left border with ticket-like pattern
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 2px;
      background: linear-gradient(
        to bottom,
        transparent 0%,
        var(--ticket-accent-color) 20%,
        var(--ticket-accent-color) 80%,
        transparent 100%
      );
      opacity: 0.3;
    }
  }

  // Quantity controls - analog style
  .ticket-quantity {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 0.5rem;

    // Analog style counter
    background: rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    padding: 0.5rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);

    span {
      font-size: 1.1rem;
      font-weight: 600;
      color: #fff;
      min-width: 1.5rem;
      text-align: center;
      text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    }

    .quantity-btn {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: none;
      background: rgba(0, 0, 0, 0.5);
      color: #fff;
      font-size: 1.2rem;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.2s ease;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2),
        inset 0 1px 1px rgba(255, 255, 255, 0.1);

      &:hover:not(:disabled) {
        background: rgba(0, 0, 0, 0.7);
        transform: scale(1.1);
        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3),
          inset 0 1px 1px rgba(255, 255, 255, 0.15);
      }

      &:active:not(:disabled) {
        transform: scale(0.95);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2),
          inset 0 1px 3px rgba(0, 0, 0, 0.3);
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}

// Checkout area
.checkout-area {
  margin-top: 2rem;
  padding: 1.5rem;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);

  // Paper texture
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)' opacity='0.05'/%3E%3C/svg%3E");
    pointer-events: none;
    z-index: 0;
  }

  // Subtle border
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    background: linear-gradient(
      to right,
      transparent 0%,
      var(--ticket-primary-color) 50%,
      transparent 100%
    );
    opacity: 0.5;
    z-index: 1;
  }

  // All content should be above the texture
  & > * {
    position: relative;
    z-index: 1;
  }

  // Selected tickets list
  .selected-tickets {
    margin-bottom: 1rem;
    overflow: visible;

    .selected-ticket-item {
      display: flex;
      justify-content: space-between;
      padding: 0.5rem 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      color: rgba(255, 255, 255, 0.9);
      font-size: 0.9rem;
      overflow: visible;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  // Total amount
  .total-amount {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    margin-bottom: 1.5rem;
    font-size: 1.1rem;
    font-weight: 600;
    color: $primary-color;
    overflow: visible;
  }

  // Checkout form
  .checkout-form {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
    margin-bottom: 1.5rem;

    .form-group {
      position: relative;

      input {
        width: 100%;
        padding: 0.75rem 1rem;
        background: rgba(0, 0, 0, 0.2);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 8px;
        color: white;
        font-size: 1rem;
        transition: all 0.2s ease;
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);

        &:focus {
          outline: none;
          border-color: rgba(212, 175, 55, 0.5);
          background: rgba(0, 0, 0, 0.3);
          box-shadow: 0 0 0 2px rgba(212, 175, 55, 0.1),
            inset 0 1px 3px rgba(0, 0, 0, 0.2);
        }

        &::placeholder {
          color: rgba(255, 255, 255, 0.5);
        }

        &.error {
          border-color: rgba(244, 67, 54, 0.5);
          background: rgba(244, 67, 54, 0.05);
        }
      }

      .error-message {
        color: #f44336;
        font-size: 0.8rem;
        margin-top: 0.3rem;
        position: absolute;
      }
    }
  }
}

.checkout-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);

  span {
    font-size: 1.1rem;
    color: rgba(255, 255, 255, 0.9);

    &.total-amount {
      font-size: 1.5rem;
      font-weight: 700;
      color: var(--ticket-primary-color);
      text-shadow: 0 0 10px rgba(212, 175, 55, 0.3);

      // Glowing effect
      animation: pulse 2s infinite ease-in-out;
    }
  }
}

.checkout-button {
  width: 100%;
  padding: 0.9rem;
  background: var(--ticket-primary-color);
  color: #000;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.2);

  // Metallic effect
  background-image: linear-gradient(
    to bottom,
    lighten(#d4af37, 5%) 0%,
    #d4af37 50%,
    darken(#d4af37, 5%) 100%
  );

  // Embossed effect
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: rgba(255, 255, 255, 0.5);
    opacity: 0.5;
    border-radius: 8px 8px 0 0;
  }

  &:hover:not(:disabled) {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
    background-image: linear-gradient(
      to bottom,
      lighten(#d4af37, 8%) 0%,
      lighten(#d4af37, 3%) 50%,
      #d4af37 100%
    );
  }

  &:active:not(:disabled) {
    transform: translateY(0);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  .loading-spinner-small {
    width: 18px;
    height: 18px;
    border: 2px solid rgba(0, 0, 0, 0.3);
    border-top: 2px solid #000;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
}

// Responsive adjustments
@media (max-width: 768px) {
  .tickets-wrapper {
    padding: 1rem;
  }

  .tickets-list {
    grid-template-columns: 1fr;
  }

  .checkout-form {
    grid-template-columns: 1fr;
  }

  .ticket-item {
    padding: 1.25rem 1.25rem 1.25rem 2.25rem;
  }

  .ticket-header h4 {
    font-size: 1.1rem;
  }

  .ticket-price {
    font-size: 1.5rem;
  }
}
