.maintenance-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #121212;
  z-index: 9999;

  .maintenance-card {
    background-color: transparent;
    width: 100%;
    max-width: 320px;
    text-align: center;

    .maintenance-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 32px;

      h1 {
        font-size: 1.4rem;
        font-weight: 600;
        color: #ffc807;
        letter-spacing: 1px;
      }

      .access-form {
        width: 100%;

        form {
          display: flex;
          align-items: center;
          gap: 8px;
          background-color: rgba(255, 255, 255, 0.05);
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 8px;
          padding: 4px;

          input {
            flex: 1;
            background: transparent;
            border: none;
            padding: 10px 12px;
            color: white;
            font-size: 0.9rem;

            &:focus {
              outline: none;
            }

            &.error {
              color: #ff5757;
            }

            &::placeholder {
              color: rgba(255, 255, 255, 0.3);
            }
          }

          button {
            height: 32px;
            width: 32px;
            border-radius: 6px;
            background-color: #ffc807;
            color: #000;
            border: none;
            font-weight: 600;
            font-size: 1rem;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: transform 0.2s ease;

            &:hover,
            &:focus {
              transform: scale(1.05);
            }

            &:active {
              transform: scale(0.95);
            }
          }
        }
      }
    }
  }
}
