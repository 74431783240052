@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out forwards;
}

@keyframes scaleIn {
  0% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

.scale-in {
  animation: scaleIn 0.3s ease-in-out forwards;
}

@keyframes slideIn {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-in {
  animation: slideIn 1s ease-in-out forwards;
}

@keyframes fadeInLeft {
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.fade-in-left {
  animation: fadeInLeft 1s ease-in-out forwards;
}

@keyframes fadeInUp {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.fade-in-up {
  animation: fadeInUp 1s ease-in-out forwards;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.bounce {
  animation: bounce 1s ease-in-out forwards;
}

@keyframes subtleGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes bounceIn {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  75% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.bounce-in {
  animation: bounceIn 1s ease-in-out forwards;
}

@keyframes zoomIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.zoom-in {
  animation: zoomIn 1s ease-in-out forwards;
}

@keyframes rotateIn {
  0% {
    transform: rotate(-45deg);
    opacity: 0;
  }
  100% {
    transform: rotate(0);
    opacity: 1;
  }
}

.rotate-in {
  animation: rotateIn 1s ease-in-out forwards;
}

@keyframes slide {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(56.57px, 56.57px);
  }
}

@keyframes subtleBounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.subtle-bounce {
  animation: subtleBounce 0.1s ease-in-out infinite;
}
