.loading-spinner {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.small {
    .spinner {
      width: 16px;
      height: 16px;
      border-width: 2px;
    }
  }

  &.medium {
    .spinner {
      width: 24px;
      height: 24px;
      border-width: 3px;
    }
  }

  &.large {
    .spinner {
      width: 32px;
      height: 32px;
      border-width: 4px;
    }
  }

  &.primary .spinner {
    border-color: rgba(255, 200, 7, 0.2);
    border-top-color: #ffc807;
  }

  &.white .spinner {
    border-color: rgba(255, 255, 255, 0.2);
    border-top-color: white;
  }

  .spinner {
    border-style: solid;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
