.color-picker-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 1rem;
}

.color-picker-dialog {
  background: #1a1a1a;
  border-radius: 12px;
  width: 100%;
  max-width: 360px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;

  .color-picker-header {
    padding: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    h3 {
      margin: 0;
      font-size: 1.1rem;
      color: #fff;
      text-align: center;
      font-weight: 500;
    }
  }

  .color-picker-content {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  // Color field container styles
  .color-field-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 8px;
  }

  .color-field {
    position: relative;
    height: 150px;
    border-radius: 8px;
    overflow: hidden;
    cursor: crosshair;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1);

    &-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0)),
        linear-gradient(to top, #000, rgba(0, 0, 0, 0));
    }

    &-cursor {
      position: absolute;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: 2px solid #fff;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
      transform: translate(-50%, -50%);
      pointer-events: none;
    }
  }

  .hue-slider-container {
    width: 100%;
    padding: 0 2px;
  }

  .hue-slider {
    width: 100%;
    height: 16px;
    -webkit-appearance: none;
    background: linear-gradient(
      to right,
      #ff0000,
      #ffff00,
      #00ff00,
      #00ffff,
      #0000ff,
      #ff00ff,
      #ff0000
    );
    border-radius: 8px;
    outline: none;
    opacity: 0.85;
    transition: opacity 0.2s;
    cursor: pointer;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1);

    &:hover {
      opacity: 1;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: #fff;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
      cursor: pointer;
    }

    &::-moz-range-thumb {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: #fff;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
      cursor: pointer;
      border: none;
    }
  }

  .color-inputs {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    label {
      font-size: 0.8rem;
      color: rgba(255, 255, 255, 0.7);
      margin-bottom: 0.25rem;
      display: block;
    }

    input {
      background: rgba(255, 255, 255, 0.05);
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 6px;
      color: #fff;
      font-size: 0.9rem;
      padding: 0.5rem;
      width: 100%;
      transition: all 0.2s ease;

      &:focus {
        outline: none;
        border-color: rgba(255, 255, 255, 0.2);
        background: rgba(255, 255, 255, 0.08);
      }

      &[type="number"] {
        -moz-appearance: textfield;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
  }

  .hex-input {
    margin-bottom: 0.5rem;
  }

  .rgb-inputs {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
  }

  .color-preview {
    height: 60px;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.1);

    .color-value {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0.5rem;
      background: rgba(0, 0, 0, 0.5);
      color: #fff;
      font-size: 0.8rem;
      text-align: center;
      backdrop-filter: blur(4px);
    }
  }

  .preset-colors {
    label {
      font-size: 0.8rem;
      color: rgba(255, 255, 255, 0.7);
      margin-bottom: 0.5rem;
      display: block;
    }

    .color-grid {
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      gap: 0.5rem;
      padding: 0.5rem;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 8px;

      .color-option {
        width: 100%;
        padding-top: 100%;
        border-radius: 4px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        cursor: pointer;
        position: relative;
        transition: all 0.2s ease;

        &:hover {
          transform: scale(1.15);
          z-index: 1;
          border-color: rgba(255, 255, 255, 0.3);
        }

        &.selected {
          border: 2px solid #fff;
          box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.5);
        }
      }
    }
  }

  .color-picker-actions {
    display: flex;
    justify-content: flex-end;
    gap: 0.75rem;
    padding: 1rem;
    background: rgba(0, 0, 0, 0.2);
    border-top: 1px solid rgba(255, 255, 255, 0.1);

    button {
      padding: 0.5rem 1rem;
      border-radius: 6px;
      font-size: 0.9rem;
      cursor: pointer;
      transition: all 0.2s ease;

      &.cancel-button {
        background: rgba(255, 255, 255, 0.05);
        border: 1px solid rgba(255, 255, 255, 0.1);
        color: rgba(255, 255, 255, 0.7);

        &:hover {
          background: rgba(255, 255, 255, 0.1);
          color: #fff;
        }
      }

      &.apply-button {
        background: #2196f3;
        border: none;
        color: #fff;
        font-weight: 500;

        &:hover {
          background: #1976d2;
          transform: translateY(-1px);
        }
      }
    }
  }
}

// Media queries for responsiveness
@media (max-width: 480px) {
  .color-picker-dialog {
    max-width: 320px;

    .color-picker-content {
      padding: 0.75rem;
      gap: 0.75rem;
    }

    .color-field {
      height: 120px;
    }

    .color-preview {
      height: 50px;
    }

    .preset-colors .color-grid {
      gap: 0.35rem;
    }

    .color-picker-actions {
      padding: 0.75rem;

      button {
        padding: 0.4rem 0.75rem;
        font-size: 0.85rem;
      }
    }
  }
}

@media (max-height: 600px) {
  .color-picker-overlay {
    align-items: flex-start;
    padding-top: 1rem;
  }

  .color-picker-dialog {
    max-height: calc(100vh - 2rem);
    overflow-y: auto;

    .color-field {
      height: 100px;
    }

    .color-preview {
      height: 40px;
    }
  }
}
