@import "../../_variables";

.table-system {
  min-height: 100vh;
  background: #000;
  color: #fff;
  padding-top: 2rem;

  &-wrapper {
    max-width: 600px;
    margin: 0 auto;
    background: #111;
    border-radius: 16px;
    display: grid;
    flex-direction: column;
    gap: 1.5rem;
  }

  &-content {
    padding-top: 15px; // Add space to prevent navigation overlap
    display: grid;
    gap: 1.5rem;
  }

  &-logo {
    width: 5rem;
    height: auto;
    margin: 0 auto;
  }

  &-title {
    color: rgba(255, 255, 255, 0.7);
    text-align: center;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.06rem;
    text-transform: uppercase;
    margin-top: 1.5rem;
  }

  &-count {
    text-align: center;

    h4 {
      font-size: 0.875rem;
      color: rgba(255, 255, 255, 0.7);
      margin-bottom: 1rem;
    }

    &-number {
      width: 4rem;
      height: 4rem;
      margin: 0 auto;
      border-radius: 50%;
      background: #ffc807;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        color: #000;
        font-size: 2rem;
        font-weight: 700;
        line-height: 1;
      }
    }
  }
  &-form {
    display: grid;
    gap: 1rem;

    .input-group {
      justify-self: center;
      width: 100%;
      display: grid;
    }

    input,
    select {
      width: 90%;
      padding: 0.75rem 1rem;
      border: 1px solid #333;
      border-radius: 8px;
      background: #1a1a1a;
      color: #fff;
      font-size: 1rem;
      justify-self: center;
      -webkit-appearance: none; // Removes iOS default styling
      -moz-appearance: none;
      appearance: none;
      margin: 0; // Removes iOS default margin

      // iOS specific text color fix
      &:not([type="submit"]):not([type="button"]) {
        -webkit-text-fill-color: #fff;
      }

      // Fix for iOS zoom on focus
      @supports (-webkit-touch-callout: none) {
        font-size: 16px; // Prevents zoom on iOS
      }

      &::placeholder {
        color: #666;
        opacity: 1; // Fixes iOS opacity
      }

      &:focus {
        outline: none;
        border-color: #ffc807;
        box-shadow: 0 0 0 2px rgba(255, 200, 7, 0.1);
      }
    }

    // Specific select styling
    select {
      cursor: pointer;
      padding-right: 2rem; // Space for custom arrow
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23666666' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right 0.75rem center;
      background-size: 1rem;

      // Remove default arrow in IE/Edge
      &::-ms-expand {
        display: none;
      }

      option {
        background: #1a1a1a;
        color: #fff;
        padding: 0.5rem;
      }

      optgroup {
        font-weight: bold;
        color: #ffc807;
      }
    }

    // iOS specific dark mode fix
    @media (prefers-color-scheme: dark) {
      input,
      select {
        background: #1a1a1a !important;
      }
    }
  }

  // Add specific iOS input reset styles
  @supports (-webkit-touch-callout: none) {
    input,
    select {
      border-radius: 8px !important;
      -webkit-border-radius: 8px !important;
    }
  }

  // Add specific button reset for iOS
  &-btn {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 90%;
    padding: 0.75rem;
    background: #ffc807;
    color: #000;
    border: none;
    border-radius: 8px;
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
    transition: transform 0.2s ease, background-color 0.2s ease;
    margin-top: 0.5rem;
    justify-self: center;
    &:not(:disabled):hover {
      background: darken(#ffc807, 10%);
      transform: translateY(-1px);
    }

    &:disabled {
      background: #333;
      color: #666;
      cursor: not-allowed;
    }
  }

  &-download {
    text-align: center;

    &-link {
      display: inline-block;
      padding: 0.75rem 1.5rem;
      background: #28a745;
      color: #fff;
      border-radius: 8px;
      text-decoration: none;
      font-weight: 500;
      transition: all 0.2s ease;

      &:hover {
        background: darken(#28a745, 10%);
        transform: translateY(-1px);
      }
    }
  }

  .no-event-message {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
    margin: 30px 0;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    text-align: center;

    p {
      font-size: 18px;
      color: #ffffff;
      opacity: 0.8;
    }
  }
}

.refresh-button {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
  justify-self: center;

  button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 8px;
    transition: opacity 0.2s ease;

    &:disabled {
      cursor: default;
    }

    img {
      width: 36px;
      height: 36px;
      transition: transform 0.1s ease;
    }

    &:hover:not(:disabled) img {
      opacity: 0.8;
    }

    &.spinning img {
      animation: doubleSpinRefresh 1s ease-in-out;
    }
  }
}

@keyframes doubleSpinRefresh {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(720deg);
  }
}

// Loading state and empty state styling
.loading-state,
.no-reservations {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  margin: 20px 0;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  text-align: center;

  p {
    font-size: 16px;
    color: #ffffff;
    opacity: 0.7;
    margin: 5px 0;
  }

  p:first-child {
    font-weight: 500;
    opacity: 0.9;
  }
}

// Additional style for TableCodeManagement
.table-code-management {
  margin-top: 20px;
  padding-bottom: 20px;
}
