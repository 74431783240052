@import "../../_variables";

.upcoming-event-container {
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  background-color: #151515;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  margin-bottom: 1.5rem;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.1);
  max-width: 100%;

  // Seamless mode - removes container styling
  &.seamless {
    border: none;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
    margin-bottom: 0;

    .event-navigation {
      background: transparent;
      border-bottom: none;
      padding: 0.75rem 0;
    }

    .event-card {
      border: none;
      background: transparent;

      .event-image-container {
        border-radius: 8px;
        overflow: hidden;
      }
    }

    .event-details {
      padding: 1rem 0;
    }

    .ticket-section {
      padding: 0;
      margin: 0;
      width: 100%;
      max-width: 100%;
    }

    .action-buttons {
      margin-top: 1rem;

      .action-button {
        background: rgba(255, 200, 7, 0.1);
        border: 1px solid rgba(255, 200, 7, 0.2);

        &:hover {
          background: rgba(255, 200, 7, 0.15);
        }
      }
    }
  }

  &.loading {
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;

    // Seamless loading state
    &.seamless {
      min-height: 150px;
      background: transparent;
    }

    .loading-spinner {
      width: 40px;
      height: 40px;
      border: 3px solid rgba(255, 200, 7, 0.2);
      border-radius: 50%;
      border-top-color: #ffc807;
      animation: spin 1s ease-in-out infinite;
    }

    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
  }

  .spinner {
    width: 24px;
    height: 24px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;
  }

  &.empty,
  &.error {
    min-height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    text-align: center;

    .empty-state {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.75rem;

      .empty-icon {
        font-size: 2.5rem;
        color: rgba(255, 255, 255, 0.5);
      }

      p {
        font-size: 1rem;
        color: rgba(255, 255, 255, 0.7);
      }

      .empty-state-subtext {
        font-size: 0.85rem;
        color: rgba(255, 255, 255, 0.5);
        margin-top: 0.25rem;
      }
    }
  }

  .event-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    background-color: rgba(0, 0, 0, 0.2);

    .navigation-indicator {
      display: flex;
      gap: 0.5rem;

      .indicator-dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.3);
        cursor: pointer;
        transition: background-color 0.2s ease, transform 0.2s ease;

        &.active {
          background-color: #ffc807;
          transform: scale(1.2);
        }

        &:hover {
          background-color: rgba(255, 200, 7, 0.7);
        }
      }
    }

    .nav-button {
      background: none;
      border: none;
      color: #fff;
      font-size: 1.5rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: color 0.2s ease;

      &:hover:not(.disabled) {
        color: #ffc807;
      }

      &.disabled {
        color: rgba(255, 255, 255, 0.3);
        cursor: not-allowed;
      }
    }
  }

  .event-card {
    display: flex;
    flex-direction: column;
    transition: transform 0.2s ease, box-shadow 0.3s ease;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.3);
    }

    .event-image-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }

    .event-image-container {
      width: 100%;
      min-height: 150px;
      overflow: hidden;
      background-color: #000;
      cursor: pointer;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 60%;
        background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
        pointer-events: none;
        z-index: 1;
      }

      .event-image {
        width: 100%;
        height: auto;
        display: block;
        transition: transform 0.3s ease;

        // Default object-fit behavior
        object-fit: cover; // Default to cover for most images
      }

      &:hover .event-image {
        transform: scale(1.05);
      }

      .no-image {
        width: 100%;
        height: 240px; /* Keep a default height for the no-image state */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: linear-gradient(45deg, #111, #222);
        color: rgba(255, 255, 255, 0.5);
        gap: 0.5rem;

        svg {
          font-size: 2.5rem;
          opacity: 0.6;
        }

        span {
          font-size: 0.9rem;
        }
      }
    }

    // Additional styles for different aspect ratios
    &.has-landscape-flyer .event-image-container {
      .event-image {
        max-height: 240px; /* Landscape images shouldn't be too tall */
        width: 100%;
        object-fit: cover;
      }
    }

    &.has-square-flyer .event-image-container {
      .event-image {
        max-height: 350px; /* Square images can be a bit taller */
        width: 100%;
        object-fit: cover;
      }
    }

    &.has-portrait-flyer {
      .event-image-wrapper {
        background: linear-gradient(145deg, #111, #1a1a1a);
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        /* Add a subtle grain texture overlay */
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)' opacity='0.05'/%3E%3C/svg%3E");
          opacity: 0.4;
          pointer-events: none;
          z-index: 0;
        }
      }

      .event-image-container {
        background: transparent;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: auto;
        margin: 0 auto;

        .event-image {
          max-height: 450px;
          height: auto;
          width: auto;
          object-fit: contain;
          z-index: 1;
          position: relative;
          box-shadow: 0 8px 24px rgba(0, 0, 0, 0.3);
        }
      }
    }

    // Mobile styles for aspect ratios
    @media (max-width: 767px) {
      &.has-landscape-flyer .event-image-container .event-image {
        max-height: 200px;
        width: 100%;
        object-fit: cover;
      }

      &.has-square-flyer .event-image-container .event-image {
        max-height: 300px;
        width: 100%;
        object-fit: cover;
      }

      &.has-portrait-flyer {
        .event-image-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
        }

        .event-image-container {
          display: inline-flex;
          width: auto;

          .event-image {
            max-height: 400px;
            height: auto;
            max-width: 100%;
            width: auto;
            object-fit: contain;
          }
        }
      }
    }

    // Tablet styles for aspect ratios
    @media (min-width: 768px) and (max-width: 1023px) {
      &.has-landscape-flyer .event-image-container .event-image {
        max-height: 240px;
        width: 100%;
        object-fit: cover;
      }

      &.has-square-flyer .event-image-container .event-image {
        max-height: 350px;
        width: 100%;
        object-fit: cover;
      }

      &.has-portrait-flyer {
        .event-image-wrapper {
          display: flex;
          justify-content: center;
          width: 100%;
        }

        .event-image-container {
          display: inline-flex;
          width: auto;

          .event-image {
            max-height: 420px;
            height: auto;
            max-width: 100%;
            width: auto;
            object-fit: contain;
          }
        }
      }
    }

    // Desktop styles for aspect ratios
    @media (min-width: 1024px) {
      &.has-landscape-flyer .event-image-container {
        max-height: 280px;

        .event-image {
          width: 100%;
          object-fit: cover;
        }
      }

      &.has-square-flyer .event-image-container {
        max-height: 380px;

        .event-image {
          width: 100%;
          object-fit: cover;
        }
      }

      &.has-portrait-flyer .event-image-container {
        max-height: 480px;
        background: linear-gradient(145deg, #111, #1a1a1a);
        display: flex;
        justify-content: center;
        align-items: center;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)' opacity='0.05'/%3E%3C/svg%3E");
          opacity: 0.4;
          pointer-events: none;
          z-index: 0;
        }

        .event-image {
          max-height: 480px;
          height: auto;
          max-width: 100%;
          width: auto;
          object-fit: contain;
          position: relative;
          z-index: 1;
          box-shadow: 0 10px 24px rgba(0, 0, 0, 0.3);
        }
      }
    }

    .event-details {
      padding: 1.25rem;
      background-color: #151515;
      flex: 1;

      .event-header {
        margin-bottom: 1.25rem;
        position: relative;
        z-index: 2;
        text-align: center;
      }

      .event-title {
        font-size: 2.2rem;
        font-weight: 700;
        margin: 0 0 0.5rem 0;
        color: #fff;
        line-height: 1.2;
        text-shadow: 0 2px 10px rgba(0, 0, 0, 0.5),
          0 0 15px rgba(255, 200, 7, 0.3);
        position: relative;
        text-align: center;

        &::after {
          content: "";
          position: absolute;
          bottom: -8px;
          left: 50%;
          transform: translateX(-50%);
          width: 80px;
          height: 3px;
          background: linear-gradient(
            to right,
            transparent,
            #ffc807,
            transparent
          );
          border-radius: 3px;
          box-shadow: 0 0 8px rgba(255, 200, 7, 0.5);
        }
      }

      .event-subtitle {
        font-size: 1.1rem;
        color: rgba(255, 255, 255, 0.8);
        margin: 0 0 1.5rem 0;
        font-weight: 400;
        letter-spacing: 0.02em;
        text-align: center;
      }

      .event-description-container {
        margin: 1rem auto;
        padding: 1rem 1.25rem;
        max-width: 85%;
        background: rgba(255, 255, 255, 0.03);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        position: relative;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

        .event-description {
          color: rgba(255, 255, 255, 0.85);
          font-size: 0.95rem;
          line-height: 1.6;
          margin: 0;
          position: relative;
          font-weight: 300;
          font-style: italic;
          letter-spacing: 0.02em;
        }
      }

      .event-details-section {
        margin: 1.5rem 0;
        width: 100%;
      }
    }

    .guest-code-section {
      margin-top: 1.5rem;
      border-top: 1px solid rgba(255, 200, 7, 0.3);
      padding-top: 1.5rem;

      h4 {
        color: #fff;
        font-size: 1.1rem;
        margin-bottom: 1rem;
        text-align: center;
        position: relative;
        font-weight: 600;

        &:after {
          content: "";
          position: absolute;
          bottom: -8px;
          left: 50%;
          transform: translateX(-50%);
          width: 40px;
          height: 2px;
          background-color: #ffc807;
        }
      }
    }
  }

  // Full width class for sections that need to take up all available space
  .full-width {
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;
  }
}

@media (min-width: 768px) {
  .upcoming-event-container {
    max-width: 90%;
    margin: 0 auto 2rem;

    .event-card {
      flex-direction: row;

      .event-image-container {
        width: 35%;
        min-height: 280px;
        max-height: 450px;
        display: flex;
        align-items: center;
      }

      .event-details {
        width: 65%;

        .event-header {
          margin-top: 0.5rem;
        }

        .event-title {
          text-align: left;
          margin-top: 0;
        }

        .event-subtitle {
          text-align: left;
        }
      }

      // Adjust the max heights for different flyer types in desktop view
      &.has-landscape-flyer .event-image-container .event-image {
        max-height: 280px;
      }

      &.has-portrait-flyer .event-image-container .event-image {
        max-height: 350px;
      }

      &.has-vertical-flyer .event-image-container .event-image {
        max-height: 420px;
      }
    }
  }
}

@media (min-width: 1024px) {
  .upcoming-event-container {
    max-width: 85%;

    .event-navigation {
      padding: 1rem;

      .nav-button {
        font-size: 1.75rem;
      }

      .indicator-dot {
        width: 10px;
        height: 10px;
      }
    }

    .event-card {
      .event-image-container {
        width: 40%;
        min-height: 320px;
        max-height: 500px;
      }

      .event-details {
        width: 60%;
        padding: 2.5rem;

        .event-title {
          font-size: 2.4rem;
          margin-bottom: 0.75rem;
        }

        .event-subtitle {
          font-size: 1.1rem;
          margin-bottom: 2rem;
        }
      }

      // Larger max heights for wider screens
      &.has-landscape-flyer .event-image-container .event-image {
        max-height: 320px;
      }

      &.has-portrait-flyer .event-image-container .event-image {
        max-height: 400px;
      }

      &.has-vertical-flyer .event-image-container .event-image {
        max-height: 480px;
      }
    }
  }
}

@media (min-width: 1440px) {
  .upcoming-event-container {
    max-width: 80%;
    display: grid;
    grid-template-columns: 1fr;

    .event-navigation {
      grid-column: 1 / -1;
    }

    .event-card {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto;

      .event-image-container {
        width: 100%;
        min-height: 200px;
        max-height: unset;
        grid-column: 1 / -1;
        grid-row: 1;
        position: relative;
        overflow: hidden;

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 70%;
          background: linear-gradient(
            to top,
            rgba(21, 21, 21, 1),
            rgba(21, 21, 21, 0.8) 30%,
            rgba(21, 21, 21, 0)
          );
          pointer-events: none;
          z-index: 1;
        }

        .event-image {
          width: 100%;
          max-width: 100%;
          margin: 0 auto;
          object-fit: contain;
        }
      }

      &.has-landscape-flyer .event-image-container {
        height: auto;

        .event-image {
          max-height: 450px;
        }
      }

      &.has-portrait-flyer .event-image-container {
        height: auto;
        background: linear-gradient(145deg, #111, #1a1a1a);

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)' opacity='0.05'/%3E%3C/svg%3E");
          opacity: 0.4;
          pointer-events: none;
          z-index: 0;
        }

        .event-image {
          max-height: 500px;
          position: relative;
          z-index: 1;
          box-shadow: 0 12px 30px rgba(0, 0, 0, 0.4);
        }
      }

      &.has-vertical-flyer .event-image-container {
        height: auto;

        .event-image {
          max-height: 550px;
        }
      }

      .event-details {
        width: 100%;
        grid-column: 1 / -1;
        grid-row: 2;
        padding: 0 3rem 3rem;

        .event-header {
          margin-top: -120px;
          position: relative;
          z-index: 2;
          text-align: center;

          .event-title {
            font-size: 3.5rem;
            text-align: center;
            margin-bottom: 1rem;
            text-shadow: 0 4px 12px rgba(0, 0, 0, 0.8);
          }

          .event-subtitle {
            font-size: 1.3rem;
            text-align: center;
            margin-bottom: 3rem;
            text-shadow: 0 2px 8px rgba(0, 0, 0, 0.6);
          }
        }
      }
    }

    // Add back the content-sections styles
    .content-sections {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2rem;
      margin-top: 2rem;

      .ticket-section,
      .guest-code-section {
        margin: 0;
      }
    }

    // Add back the event-lineups styles
    .event-lineups {
      grid-column: 1 / -1;
      margin: 2rem 0;
      padding: 2rem;

      .lineup-artists {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      }
    }
  }
}

// Extra extra large desktop view
@media (min-width: 1920px) {
  .upcoming-event-container {
    max-width: 75%;

    .event-card {
      .event-image-container {
        height: 500px;
      }

      .event-details {
        padding: 3rem;

        .event-title {
          font-size: 2.5rem;
          margin-top: -4.5rem;
        }

        .event-subtitle {
          font-size: 1.25rem;
        }
      }
    }

    .content-sections {
      grid-template-columns: 3fr 2fr;
    }
  }
}

.event-lineups {
  margin: 1rem 0 1.5rem;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 1.25rem;
  border: 1px solid rgba(255, 200, 7, 0.1);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  max-width: 100%;
  overflow-x: hidden;

  h5 {
    color: #fff;
    font-size: 1.1rem;
    margin-bottom: 1rem;
    position: relative;
    display: inline-block;
    font-weight: 600;

    &:after {
      content: "";
      position: absolute;
      bottom: -4px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: rgba(255, 200, 7, 0.4);
    }
  }

  .lineup-artists-container {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    width: 100%;
    max-width: 100%;
  }

  .lineup-category-group {
    margin-bottom: 0.75rem;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 0.75rem;
    width: 100%;
    max-width: 100%;
    overflow: hidden;

    .category-title {
      color: #ffc807;
      font-size: 0.9rem;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      margin-bottom: 0.75rem;
      font-weight: 600;
      padding-left: 0.75rem;
      border-left: 3px solid #ffc807;
      display: flex;
      align-items: center;

      &:before {
        content: "";
        display: inline-block;
        width: 8px;
        height: 8px;
        background-color: #ffc807;
        border-radius: 50%;
        margin-right: 8px;
      }

      .artist-count {
        margin-left: 8px;
        font-size: 0.8rem;
        color: rgba(255, 255, 255, 0.7);
        font-weight: normal;
      }
    }
  }

  .lineup-artists {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    width: 100%;
    max-width: 100%;
    overflow: hidden;

    @media (min-width: 768px) {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      gap: 1rem;
    }

    .artist {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 0.75rem 1rem;
      border-radius: 8px;
      background: rgba(0, 0, 0, 0.2);
      transition: all 0.2s ease;
      width: 100%;
      max-width: 100%;
      box-sizing: border-box;

      &:hover {
        background: rgba(255, 200, 7, 0.1);
        transform: translateX(5px);
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
      }

      /* Adjust hover effect for mobile to prevent overflow */
      @media (max-width: 767px) {
        &:hover {
          transform: translateX(3px); /* Smaller transform on mobile */
        }
      }

      .artist-avatar {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        overflow: hidden;
        flex-shrink: 0;
        border: 2px solid rgba(255, 200, 7, 0.5);
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform 0.3s ease;
        }

        &:hover img {
          transform: scale(1.1);
        }

        &.placeholder {
          background: linear-gradient(
            45deg,
            rgba(255, 200, 7, 0.2),
            rgba(255, 200, 7, 0.3)
          );
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffc807;
          font-size: 1.5rem;
          font-weight: 600;
        }
      }

      .artist-info {
        flex: 1;
        min-width: 0;
        max-width: calc(100% - 70px); /* Account for avatar width and gap */
        overflow: hidden; /* Prevent text overflow */

        .artist-name {
          color: #fff;
          font-size: 1.1rem;
          font-weight: 500;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
        }
      }
    }
  }

  @media (min-width: 1024px) {
    margin: 2rem 0;
    padding: 2rem;
    border-radius: 12px;

    h5 {
      font-size: 1.3rem;
      margin-bottom: 1.5rem;

      &:after {
        height: 3px;
      }
    }

    .lineup-category-group {
      margin-bottom: 1.5rem;
      padding: 1rem;
      border-radius: 10px;

      .category-title {
        font-size: 1rem;
        margin-bottom: 1rem;
        padding-left: 1rem;
        border-left-width: 4px;
      }
    }

    .lineup-artists {
      gap: 1.25rem;

      .artist {
        padding: 1rem;
        border-radius: 10px;
        transition: all 0.3s ease;

        &:hover {
          transform: translateX(5px) translateY(-5px);
          box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
        }

        .artist-avatar {
          width: 70px;
          height: 70px;
          border-width: 3px;
        }

        .artist-info {
          .artist-name {
            font-size: 1.1rem;
          }
        }
      }
    }
  }

  @media (min-width: 1440px) {
    .lineup-artists-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 1.5rem;
    }

    .lineup-category-group {
      margin-bottom: 0;
      height: 100%;
    }
  }
}

.see-full-event-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
  padding: 1rem;
  margin-top: 1.5rem;
  background: linear-gradient(
    135deg,
    rgba(255, 200, 7, 0.15),
    rgba(255, 200, 7, 0.05)
  );
  border: 1px solid rgba(255, 200, 7, 0.3);
  border-radius: 8px;
  color: #ffc807;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;

  svg {
    font-size: 1.2rem;
    transition: transform 0.3s ease;
  }

  &:hover {
    background: linear-gradient(
      135deg,
      rgba(255, 200, 7, 0.2),
      rgba(255, 200, 7, 0.1)
    );
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);

    svg {
      transform: translateX(4px);
    }
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  }
}

/* Ticket Section Styles */
.ticket-section {
  margin: 1.5rem 0;
  padding: 1.5rem;

  max-width: 100%;
  overflow-x: hidden;

  h3 {
    color: #fff;
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
    position: relative;
    display: inline-block;
    font-weight: 600;

    &:after {
      content: "";
      position: absolute;
      bottom: -4px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: rgba(255, 200, 7, 0.4);
    }
  }

  .ticket-info {
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.9rem;
    margin-bottom: 1.5rem;
  }

  .no-tickets-message {
    padding: 2rem;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);

    .loading-tickets {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;

      .spinner {
        width: 30px;
        height: 30px;
      }
    }

    .ticket-actions {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      margin-top: 1rem;
      align-items: center;

      @media (min-width: 480px) {
        flex-direction: row;
        justify-content: center;
      }
    }

    .retry-button {
      padding: 0.5rem 1rem;
      background: rgba(255, 200, 7, 0.15);
      color: #ffc807;
      border: 1px solid rgba(255, 200, 7, 0.3);
      border-radius: 8px;
      font-size: 0.9rem;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        background: rgba(255, 200, 7, 0.25);
        transform: translateY(-2px);
      }
    }

    .sample-button {
      padding: 0.5rem 1rem;
      background: rgba(33, 150, 243, 0.15);
      color: #2196f3;
      border: 1px solid rgba(33, 150, 243, 0.3);
      border-radius: 8px;
      font-size: 0.9rem;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        background: rgba(33, 150, 243, 0.25);
        transform: translateY(-2px);
      }
    }
  }
}

@media (max-width: 480px) {
  .event-lineups {
    padding: 1rem 0.75rem;

    .lineup-category-group {
      padding: 0.5rem;
    }

    .lineup-artists {
      .artist {
        padding: 0.5rem 0.75rem;
        gap: 0.75rem;

        .artist-avatar {
          width: 50px;
          height: 50px;
        }

        .artist-info {
          max-width: calc(100% - 60px);

          .artist-name {
            font-size: 0.95rem;
          }
        }
      }
    }
  }
}

.action-buttons {
  display: flex;
  gap: 1rem;
  margin: 1.25rem 0;
  width: 100%;
  padding: 0;
  background: transparent;
  border-radius: 0;
  box-shadow: none;

  .action-button {
    flex: 1;
    padding: 0.75rem 1rem;
    border-radius: 8px;
    font-size: 0.9rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.5px;

    &.buy-ticket-button {
      background: #ffc807;
      color: #000;

      &:hover {
        background: #e6b400;
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba(255, 200, 7, 0.3);
      }
    }
  }

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 0.5rem;
    padding: 0;
  }

  @media (min-width: 1024px) {
    margin: 2rem 0;
    gap: 1.5rem;

    .action-button {
      padding: 1rem 1.5rem;
      font-size: 1rem;
      letter-spacing: 1px;
      border-radius: 10px;

      &.buy-ticket-button {
        &:hover {
          transform: translateY(-3px);
          box-shadow: 0 6px 15px rgba(255, 200, 7, 0.4);
        }
      }
    }
  }

  @media (min-width: 1440px) {
    max-width: 80%;
    margin: 2.5rem auto;

    .action-button {
      padding: 1.25rem 2rem;
      font-size: 1.1rem;
      border-radius: 12px;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 12px;
        opacity: 0;
        transition: opacity 0.3s ease;
        z-index: -1;
      }

      &.buy-ticket-button {
        position: relative;
        overflow: hidden;

        &:hover:before {
          opacity: 1;
        }
      }
    }
  }
}

.event-image-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

/* Media query styles for different viewports */
@media (max-width: 767px) {
  .event-card {
    &.has-portrait-flyer {
      .event-image-wrapper {
        background: linear-gradient(145deg, #111, #1a1a1a);

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)' opacity='0.05'/%3E%3C/svg%3E");
          opacity: 0.4;
          pointer-events: none;
          z-index: 0;
        }
      }

      .event-image-container {
        width: auto;
        max-width: 100%;
      }
    }
  }
}

@media (max-width: 768px) {
  .upcoming-event-container {
    .event-card {
      .event-details {
        .event-header {
          text-align: center;
        }

        .event-title {
          font-size: 1.8rem;
          text-align: center;

          &::after {
            left: 50%;
            transform: translateX(-50%);
            width: 50px;
          }
        }

        .event-subtitle {
          font-size: 1rem;
          margin-bottom: 1.2rem;
          text-align: center;
        }

        .event-description-container {
          max-width: 100%;
          padding: 0.8rem 1rem;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .upcoming-event-container {
    .event-card {
      .event-details {
        .event-header {
          margin-top: 0.5rem;
          text-align: center;
        }

        .event-title {
          text-align: center !important;
          margin-top: 0;
        }

        .event-subtitle {
          text-align: center !important;
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .upcoming-event-container {
    .event-card {
      .event-details {
        .event-title {
          font-size: 2.4rem;

          &::after {
            width: 70px;
            height: 4px;
          }
        }

        .event-subtitle {
          font-size: 1.2rem;
        }

        .event-description-container {
          max-width: 80%;
          padding: 1.2rem 1.5rem;
        }
      }
    }
  }
}

@media (min-width: 1440px) {
  .upcoming-event-container {
    .event-card {
      .event-details {
        .event-header {
          margin-top: -120px;
          text-align: center;
        }

        .event-title {
          font-size: 3.5rem;
          text-align: center;
          text-shadow: 0 4px 12px rgba(0, 0, 0, 0.8);

          &::after {
            left: 50%;
            transform: translateX(-50%);
            width: 100px;
            height: 4px;
          }
        }

        .event-subtitle {
          font-size: 1.3rem;
          text-align: center;
          text-shadow: 0 2px 8px rgba(0, 0, 0, 0.6);
        }

        .event-description-container {
          max-width: 70%;
          margin: 2rem auto;
          padding: 1.5rem 2rem;
          backdrop-filter: blur(8px);
          -webkit-backdrop-filter: blur(8px);
        }
      }
    }
  }
}
